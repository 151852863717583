import React from 'react';
import { Input, Button } from 'components';
import { LoginService } from 'services';
import { Globals } from 'utils';
import loginRecoverPasswordLock from 'assets/img/logo/login-recover-password-lock.png'

class Remember extends React.Component<any> {

	state = {
		form: {
			email: ''
		}
	}

	disabled = () => {
		return this.state.form.email == null || this.state.form.email == '';
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	submit = async () => {
		await LoginService.reset({
			email: this.state.form.email
		});
		Globals.showSuccess("Se le ha enviado un correo electrónico");
		this.props.onClose();
	}
	
	render() {
		return (
			<div className="remember-password">
				<div className="row">
					<div className="col-md-12 text-center">
						<div className="login-recover-password-logo">
							<img src={loginRecoverPasswordLock} alt="logo"  className="img-fluid"/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 text-center">
						<div className="login-recover-password-title">
							<h4>¿Olvidaste tu Contraseña?</h4>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 text-center">
						<div className="login-recover-password-text">
							Ingresa tu correo electrónico y te enviaremos un enlace para recuperar tu contraseña
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 text-center">
						<div className="login-recover-password-input">
							<Input 
								color="gray"
								name="email" 
								placeholder="Correo Electrónico"
								onChange={ this.change } 
							/>
						</div>
					</div>
				</div>
				<div className="container-buttons">
					<div className="text-center row">
						<div className="col-md-6">
							<Button className="back-button" onClick={() => this.props.onClose()}>
								Volver
							</Button>
						</div>
						<div className="col-md-6">
							<Button onClick={this.submit} disabled={ this.disabled() }>
								Enviar
							</Button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Remember;