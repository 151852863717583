import React from 'react';
import logo from "assets/img/logo/logo.png"
import profileSvg from "assets/icons/profile.svg"
import logoutSvg from "assets/icons/logout.svg"
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import { Globals } from 'utils';

class Header extends React.Component<any> {

    logout = () => {
		Globals.confirm('¿Desea cerrar sesión?',() => {
			Globals.setLoading();
			setTimeout(() => {
				this.props.dispatch({
					type: 'SET_USER',
					payload: null
				});
				this.props.dispatch({
					type: 'SET_CHAT',
					payload: null
                })
                this.redirect()
				Globals.quitLoading();
			},1500);
		});
    }

    redirect = () => {
        return <Redirect  to="/login"/>
    }
    
    render() {
        const { person } = this.props.user
        const username = person.name + ' ' +  person.lastname
        const photo = person.profile_picture_url;
        const styleBackground = { 
            backgroundImage: `url('${photo}')`
        }

		return (
            <nav className="header">
                <div className="header-start">
                    <div className="header-logo">
                        <img src={logo} className="img-fluid" alt="logoapp"/>
                    </div>
                </div>
                <div className="header-end">
                    <div className="header-user">
                        <span className="header-user-name">{username}</span>
                        <div className={"header-user-avatar"} style={photo ? styleBackground : {}}></div>
                    </div>
                    <div className="header-buttons">
                        <Link to="/profile" className="header-buttons-profile">
                            <img src={profileSvg} alt="profile"/>
                        </Link>
                        <a className="header-buttons-logout" onClick={(event: any) => {
                            event.preventDefault()
                            this.logout()
                        }}>
                            <img src={logoutSvg} alt="logout"/>
                        </a>
                    </div>
                </div>
            </nav>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user,
	}
})(Header);