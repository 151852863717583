export default {
	EVALUATIONS: {
		SEND_MESSAGE: 'evaluations/send-message',
		MESSAGE_VIEWED: 'evaluations/message-viewed',
		BUDGET: 'evaluations/budget',
		FINISH: 'evaluations/finish'
	},
	PATIENTS: {
		USER_DELETE: 'patients/user-delete',
		USER_DISABLE: 'patients/user-disable'
	},
	SHIFTS: {
		DAY: 1,
		LATE: 2
	},
	APPOINTMENTS: {
		PENDING: 0,
		CONFIRMED: 1,
		DONE: 2,
		CANCELED: 3,
		EXPIRED: 4
	},
	APPOINTMENTS_DESCRIPTION: {
		PENDING: 'Pendiente',
		CONFIRMED: 'Confirmada',
		DONE: 'Finalizada',
		CANCELED: 'Cancelada',
		EXPIRED: 'Expirada'
	},
	APPOINTMENTS_ACTIONS: {
		CONFIRM: 'appointments/confirm',
		CANCEL: 'appointments/cancel',
		FINISH: 'appointments/finish'
	},
	LEVELS: {
		SUPERADMIN: 1,
		ADMIN: 2,
		USER: 3
	},
	PAYMENT_METHODS: {
		PAYPAL: 1,
		EPAYCO: 2,
		STRIPE: 3,
		PAYMENT_CODE: 4,
	},
	EPAYCO_STATUS: {
		ACEPTADA: 'Aceptada' // El api de Epayco literalmente devuelve "Aceptada"
	},
	PRICES_TYPES: {
		EVALUATIONS: 1,
		APPOINTMENTS: 2
	},
	CURRENCIES: {
		USD: 1,
		COP: 2
	},
	CHAT: {
		MESSAGE: 'chat/message',
		RELOAD: 'chat/reload',
		RELOAD_BADGE: 'chat/reload/badge'
	},
	SCHEDULE: {
		RELOAD: 'schedule/reload'
	},
	PROCEDURES: {
		CHANGE: 'procedures/change'
	},
	PRICES: {
		UPDATE: 'prices/update'
	},
	CREATE_EVALUATION_SELECT: {
		OPERATIONS: 0,
		PROCEDURE_PHOTOS: 1,
		PROCEDURE_PHOTOS_REFERENCE: 2,
		PATIENT_INFO: 3,
		PAYMENT: 4,
		TREATMENT_INFO: 5,
		VIEW_PHOTOS: 6,
		VIEW_PHOTOS_REFERENCE: 7
	},
	CREATE_EVALUATION_OPERATIONS_SELECT: {
		PROCEDURES: 0,
		TREATMENTS: 1
	},
	GENDERS: [
		{
		  value: 'M',
		  label: 'Masculino'
		},
		{
		  value: 'F',
		  label: 'Femenino'
		}
	]
}