import React from 'react';
import Menu from 'screens/menu';
import { Input, Button, Pagination, Table, Icon, Select, Modal, ButtonAdd, Action } from 'components';
import { PatientService, CountryService, DocumentTypeService, PaymentCodesService } from 'services';
import { Globals, Socket, Constants } from 'utils';
import CreateEditPatients from './create-edit-patients';
import ChangePassword from './change-password';
import MedicalHistory from './medical-history';
import PrintJS from 'print-js';
import { on } from 'jetemit';
import Header from 'components/header';

class Patients extends React.Component<any> {

	state: any = {
		page: 1,
		last_page: 1,
		data: [],
		form: {
			search: '',
			country: 0,
			status: -1
		},
		edit: false,
		view: false,
		item: null,
		countries: [],
		loading: true,
		header: [
			'ID',
			'Nombre',
			'Correo Electrónico',
			'Teléfono',
			'País',
			'Estatus',
			'Acciones'
		],
		status: [
			{ value: -1, label: 'Todos' },
			{ value: 1, label: 'Activos' },
			{ value: 0, label: 'Inactivos' }
		],
		changePass: false,
		medical: false,
		title_history: 'Historial Médico',
		document_types: [],
		subscriber: () => null
	}

	async componentDidMount() {
		await this.getCountries();
		this.load();

		this.setState({
			subscriber: on(Constants.PATIENTS.USER_DELETE,async (id: number) => {
				let data: any = [...this.state.data];
				const index: number = data.findIndex((i: any) => i.id === id);
				if (index != -1) {
					if (this.state.item && this.state.item.id == id) {
						await this.setState({
							view: false,
							edit: false,
							item: null
						});
					}
					data.splice(index,1);
					this.setState({
						data
					});
				}
			})
		});
	}

	componentWillUnmount() {
		this.state.subscriber();
	}

	getCountries = async () => {
		const res: any = await Promise.all([
			CountryService.get({
				withoutLoading: true,
			}),
			DocumentTypeService.get({
				withoutLoading: true
			})
		])
		this.setState({
			countries: [
				{
					id: 0,
					name: "Todos"
				},
				...res[0].countries
			],
			document_types: res[1].types
		});
	}

	load = async (withoutLoading = false) => {
		const params = {
			page: this.state.page,
			search: this.state.form.search,
			country: this.state.form.country,
			status: this.state.form.status,
			withoutLoading
		}
		const res: any = await PatientService.get(params)
		if (res.patients.data.length == 0 && this.state.page > 1) {
			this.setState({
				page: 1
			});
			this.load();
		}
		else {
			this.setState({
				data: res.patients.data,
				last_page: res.patients.last_page,
				loading: false
			});
		}		
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	delete = (item: any) => {
		Globals.confirm('¿Desea eliminar el paciente?',async () => {
			await PatientService.delete({
				id: item.id
			});
			this.load();
			Socket.emit(Constants.PATIENTS.USER_DELETE,item.id);
		});
	}

	onCloseEdit = async () => {
      await this.setState({
        edit: false,
        item: null
      });
      this.load(true);
	}

	verified = (item: any) => {
		Globals.confirm('¿Desea verificar el paciente?',async () => {
			await PatientService.verified({
				id: item.id
			});
			this.load();
		});
	}

	generateCode = async (item: any) => {
        let data: any = { user: item.id};
        const res: any = await PaymentCodesService[this.props.item ? 'edit' : 'create'](data);
        if(res.code){
            Globals.showSuccess(res.msg)
        }
    }

	changeStatus = (item: any,action: string) => {
		Globals.confirm(`¿Desea ${ action.toLowerCase() } al paciente?`,async () => {
			await PatientService.changeStatus({
				id: item.id,
				status: item.status == 1 ? 0 : 1
			});
			this.load();
			if (item.status == 1)
				Socket.emit(Constants.PATIENTS.USER_DISABLE,item.id);
		});
	}

	changePass = (item: any) => {
		this.setState({
			item,
			changePass: true
		});
	}

	setTitleHistory = (title: string) => {
		this.setState({
			title_history: title
		});
	}

	updatePatient = (_data: any) => {
		const data: any = [ ...this.state.data ];
		const index: number = data.findIndex((i: any) => i.id == _data.user_id);
		data[index].data = _data;
		const item: any = { ...this.state.item };
		item.data = _data;
		this.setState({
			data,
			item
		});
	}

	print = async (patient: any) => {
		const res: any = await PatientService.print({
			user_id: patient.id
		});
		PrintJS({
			printable: res.url,
			onLoadingEnd: async () => {
				await PatientService.deletePrint({
					name: res.name,
					withoutLoading: true
				});
			}
		});
	}

	render() {
		return (
			<>
			<Header />
			<Menu history={ this.props.history }>
				<div id="patients">
					
					<Modal
						className="modal-lg"
			            title={ this.state.item != null ? 'Editar paciente' : 'Nuevo paciente' }
			            visible={ this.state.edit }
			            onClose={ () => {
			              this.setState({
			                edit: false,
			                item: null
			              });
			            } } 
			          >
			          <CreateEditPatients
			          	updatePatient={ this.updatePatient }
			          	document_types={ this.state.document_types }
			          	countries={ this.state.countries }
			          	item={ this.state.item }
			          	onClose={ this.onCloseEdit } />
			        </Modal>

			        <Modal
			        	className="modal-lg"
			            title={ this.state.title_history }
			            visible={ this.state.medical }
			            onClose={ () => {
			              this.setState({
			                medical: false,
			                item: null
			              });
			              this.setTitleHistory("Historial Médico");
			            } } 
			          >
			         	<MedicalHistory
			         		onClose={ () => {
				              this.setState({
				                medical: false,
				                item: null
				              });
				            } }
				            setTitleHistory={ this.setTitleHistory }
			         		patient={ this.state.item } /> 
			        </Modal>

			        <Modal
			        	className="modal-lg"
			            title="Ver paciente"
			            visible={ this.state.view }
			            onClose={ () => {
			              this.setState({
			                view: false,
			                item: null
			              });
			            } }
			          >
			          <CreateEditPatients
			          	updatePatient={ this.updatePatient }
			          	document_types={ this.state.document_types }
			          	view={ true }
			          	countries={ this.state.countries }
			          	item={ this.state.item }
			          	onClose={ () => {
			              this.setState({
			                view: false,
			                item: null
			              });
			            } } />
			        </Modal>

			        <Modal
			            title="Cambiar contraseña"
			            visible={ this.state.changePass }
			            onClose={ () => {
			              this.setState({
			                changePass: false
			              });
			            } }
			          >
			          <ChangePassword
			          	patient={ this.state.item }
			          	onClose={ () => {
			              this.setState({
			                changePass: false
			              });
			            } } />
			        </Modal>

					<form className="row form-filter" onSubmit={ async (e: any) => {
						e.preventDefault();
						await this.setState({
							page: 1
						});
						this.load();
					} }>
						<div className="col-md-3">
							<Input
								name="search" 
								label="Búsqueda"
								onChange={ this.change } 
								value={ this.state.form.search }
								placeholder="Buscar por Nombre, Correo o Teléfono" />
						</div>
						<div className="col-md-3">
							<Select
								name="country"
								label="País"
								onChange={ this.change }
								value={ this.state.form.country }
								options={ this.state.countries.map((i: any) => {
									return {
										value: i.id,
										label: i.name
									}
								}) } />
						</div>
						<div className="col-md-3">
							<Select
								name="status"
								label="Estatus"
								onChange={ this.change }
								value={ this.state.form.status }
								options={ this.state.status } />
						</div>
						<div className="col-md-3">
							<Button className="btn-align-bottom text-black font-bold">
								Filtrar
							</Button>
						</div>
					</form>
					<Table loading={ this.state.loading } data={ this.state.data.length } title="Pacientes" header={ this.state.header } right={
						<ButtonAdd  
						    onClick={ () => {
								this.setState({
								edit: true
								});
							} }/>
		                }>
						{ this.state.data.map((i: any,index: number) => {
							const action: string = i.status == 0 ? 'Activar' : 'Desactivar';

							return (
								<tr key={ index }>
									<td>{ i.id }</td>
									<td className="ellipsis">{ i.person.name + ' ' + i.person.lastname }</td>
									<td>{ i.email }</td>
									<td>{ i.person.phone }</td>
									<td>{ i.person.country.name }</td>
									<td>{ i.status == 1 ? 'Activo' : 'Inactivo' }</td>
									<td>
										{/* <Button color="info" title="Ver" small="true" onClick={ () => {
											this.setState({
												item: i,
												view: true
											});
										} }>
											<Icon name="eye" />												
										</Button> */}
										<Action title="Ver" icon="show" onClick={ () => {
											this.setState({
												item: i,
												view: true
											}); }}
										/>

										<Action title="Editar" icon="edit" onClick={ () => {
											this.setState({
												item: i,
												edit: true
											});
										} } />

										{ !Globals.isSocial(i) && 
											<Action  title="Cambiar contraseña" icon="password" 
											onClick={ () => this.changePass(i) } />
										}
										<Action title="Historial Médico" icon="medical" onClick={ () => {
											this.setState({
												medical: true,
												item: i
											});
										} } />

										{/* <Button  title="Veracidad de la Información" small="true" onClick={ () => this.print(i) }>
											<Icon name="print" />												
										</Button> */}

										<Action  title="Veracidad de la Información" icon="veratity" onClick={ () => this.print(i) } />
										
										<Action  title={ action } icon={i.status == 1 ? 'lock' : 'unlock'} onClick={ () => this.changeStatus(i,action) } />
										
										{/* <Button title={ action } small="true" onClick={ () => this.changeStatus(i,action) }>
											<Icon name={ i.status == 1 ? 'lock' : 'unlock' } />												
										</Button> */}

										{ i.verified == 0 && <Button title="Verificar" small="true" onClick={ () => this.verified(i) }>
											<Icon name="check" />												
										</Button> }
										{/* <Button  title="Eliminar" small="true" onClick={ () => this.delete(i) }>
											<Icon name="trash" />												
										</Button> */}

										<Action title="Generar código de pago" icon="menu-prices" onClick={ () => this.generateCode(i) } />

										<Action title="Eliminar" icon="delete" onClick={ () => this.delete(i) } />
									</td>
								</tr>
							)
						}) }
					</Table>

					<Pagination 
						pages={ this.state.last_page } 
						active={ this.state.page }
						onChange={ async (page: number) => {
							await this.setState({
								page: page
							});
							this.load();
					} } />
				</div>
			</Menu>
					</>
		)
	}
}

export default Patients;