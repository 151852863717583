import { axios, Error, Globals } from 'utils';
import { AxiosError, AxiosResponse } from 'axios';

class Api {
	
	createResponse = (uri: string,params?: any) => {	
		if (!params) {
			params = {};
		}	
		return new Promise((resolve,reject) => {
			if (!params.withoutLoading) {
				Globals.setLoading();
			}
			axios[params.hasFile ? 'upload' : 'post'](uri,params)
				.then((res: AxiosResponse) => {
					resolve(res.data);
				})
				.catch((err: AxiosError) => {
					Error.default(err);
					reject(err);
				})
				.finally(() => {
					if (!params.withoutQuitLoading)
						Globals.quitLoading();
				});
		});
	}
}

export default new Api();