import React from 'react';
import { Table } from 'components';
import { Globals } from 'utils';

interface PaymentProps {
	appointment: any
}

class PaymentData extends React.Component<any> {
	
	render() {
		const payment = this.props.appointment && this.props.appointment.payment;

		return (
			<div className="evaluation-payment-data">
				{ payment ? (
					<Table title="Datos de pago" data={ 1 }>
						<tr>
							<th>Monto</th>
							<td>{ Globals.formatMiles(payment.amount,true,payment.currency.code) }</td>
						</tr>
						<tr>
							<th>Método de Pago</th>
							<td>{ payment.method.name }</td>
						</tr>
						<tr>
							<th>Fecha</th>
							<td>{ Globals.getDate(payment.created_at,'DD/MM/YYYY HH:mm') }</td>
						</tr>
						<tr>
							<th>Número de confirmación</th>
							<td>{ payment.response_code }</td>
						</tr>
					</Table>
				) : (
					<p className="no-payment">No se encontraron datos de pago</p>
				) }
			</div>
		)
	}
}

export default PaymentData;