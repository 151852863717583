import React from 'react';
import { Button, Input, Select, DatePicker } from 'components';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { EpaycoService } from 'services';
import { Globals, Constants, ENV } from 'utils';
import { ePaycoLogo, ePaycoWhite } from 'assets/img';
import { connect } from 'react-redux';
import moment from 'moment';

let ePayco: any = ENV.EPAYCO_PUBLIC;

interface ePaycoProps {
  onError: (err: any, method: number) => void,
  onSuccess: (payment: any, method: number) => void,
  total: number,
  showForm: boolean,
  onPay: () => void,
  onBack: () => void,
  showPayment: boolean,
  user: any,
  document_types: []
}

const INIT_FORM = {
  name: '',
  lastname: '',
  email: '',
  number: '',
  cvc: '',
  date: '',
  document: '',
  document_type: 'CC'
}

class _ePayco extends React.Component<ePaycoProps> {
  
  state = {
    form: INIT_FORM,
    document_types: [...this.props.document_types]
  }


  componentDidMount() {
  //  ePayco.setPublicKey(ENV.EPAYCO_PUBLIC);
    
    // Se crea el keyUserIndex de ePayco en caso de que no exista
    if (!localStorage.getItem("keyUserIndex")) {
      this.createGuid();
    }

    const user: any = this.props.user;

    this.setState({
      form: {
        ...this.state.form,
        name: user.person.name,
        lastname: user.person.lastname,
        email: user.email
      }
    });
  }
  
  // Función para crear el keyUserIndex en caso de que no existe, esto para evitar un error en el Api de ePayco
  createGuid = () => {
      const e = () => {
          return Math.floor(65536 * (1 + Math.random())).toString(16).substring(1)
      }
      localStorage.setItem("keyUserIndex", e() + e() + "-" + e() + "-" + e() + "-" + e() + "-" + e() + e() + e());
  }

  pay = (e: any) => {
    e.preventDefault();

    Globals.setLoading();

    const data: any = {
      card: {
       name: this.state.form.name,
       last_name: this.state.form.lastname,
       email: this.state.form.email,
       number: this.state.form.number,
       cvc: this.state.form.cvc,
       exp_month: moment(this.state.form.date,'MM/YY').format('MM'),
       exp_year: moment(this.state.form.date,'MM/YY').format('YY'),
       doc_type: this.state.form.document_type,
       doc_number: this.state.form.document
      }
    }

    ePayco.token.create(data, (err: any, token: any) => {
        if (!err) {
            this.process(token,data);
        } else {
            console.log(err);
            this.props.onError(err,Constants.PAYMENT_METHODS.EPAYCO);
        }
    });
  }

  process = async (token: string,data: any) => {
    const res: any = await EpaycoService.pay({
      name: data.card.name,
      last_name: data.card.last_name,
      amount: this.props.total,
      email: data.card.email,
      token,
      doc_type: data.card.doc_type,
      doc_number: data.card.doc_number,
      user_id: this.props.user.id,
      withoutLoading: true
    });
    Globals.quitLoading();
    this.props.onBack();
    this.props.onSuccess(res,Constants.PAYMENT_METHODS.EPAYCO);
  }

  change = (e: any) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  }

  disabled = () => {
    const {
      name,
      lastname,
      email,
      number,
      date,
      cvc,
      document,
      document_type
    } = this.state.form;
    return name == null || name == '' ||
           lastname == null || lastname == '' ||
           email == null || email == '' ||
           number == null || number == '' ||
           date == null || date == '' ||
           cvc == null || cvc == '' ||
           document == null || document == '' ||
           document_type == null || document_type == '';
  }

  render() {
    if (this.props.showPayment)
      return (
        <div className="epayco">
          {
            !this.props.showForm ? (
              <Button className="btn-epayco" onClick={ this.props.onPay }>
                <img src={ ePaycoLogo } />
              </Button>
            ) : (
              <form onSubmit={ this.pay }>
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      value={ this.state.form.name }
                      name="name" 
                      label="Nombre"
                      onChange={ this.change } />
                  </div>
                  <div className="col-md-6">
                    <Input
                      value={ this.state.form.lastname }
                      name="lastname" 
                      label="Apellido"
                      onChange={ this.change } />
                  </div>
                  <div className="col-md-6">
                    <Input
                      value={ this.state.form.email }
                      name="email" 
                      label="Correo Electrónico"
                      onChange={ this.change } />
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="number"
                      value={ this.state.form.number }
                      name="number" 
                      maxlength="16"
                      label="Número de la tarjeta"
                      onChange={ this.change } />
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="password"
                      value={ this.state.form.cvc }
                      name="cvc" 
                      maxlength="4"
                      label="Código secreto"
                      onChange={ this.change } />
                  </div>
                  <div className="col-md-6">
                     <DatePicker
                        name="date"
                        dateFormat="MM/yy"
                        showMonthYearPicker
                        label="Fecha de vencimiento"
                        minDate={ moment().add(1,'month').toDate() }
                        value={ this.state.form.date }
                        onChange={ async (value: string) => {
                            await this.setState({
                                form: {
                                    ...this.state.form,
                                    date: value
                                }
                            });
                        } } />
                  </div>
                  <div className="col-md-6">
                    <Select
                      name="document_type"
                      label="Tipo de documento"
                      onChange={ this.change }
                      value={ this.state.form.document_type }
                      options={ this.state.document_types.map((i: any) => {
                        return {
                          value: i.name,
                          label: i.name
                        }
                      }) } />
                  </div>
                  <div className="col-md-6">
                    <Input
                      value={ this.state.form.document }
                      name="document" 
                      label="Número de documento"
                      onChange={ this.change } />
                  </div>
                </div>
                <div className="text-center">
                  <Button type="submit" disabled={ this.disabled() }>
                    Pagar con <img src={ ePaycoWhite } />
                  </Button>
                </div>
              </form>
            )
          }        
        </div>
      )
    else
      return <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
  }

}

export default connect((state: any) => {
  return {
    user: state.user
  }
})(_ePayco);