import React from 'react';
import { Modal } from 'react-bootstrap';
import { Globals } from 'utils';

const _Modal = React.forwardRef((props: any, ref) => (
	<Modal ref={ ref as React.RefObject<any> } id={ props.id } className={ props.className } onShow={ Globals.hideTooltip } show={ props.visible } onHide={ props.onClose }>
		<Modal.Header closeButton>
		  <Modal.Title>{ props.title }</Modal.Title>
		</Modal.Header>
		<Modal.Body>{ props.children }</Modal.Body>
	</Modal>
))

export default _Modal;