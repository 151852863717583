import axios from "axios";
// import ENV from "./env";

const { REACT_APP_BASE_API: baseURL } = process.env

let _axios: any = axios.create({
  baseURL
});

const createFormData = (data: any) => {
  var formdata = new FormData();
  for (var key in data) {
    if (Array.isArray(data[key])) {
      for (var _key in data[key]) {
        formdata.append(key + '[]', data[key][_key]);
      }      
    }
    else {
      formdata.append(key, data[key]);
    }    
  }
  return formdata;
}

_axios.all = axios.all;
_axios.spread = axios.spread;
_axios.upload = (url: string, data: any) => {
  return _axios.post(url, createFormData(data), {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};
export default _axios;
