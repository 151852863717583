import React from 'react';
import Menu from 'screens/menu';
import { Table, Button, Icon, Modal, Select, Pagination, DatePicker, ButtonAdd } from 'components';
import { UnitService, EvaluationService, ProcedureService, PriceService, DocumentTypeService, TreatmentService } from 'services';
import { connect } from 'react-redux';
import moment from 'moment';
import { Globals, Constants } from 'utils';
import ViewEvaluation from './view-evaluation';
import CreateEvaluation from './create-evaluation';
import { on, emit } from 'jetemit';
import Header from 'components/header';
import Swal from 'sweetalert2';

const { 
	PAYMENT,
	VIEW_PHOTOS,
	VIEW_PHOTOS_REFERENCE
} = Constants.CREATE_EVALUATION_SELECT;
class Evaluations extends React.Component<any> {
	constructor(props: any) {
		super(props);
		this.createEvalutaion = React.createRef<any>();
	  }
	createEvalutaion: any
	state: any = {
		data: [],
		last_page: 1,
		page: 1,
		loading: true,
		form: {
			status: '',
			since: '',
			until: ''
		},
		header: [
			'Procedimientos',
			'Fotos',
			'Presupuesto',
			'Fecha',
			'Estatus',
			'Acciones'
		],
		view: false,
		create: false,
		item: null,
		status: [
			{ value: -1, label: "Todos" },
			{ value: 1, label: "Activo" },
			{ value: 0, label: "Inactivo" }
		],
		procedures: [],
		prices: [],
		document_types: [],
		subscriber: () => null,
		subscriberBudget: () => null,
		listen: true,
		subscriberProcedures: () => null,
		contact: null,
		typePhotos: [],
		units: [],
		step: 0,
		treatments: []
	}

	componentDidMount() {
		this.loadData();
		this.load();
		this.setState({
			subscriber: on(Constants.EVALUATIONS.SEND_MESSAGE,(_data: any) => {
				if (_data.user_id != this.props.user.id && this.state.listen) {
					const data: any = [...this.state.data];
					const index = data.findIndex((i: any) => i.id == _data.evaluation_id);
					if (index != -1) {
						data[index].badge = _data.badge;
						data[index].messages.unshift(_data);
						this.setState({
							data
						});
					}	
				}							
			}),
			subscriberBudget: on(Constants.EVALUATIONS.BUDGET,(_data: any) => {
				let data: any = [ ...this.state.data ];
				const index = data.map((i: any) => i.id).indexOf(_data.id);
				if (index != -1) {
					data[index] = _data;
					this.setState({
						data
					});
				}
				// @ts-ignore
				if (_data.id == this.state.item.id) {
					this.setState({
						item: _data
					});
				}
			}),
			subscriberProcedures: on(Constants.PROCEDURES.CHANGE,(procedures: any) => {
				const selected: any = [...this.state.procedures.filter((i: any) => i.selected == true).map((i: any) => i.id)];
				this.setState({
					procedures: procedures.map((i: any) => {
						i.selected = false;
						if (selected.indexOf(i.id) != -1) {
							i.selected = true;
						}
						return i;
					})
				});
			})
		});

		this.checkEvaluationPendig();
	}

	componentWillUnmount() {
		this.state.subscriber();
		this.state.subscriberBudget();
		this.state.subscriberProcedures();
	}

	checkEvaluationPendig = async () => {
		const response: any = await EvaluationService.checkEvaluationPending({
			user_id: this.props.user.id,
		});
		if(response){
			if (response.evaluation) {
				let msg = "Posees una consulta pendiente por cancelar. Dirigite a 'solicitar una cita online' para culminar el proceso de pago, o haz click en eliminar si deseas comenzar una nueva consulta.";
				Swal.fire({
					title: "",
					text: msg,
					type: "warning",
					showCancelButton: true,
					confirmButtonText: 'Aceptar',
					cancelButtonText: 'Eliminar',
					customClass: {
						popup: 'swal-custom-popup',
						header: 'swal-custom-color-icons',
						actions: 'swal-custom-actions',
						confirmButton: 'swal-custom-confirm-button',
						cancelButton: 'swal-custom-cancel-button'
					}
				}).then(async confirm => {
					if (confirm.dismiss) {
						const res: any = await EvaluationService.deleteEvaluationPending({
							user_id: response.evaluation.user_id,
							id: response.evaluation.id,
						});
						if(res.result){
							Globals.showSuccess("Eliminada consulta pendiente.");
						}
					}
				});	
			}
		}
		else {
			console.log(response.error);
		}
	}

	loadData = async () => {
		// const res: any = await Promise.all([
		// 	ProcedureService.get({
		// 		withoutLoading: true,
		// 		photos: true,
		// 		all: true
		// 	}),
		// 	PriceService.get({
		// 		withoutLoading: true
		// 	}),
		// 	DocumentTypeService.get({
		// 		withoutLoading: true
		// 	}),
		// 	TreatmentService.get({
		// 		withoutLoading: true,
		// 		all: true
		// 	}),
		// 	UnitService.get({
		// 		withoutLoading: true
		// 	})
		// ])

		const res: any = await EvaluationService.getData({
			withoutLoading: true
		});
		this.setState({
			procedures: res.procedures,
			prices: res.prices,
			document_types: res.types,
			treatments: res.treatments,
			contact: res.contact,
			typePhotos: res.typePhotos,
			units: res.units
		});
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await EvaluationService.getClient({
			user_id: this.props.user.id,
			page: this.state.page,
			...this.state.form,
			withoutLoading
		});
		this.setState({
			data: res.evaluations.data,
			last_page: res.evaluations.last_page,
			loading: false
		});
	}

	add = () => {
		this.setState({
			create: true
		});
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	updateEvaluation = (item: any) => {
		const data: any = [ ...this.state.data ];
		data[data.findIndex((i: any) => i.id == item.id)] = item;
		this.setState({
			data: data
		});
	}

	onClose =  () => {
		const {step} = this.state
		if(step === VIEW_PHOTOS || step === VIEW_PHOTOS_REFERENCE) {
			// console.log('create', this.createEvalutaion)
			emit('CLOSE_PHOTOS')
			// this.createEvalutaion.changeSection(PAYMENT)
			return
		}
		this.setState({
			create: false
		});
	}

	render() {
		const { units, document_types, prices, procedures, treatments, contact, typePhotos } = this.state;
		const disabled: boolean = units.length == 0 || document_types.length == 0 ||
								  prices.length == 0 || procedures.length == 0 ||
								  typePhotos.length == 0 || contact == null;

		return (
			<>
			<Header />
			<Menu history={ this.props.history }>
				<div id="client-evaluations">

					<Modal
						className="modal-lg modal-evaluations"
			            title="Solicitar cita online"
			            visible={ this.state.create }
			            onClose={ this.onClose } 
			          >
			          <CreateEvaluation
					  	setStep={(step) => {
							console.log('step', step)
							this.setState({
								step
							})
						}}
						ref={(ref) => {
							console.log('ref', ref)
							this.createEvalutaion = ref
						}}
			          	document_types={ this.state.document_types }
			          	prices={ this.state.prices }
						procedures={ this.state.procedures }
						treatments={ this.state.treatments }
						contact={ this.state.contact }
						typePhotos={ this.state.typePhotos }
						units={ this.state.units }
			          	onClose={ async () => {
			              await this.setState({
			                create: false,
			                page: 1
			              });
			              this.load(true);
			            } }  />
			        </Modal>

			        <Modal
						className="modal-lg"
			            title="Ver Evaluación"
			            visible={ this.state.view }
			            onClose={ () => {
			              this.setState({
			                item: null,
			                view: false,
			                listen: true
			              });
			            } } 
			          >
			          <ViewEvaluation
			          	setListen={ (status: boolean) => {
			            	this.setState({
			            		listen: status
			            	});
			            } }
			          	updateEvaluation={ this.updateEvaluation }
			          	evaluation={ this.state.item != null && this.state.data.find((i: any) => i.id == this.state.item.id) } />
			        </Modal>

			        {/* <form className="row form-filter" onSubmit={ async (e: any) => {
						e.preventDefault();
						await this.setState({
							page: 1
						});
						this.load();
					} }>
						<div className="col-md-2">
							<Select
								name="status"
								label="Estatus"
								onChange={ this.change }
								value={ this.state.form.status }
								options={ this.state.status } />
						</div>
						<div className="col-md-2">
							<DatePicker
								label="Desde"
								maxDate={ this.state.form.until ? moment(this.state.form.until).toDate() : null }
								onChange={ (text: string) => {
									this.setState({
										form: {
											...this.state.form,
											since: text
										}	
									});
								} }
								value={ this.state.form.since }
							/>
						</div>
						<div className="col-md-2">
							<DatePicker
								label="Hasta"
								minDate={ this.state.form.since ? moment(this.state.form.since).toDate() : null }
								onChange={ (text: string) => {
									this.setState({
										form: {
											...this.state.form,
											until: text
										}										
									});
								} }
								value={ this.state.form.until }
							/>
						</div>
						<div className="col-md-2">
							<Button className="btn-align-bottom text-black font-bold">
								Filtrar
							</Button>
						</div>
					</form> */}

					<Table loading={ this.state.loading } data={ this.state.data.length } 
						title="Asesoría online" header={ this.state.header } 
						center={ [1,2,3] } 
						right={	
							<Button className="text-black font-bold" onClick={ this.add } disabled={ disabled }>
								Solicitar cita online
							</Button>
						}>
	                	{ this.state.data.map((i: any, index: number) => {
							return (
			                	<tr key={index}>
									<td>
										<ul>
											{
												i.procedures.map((i: any, key: number) => (
													<li key={key} className="item-procedure">
														<p className="ellipsis">{ i.name }</p>
													</li>
												))
											}
										</ul>										
									</td>
									<td className="text-center">{ i.photos.length }</td>
									<td className="text-center">{ i.budgets.length > 0 ? (
										<p>{ Globals.formatMiles(i.budgets[0].amount,true,i.budgets[0].currency.code) }</p>
									) : (
										<Icon name="close"  />
									) }</td>
									<td className="text-center">{ moment(i.created_at).format('DD/MM/YYYY HH:mm') }</td>
									<td>{ i.status == 1 ? 'Activo' : 'Inactivo' }</td>
									<td>
										<Button badge={ i.badge || i.messages.filter((i: any) => i.view == 0 && i.user_id != this.props.user.id).length } color="info" title="Ver" small="true" onClick={ () => {
											this.setState({
												item: i,
												view: true
											});
										} }>
											<Icon name="eye" />												
										</Button>						
									</td>
								</tr>
							)
						}) }
					</Table>

					<Pagination 
						pages={ this.state.last_page } 
						active={ this.state.page }
						onChange={ async (page: number) => {
							await this.setState({
								page: page
							});
							this.load();
					} } />
				</div>
			</Menu>
			</>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user,
		evaluation: state.evaluation,
	}
})(Evaluations);