import moment from 'moment'

class Format {

    filterPhotosTypes = (photos:any, procedures:any) => {
        
        const Ids = procedures.map((procedure:any) => {
            return procedure.photos.map((photo:any) => {
                return photo.id;
            }).flat();
        }).flat();

        const uniqueIds = Ids.filter((id:number, index: number) => {
            return Ids.indexOf(id) === index;
        }).sort((a:number, b: number) => {
            return a >= b;
        });

        const photosFiltered = photos.filter((photo:any) => {
            return uniqueIds.includes(photo.id)
        })

        return photosFiltered;
    }

    filterPhotos = (typePhotosFiltered:any, photos:any) => {
        
        const typePhotosFilteredIds = typePhotosFiltered.map((typePhoto:any) => {
            return typePhoto.id;
        });
        const photosFiltered = photos.filter((photo:any) => {
            return typePhotosFilteredIds.includes(photo.typePhotoId)
        })

        return photosFiltered
    }

    getUploadedPhotosTypes = (typePhotosFiltered:any, typePhotosState:any) => {

        const typePhotosUpdated = typePhotosFiltered.map((typeFiltered:any) => {
            const index = typePhotosState.findIndex((typeState:any) => typeState.id === typeFiltered.id)
            let typeFilteredUpdated = typeFiltered
            if(index > -1) {
                typeFilteredUpdated = typePhotosState[index];
            }
            return typeFilteredUpdated;
        })

        return typePhotosUpdated

    }

    age = (birthdate: string): number => {
        return moment().diff(moment(birthdate, 'YYYY-MM-DD'), 'years')
    }

}

export default new Format();