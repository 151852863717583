import { Api } from 'utils';

class EvaluationService {
	
	static get = async (params?: any) => Api.createResponse('evaluations/get',params);
	static send = async (params?: any) => Api.createResponse('evaluations/send',params);
	static viewMessages = async (params?: any) => Api.createResponse('evaluations/view-messages',params);
	static finish = async (params?: any) => Api.createResponse('evaluations/finish',params);

	// Clients
	static getClient = async (params?: any) => Api.createResponse('clients/evaluations/get',params);
	static create = async (params?: any) => Api.createResponse('clients/evaluations/create',params);
	static paymentError = async (params?: any) => Api.createResponse('clients/evaluations/payment-error',params);
	static getData = async (params?: any) => Api.createResponse('clients/evaluations/get-data',params);
	static checkEvaluationPending = async (params?: any) => Api.createResponse('clients/evaluations/checkpending',params);
	static deleteEvaluationPending = async (params?: any) => Api.createResponse('clients/evaluations/deletepending',params);
}

export default EvaluationService;