import React from 'react';
import Menu from 'screens/menu';
import { connect } from 'react-redux';
import { Input, Button, Icon } from 'components';
import { Socket, Constants, ENV, Globals } from 'utils';
import moment from 'moment';
import { on, emit } from 'jetemit';
import { ChatService } from 'services';
import Header from 'components/header';

class Chat extends React.Component<any> {

	private messages: any;

	state: any = {
		chat: null,
		chats: [],
		messages: [],
		message: '',
		unsuscriber: () => null,
		subscriberChat: () => null,
		subscriberDelete: () => null,
		admin: null
	}

	componentWillUnmount() {
		this.state.unsuscriber();
		this.state.subscriberChat();
		this.state.subscriberDelete();
	}

	componentDidMount() {
		if (this.props.user.level === Constants.LEVELS.USER) {
			this.loadMessages();
		}
		else {
			this.loadChats();
		}		

		this.setState({
			unsuscriber: on(Constants.CHAT.MESSAGE,async (message: any) => {
				if (this.state.chat && message.chat.id == this.state.chat.id) {
					message.viewed = 1;
	
					if (this.state.chats.length > 0) {
						let chats: any = [...this.state.chats];
						const index: number = chats.findIndex((i: any) => i.id == message.chat_id);
						let chat: any = chats[index];
						chat.updated_at = message.updated_at;
						chats.splice(index,1);
						chats.unshift(chat);
						this.setState({
							chats
						});
					}

					await this.setState({
						messages: [
							...this.state.messages,
							message
						]				
					},this.scrollToBottom);

					if (message.user_id != this.props.user.id) {
						await ChatService.viewed({ 
							id: message.id,
							withoutLoading: true
						});
					}
				}
				else if (this.state.chats.length > 0) {
					let chats: any = [...this.state.chats];
					const index: number = chats.findIndex((i: any) => i.id == message.chat_id);
					chats[index].messages.push(message);
					this.setState({
						chats
					});
				}
			}),
			subscriberChat: on(Constants.CHAT.RELOAD,() => {
				this.loadChats(true);
			}),
			subscriberDelete: on(Constants.PATIENTS.USER_DELETE,(data: any) => {
				this.loadChats(true);

				if (this.props.user.level != Constants.LEVELS.USER) {
					const user: any = this.state.chat.users.find((i: any) => i.level == Constants.LEVELS.USER);

					if (data == user.id) {
						this.setState({
							chat: null,
							messages: []
						});
						Globals.showError("Lo sentimos, el usuario fue eliminado");
					}
				}
			})
		});
	}

	loadChats = async (withoutLoading: boolean = false) => {
		const res: any = await ChatService.get({ 
			id: this.props.user.id,
			withoutLoading
		});
		this.setState({
			chats: res.chats
		});
	}

	loadMessages = async () => {
		const res: any = await ChatService.messages({
			id: this.state.chat?.id, 
			user_id: this.props.user.id,
			getAdminWeb: true
		});
		const chats: any = [...this.state.chats];
		const index: number = chats.findIndex((i: any) => i.id == res.chat.id);
		chats[index] = res.chat;
		this.setState({
			messages: res.chat.messages,
			chat: res.chat,
			chats,
			admin: res.chat.adminWeb
		},this.scrollToBottom);

		emit(Constants.CHAT.RELOAD_BADGE);

		if (res.create) {
			Socket.emit(Constants.CHAT.RELOAD);
		}
	}

	setActive = async (chat: any) => {
		await this.setState({
			chat: chat,
		});
		this.loadMessages();
	}

	getPosition = (message: any) => {
		let position = 'left';
		if (message.user.level == this.props.user.level) {
			position = 'right';
		}
		return position;
	}

	scrollToBottom = () => {
		this.messages.scrollTop = this.messages.scrollHeight;
	}

	send = async (validate = true) => {
		if (this.state.message == '') {
			return;
		}
	    Socket.emit(Constants.CHAT.MESSAGE,{
	    	chat_id: this.state.chat.id,
			text: this.state.message,
			user_id: this.props.user.id
		});
		this.setState({
			message: ''
		},this.scrollToBottom);
	}

	change = async (e: any) => {
		await this.setState({
			[e.target.name]: e.target.value
		});
	}

	onKeyUp = (e: any) => {
		e.preventDefault();
		if (e.key == 'Enter') {
			this.send();
		}
	}

	getUserName = (users: any) => {
		if (this.props.user.level == Constants.LEVELS.USER) {
			return ENV.NAME;
		}
		else {
			const user: any = users.find((i: any) => i.level == Constants.LEVELS.USER);
			return user.person.name + ' ' + user.person.lastname;
		}
	}

	render() {
		const is_admin: boolean = this.props.user.level != Constants.LEVELS.USER;
		const photoAdminWeb = this.state.admin?.person?.profile_picture_url;
		const styleBackground = { 
            backgroundImage: `url('${photoAdminWeb}')`
        }

		return (
			<>
			<Header />
			<Menu history={ this.props.history }>
				<div id="chat-client">
					<div className="container-chat">
						<div className="row">
							{
								  is_admin && (
									<div className="col-md-4 no-padding-right">
										<div className="list-chats">
											{ this.state.chats.map((i: any,index: number) => {
												i.count = i.messages.filter((_i: any) => _i.user.level != this.props.user.level && _i.view == 0).length;
												return (
													<div key={ index } className={ `item-chat ${ this.state.chat && this.state.chat.id == i.id ? 'active' : '' }` } onClick={ () => this.setActive(i) }>
														{ i.count > 0 && <div className="badge">{ i.count }</div> }
														<h2>{ this.getUserName(i.users) }</h2>
														<p className="date">{ moment(i.updated_at || i.created_at).format('DD/MM/YYYY HH:mm') }</p> 
													</div>
												)
											}) }
										</div>
									</div>
								)
							}							
							<div className={ is_admin ? 'col-md-8 no-padding-left' : 'col-md-12' }>
								<div className="container-messages">
									{ this.state.chat && 
										<div className="top-messages">
											<div className="top-messages-admin-avatar" style={photoAdminWeb ? styleBackground : {}}></div>
											<h2>{ this.getUserName(this.state.chat.users) }</h2>
										</div> 
									}

									<div className="container-scroll" ref={ ref => this.messages = ref }>
										{ !this.state.chat && is_admin && <h2 className="no-chat">Por favor, seleccione un chat</h2> }

										{ this.state.messages.map((i: any,index: number) => {
											return (
												<div className={ `message ${ this.getPosition(i) }` } key={ index }>
													<h2 dangerouslySetInnerHTML={{__html: Globals.urlify(i.text)}}></h2>
													<p>{ moment(i.created_at).format('DD/MM/YYYY HH:mm') }</p>
													<div className={ `triangle-${ this.getPosition(i) }` }></div>
												</div>
											)									
										}) }
									</div>

									<div className="container-input">
										<Input 
											disabled={ !this.state.chat }
											value={ this.state.message }
											onChange={ this.change }
											name="message"
											onKeyUp={ this.onKeyUp } />
										<Button 
											onClick={ this.send }
											disabled={ !this.state.chat || !this.state.message }>
											<img className="chat-send-message-icon" src={require(`assets/icons/chat-send-message.svg`)} alt="ico"/>
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Menu>
			</>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user,
	}
})(Chat);