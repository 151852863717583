import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Globals, Constants } from 'utils';
import menuSvg from 'assets/icons/menu.svg'

class Menu extends React.Component<any> {

	state = {
		leftOpen: false,
		items: [
			{ name: 'Pacientes', path: '/', icon: 'patients.svg' },
			{ name: 'Asesoría online', path: '/evaluations', icon: 'evaluations.svg' },	
			{ name: 'Consulta presencial', path: '/appointments', icon: 'appointments.svg' },
			{ name: 'Procedimientos', path: '/procedures', icon: 'procedures.svg' },					
			{ name: 'Chat', path: '/chat', icon: 'chat.svg' },
			{ name: 'Horario', path: '/schedule', icon: 'schedule.svg' },
			{ name: 'Precios', path: '/prices', icon: 'prices.svg' },
			{ name: 'Moderadores', path: '/moderators', icon: 'moderators.svg' },
			{ name: 'Correos', path: '/emails', icon: 'emails.svg' },
			{ name: 'Codigos de pago', path: '/paymentcodes', icon: 'prices.svg' },
		],
		items_client: [
			{ name: 'Asesoría online', path: '/', icon: 'evaluations.svg' },
			{ name: 'Consulta presencial', path: '/clients/appointments', icon: 'appointments.svg' },
			{ name: 'Chat', path: '/chat-client', icon: 'chat.svg' },
		]
	}

	logout = () => {
		Globals.confirm('¿Desea cerrar sesión?',() => {
			Globals.setLoading();
			setTimeout(() => {
				this.props.dispatch({
					type: 'SET_USER',
					payload: null
				});
				this.props.dispatch({
					type: 'SET_CHAT',
					payload: null
				})
				this.props.history.push('/login');
				Globals.quitLoading();
			},1500);
		});
	}

	toggleSidebar = (event: any) => {
	    let key: any = `${ event.currentTarget.parentNode.id }Open`;
	    // @ts-ignore
	    this.setState({ [key]: !this.state[key] });
	}

	componentDidUpdate() {
		$(window).trigger('resize');
	}

	checkPath = (i: any, permissions: Array<any>) => {
		let _path = permissions.find((_i: any) => _i.path === i.path);
		return _path || this.props.user.level != Constants.LEVELS.ADMIN;
	}

	render() {
		const leftOpen = this.state.leftOpen ? "open" : "closed";
		const chatUrl = this.props.user.level === Constants.LEVELS.USER ? '/chat-client' : '/chat'
		let items = [];
		if (this.props.user.level == Constants.LEVELS.USER) {
			items = this.state.items_client;
		}
		else {
			items = this.state.items;
		}

		let permissions: any = [];
		if (this.props.user.permissions) {
			permissions = [ ...this.props.user.permissions ];
		}

		return (
			<React.Fragment>
				<nav className="navbar navbar-expand-md bg-dark navbar-dark fixed-top justify-content-end">
				  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
				    <span className="navbar-toggler-icon"></span>
				  </button>

				  <div className="collapse navbar-collapse" id="collapsibleNavbar">
				    <ul className="navbar-nav">
				      	{ items.map((i,index) => {
							if (this.checkPath(i,permissions)) {
								return (
									<li key={ index } className={ 'nav-item ' + (this.props.history.location.pathname == i.path ? 'active' : '') }>
										<Link to={ i.path } className="nav-link">
											{ i.name }
											{ (i.path === chatUrl )
													&& this.props.chat > 0
													&& <span className="badge">{ this.props.chat }</span> }
										</Link>
									</li>
								)
							}
							else
								return null;					
						}) }

				      	<li className="nav-item">
							<a href="#" data-toggle="collapse" data-target=".navbar-collapse.show" className="nav-link" onClick={ this.logout }>
								Cerrar Sesión
							</a>
						</li>
				    </ul>
				  </div> 
				</nav>
				
				<div id="layout">
					<div id="left" className={ leftOpen }>

						<div className={`icon ${this.state.leftOpen ? 'ml-4' : 'ml-3'} p-1`} onClick={ this.toggleSidebar }>
				              {/* &equiv; */}
							  <img src={menuSvg} alt=""/>
				        </div>
						
						<div className={`sidebar ${ leftOpen }`}>
							<div className="container-menu">
								<div className="menu">
									{/* <button className="btn btn-default btn-logout" onClick={ this.logout }>
										<i className="fa fa-power-off"></i>
									</button> */}
									{/* <Link className="btn btn-default btn-logout btn-profile" to="/profile">
										<i className="fa fa-user"></i>
									</Link>
									<div className="container-user">
										<h2>{ this.props.user.person.name + ' ' + this.props.user.person.lastname }</h2>
									</div> */}
									<ul className="menu">
										{ items.map((i,index) => {
											if (this.checkPath(i,permissions)) {
												return (
													<li key={ index } className={ 'nav-item ' + (this.props.history.location.pathname == i.path ? 'active' : '') }>
														<Link to={ i.path } className="nav-link">
															<img className="nav-link-icon" src={require(`assets/icons/menu-${i.icon}`)} alt="ico" width="22" height="22"/>&nbsp;&nbsp;
															<span className="nav-link-text">
															{ i.name }
															</span>
															{ i.path === chatUrl
																&& this.props.chat > 0
																&& <span className="badge">{ this.props.chat }</span> }
														</Link>
													</li>
												)
											}
											else
												return null;
										}) }
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className={ `container-router ${ leftOpen }` }>
						{ this.props.children }
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user,
		chat: state.chat
	}
})(Menu);