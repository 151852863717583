import React from 'react';
import { Tabs } from 'components';
import { connect } from 'react-redux';
import Data from './data';
import PaymentData from './payment-data';
import Messages from './messages';
import Budgets from './budgets';
import { on } from 'jetemit';
import { Constants } from 'utils';

interface ViewEvaluationProps {
	evaluation: any,
	user: any,
	updateEvaluation: (item: any) => void,
	setListen: (status: boolean) => void
}

class ViewEvaluation extends React.Component<ViewEvaluationProps> {

	state = {
		itemSelected: 1,
		badge: null,
		evaluation: this.props.evaluation,
		subscriberFinish: () => null,
		subscriber: () => null,
	}

	componentDidMount() {
		this.setState({
			subscriber: on(Constants.EVALUATIONS.SEND_MESSAGE,(data: any) => {
				if (data.user_id != this.props.user.id && this.state.itemSelected != 2) {
					this.setState({
						badge: data.badge
					});
				}
			}),
			subscriberFinish: on(Constants.EVALUATIONS.FINISH,(data: any) => {
				if (data.id == this.state.evaluation.id) {
					this.props.updateEvaluation(data);
				}
			})
		});		
	}

	componentWillUnmount() {
		this.state.subscriber();
		this.state.subscriberFinish();
		this.props.setListen(true);
	}

	render() {
		const { itemSelected } = this.state;
		const badge: number = this.state.badge || this.state.evaluation?.badge || this.state.evaluation?.messages?.filter((i: any) => i.view == 0 && i.user_id != this.props.user.id).length;

		return (
			<div id="client-evaluations-view">

				<Tabs
			    	onChange={ async (i: number) => {
			    		await this.setState({
							itemSelected: i
			    		});
			    		this.props.setListen(i != 2);
			    	} }
			    	selected={ itemSelected }
			    	items={ [
						{ value: 1, label: 'Datos' },
						{ value: 2, label: 'Mensajes', badge },
						{ value: 3, label: 'Datos de pago' },
						{ value: 4, label: 'Presupuesto' }
				    ] } />

				{	/* Datos */
					itemSelected == 1 && (
						<Data evaluation={ this.props.evaluation } />
					)
				}

				{	/* Mensajes */
					itemSelected == 2 && (
						<Messages
							updateEvaluation={ async (evaluation: any) => {
								await this.setState({
									evaluation,
									badge: null
								});
								this.props.updateEvaluation(evaluation);
							} }
							evaluation={ this.props.evaluation } />
					)
				}

				{	/* Datos de pago */
					itemSelected == 3 && (
						<PaymentData evaluation={ this.props.evaluation } />
					)
				}

				{	/* Presupuesto */
					itemSelected == 4 && (
						<Budgets
							evaluation={ this.props.evaluation } />
					)
				}
			</div>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user
	}
})(ViewEvaluation);