import React from 'react';
import { Table, Owl, Viewer, Textarea, Input } from 'components';
import { ENV } from 'utils';

interface PatientProps {
	evaluation: any
}

class Data extends React.Component<PatientProps> {

	state: any = {
		visible: false,
		image: '',
		evaluation: this.props.evaluation,
		startPositionPhotos: 0,
		startPositionReference: 0
	}

	updateCarouselPosition = (object: any, type: string) => {
		if (object.item.index != this.state[`startPosition${ type }`])
			this.setState({ 
				[`startPosition${ type }`]: object.item.index
			});
	}
		
	render() {	
		const { evaluation } = this.state;
		const { procedures } = evaluation;

		return (
			<React.Fragment>

				<Viewer
			      visible={ this.state.visible }
			      onClose={ () => this.setState({ visible: false }) }
			      images={ [
					{ src: this.state.image, alt: '' },
			      ] } />

				<div className="row">
					<div className="col-md-12">				
						<div className="row">
							{ this.state.evaluation.weight && (
								<div className="col-md-6">
									<Input
										color="gray"
										label={ `Peso (${ this.state.evaluation.weight_unit.name })` }
										disabled={ true }
										value={ this.state.evaluation.weight } />
								</div>
							) }
							
							{ this.state.evaluation.height && (
								<div className="col-md-6">
									<Input
										color="gray"
										label={ `Altura (${ this.state.evaluation.height_unit.name })` }
										disabled={ true }
										value={ this.state.evaluation.height } />
								</div>
							) }
							
							{ this.state.evaluation.bust_size && (
								<div className="col-md-4">
									<Input
										color="gray"
										label="Tamaño de busto (cm)"
										disabled={ true }
										value={ this.state.evaluation.bust_size } />
								</div>
							) }
							
							{ this.state.evaluation.hip_measurement && (
								<div className="col-md-4">
									<Input
										color="gray"
										label="Medida de cadera (cm)"
										disabled={ true }
										value={ this.state.evaluation.hip_measurement } />
								</div>
							) }
							
							{ this.state.evaluation.waist_measurement && (
								<div className="col-md-4">
									<Input
										color="gray"
										label="Medida de cintura (cm)"
										disabled={ true }
										value={ this.state.evaluation.waist_measurement } />
								</div>
							) }
						</div>

						{ this.state.evaluation.description && <Textarea
		                    label="Descripción"
		                    disabled={ true }
		                    value={ this.state.evaluation.description }
		                /> }

						<Table data={ procedures.length } title="Procedimientos">
							{
								procedures.map((i: any,index: number) => (
									<tr>
										<td>{ i.name }</td>
									</tr>
								))
							}
						</Table>
					</div>
			    </div>

			    <h2 className="h2-title-carousel">Fotos para la evaluación</h2>

				<Owl
					startPosition={ this.state.startPositionPhotos }
					onDragged={ (object: any) => this.updateCarouselPosition(object,'Photos') }
					items={ this.state.evaluation.photos.map((i: any) => {
						const image: string = ENV.BasePublic + i.file;

						return (
							<div onClick={ () => {
								this.setState({
									visible: true,
									image: image
								})
							} } className="item-photo" style={ {
								backgroundImage: `url(${ image })`
							} }></div>
						)							
					}) } />

				{ this.state.evaluation.references.length > 0 && (
					<React.Fragment>
						<h2 className="h2-title-carousel">Más Fotos (opcional)</h2>

						<Owl
							startPosition={ this.state.startPositionReference }
							onDragged={ (object: any) => this.updateCarouselPosition(object,'Reference') }
							items={ this.state.evaluation.references.map((i: any) => {
								const image: string = ENV.BasePublic + i.file;

								return (
									<div className="item">
										<div onClick={ () => {
											this.setState({
												visible: true,
												image: image
											})
										} } className="item-photo" style={ {
											backgroundImage: `url(${ image })`
										} }></div>
									</div>
								)							
							}) } />
					</React.Fragment>
				) }
			</React.Fragment>
		)
	}
}

export default Data;