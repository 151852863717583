import io from 'socket.io-client';
// import ENV from './env';
const socketUrl = process.env.REACT_APP_SOCKET || 'http://45.173.197.126:11035'

let Socket = io(socketUrl, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
      forceNew: false 
});

export default Socket;