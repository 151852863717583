import { Api } from 'utils';

class MedicalHistoryService {
	
	static get = async (params?: any) => Api.createResponse('medical-history/get',params);
	static create = async (params?: any) => Api.createResponse('medical-history/create',params);
	static edit = async (params?: any) => Api.createResponse('medical-history/edit',params);
	static delete = async (params?: any) => Api.createResponse('medical-history/delete',params);
}

export default MedicalHistoryService;