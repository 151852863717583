import React from 'react';
import { Input, Textarea, Select } from 'components';

interface ViewPatientProps {
	countries: any,
	item: any,
	view?: boolean,
	onClose: () => void
}

class ViewPatient extends React.Component<ViewPatientProps> {

	state = {
		form: {
			email: '',
			name: '',
			lastname: '',
			address: '',
			country_id: '',
			phone: ''
		},
		countries: this.props.countries.slice(1)
	}

	componentDidMount() {
		const item: any = this.props.item;

		if (item) {
			this.setState({
				form: {
					id: item.id,
					email: item.email,
					name: item.person.name,
					lastname: item.person.lastname,
					address: item.person.address,
					country_id: item.person.country_id,
					phone: item.person.phone,
				}
			});
		}
	}

	render() {
		return (
			<div className="modal-create-edit">
                <React.Fragment>
                    <Input
                        color="gray"
                        value={ this.state.form.name }
                        name="name" 
                        label="Nombre"
                        disabled={ this.props.view }
                        />
                    <Input
                        color="gray"
                        value={ this.state.form.lastname }
                        name="lastname" 
                        label="Apellido"
                        disabled={ this.props.view }
                        />
                    <Input
                        color="gray"
                        value={ this.state.form.email }
                        name="email" 
                        label="Correo electrónico"
                        disabled={ this.props.view }
                        />
                    <Input
                        type="number"
                        color="gray"
                        value={ this.state.form.phone }
                        name="phone" 
                        label="Teléfono"
                        disabled={ this.props.view }
                        />
                    <Select
                        color="gray"
                        name="country_id"
                        label="País"
                        disabled={ this.props.view }
                        value={ this.state.form.country_id }
                        options={ this.state.countries.map((i: any) => {
                            return {
                                value: i.id,
                                label: i.name
                            }
                        }) } />
                    <Textarea
                        label="Dirección"
                        name="address"
                        disabled={ this.props.view }
                        value={ this.state.form.address }
                    />
                </React.Fragment>
			</div>
		)
	}
}

export default ViewPatient;