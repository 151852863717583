import React from 'react';
import { Icon, Button } from 'components';
import { Globals } from 'utils';

const permitidos = ['jpg','png','gif','jpeg','xls','xlsx','doc','docx','ppt','pptx','pdf','txt'];
const _images = ['jpg','png','gif','jpeg'];

class AddFiles extends React.Component<any> {

	private file: any;

	dataURItoBlob = (dataURI: any) => {
	    var byteString;
	    if (dataURI.split(',')[0].indexOf('base64') >= 0)
	        byteString = atob(dataURI.split(',')[1]);
	    else
	        byteString = unescape(dataURI.split(',')[1]);
	    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	    var ia = new Uint8Array(byteString.length);
	    for (var i = 0; i < byteString.length; i++) {
	        ia[i] = byteString.charCodeAt(i);
	    }
	    return new Blob([ia], {type:mimeString});
	}

	change = (e: any) => {
		var files = [...e.target.files] || [...e.dataTransfer.files];
		if (!files[0]) {
			return;
		}
		this.file.value = '';
		var format = files[0].name.split('.');
		if (permitidos.indexOf(format[format.length - 1].toLowerCase()) == -1) {
			Globals.showError('El formato del archivo no es válido');
			return false;
		}
		
		let tipo = format;
    	switch(tipo) {
			case 'jpg':
				tipo = 'image/jpeg';
				break;

			case 'png':
				tipo = 'image/png';
				break;

			case 'gif':
				tipo = 'image/gif';
				break;

			case 'jpeg':
				tipo = 'image/jpeg';
				break;

			case 'txt':
				tipo = 'text/plain';
				break;

			case 'pdf':
				tipo = 'application/pdf';
				break;

			case 'doc':
				tipo = 'application/msword';
				break;

			case 'docx':
				tipo = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
				break;

			case 'xls':
				tipo = 'application/vnd.ms-excel';
				break;
			
			case 'xlsx':
				tipo = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
				break;

			case 'ppt':
				tipo = 'application/vnd.ms-powerpoint';
				break;

			case 'pptx':
				tipo = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
				break;

			default: 
				tipo = 'image/jpeg';
				break;
		}

		if (_images.indexOf(format) != -1) {
			var reader = new FileReader();
			reader.readAsDataURL(files[0]);
	    	reader.onload = file => {
	            var tempImg: any = new Image();
	            tempImg.src = reader.result;
				
				let self: any = this;

	        	tempImg.onload = function() {
				    var MAX_WIDTH = 1000;
				    var MAX_HEIGHT = 1000;
				    var tempW = tempImg.width;
				    var tempH = tempImg.height;

				    if (tempW > tempH) {
				        if (tempW > MAX_WIDTH) {
					        tempH *= MAX_WIDTH / tempW;
					        tempW = MAX_WIDTH;
				        }
				    } else {
				        if (tempH > MAX_HEIGHT) {
					        tempW *= MAX_HEIGHT / tempH;
					        tempH = MAX_HEIGHT;
				        }
				    }
				    var resizedCanvas = document.createElement('canvas');
				    resizedCanvas.width = tempW;
				    resizedCanvas.height = tempH;
				    var ctx: any = resizedCanvas.getContext("2d");
				    ctx.drawImage(this, 0, 0, tempW, tempH);
				    var dataURL = resizedCanvas.toDataURL(tipo);
				    self.props.onChange({
				    	target: {
				    		name: files[0].name,
				    		value: self.dataURItoBlob(dataURL)
				    	}			    	
				    });
				}
	        }
		}
		else {
			this.props.onChange({
		    	target: {
		    		name: files[0].name,
		    		value: files[0]
		    	}			    	
		    });
		}
	}
	
	render() {
		return (
			<div className="container-add-files">
				{
					this.props.files.map((i: any,index: number) => {
						let format: any = i.name.split('.');
						format = format[format.length - 1];
						return (
							<div className="item-add-file" key={ index }>
								<p className="format">{ format }</p>
								<p onClick={ () => {
									if (i.url) {
										if (_images.indexOf(format) == -1) {
											Globals.downloadFile(i.url,i.name);
										}
										else {
											this.props.onOpen(i.url);
										}										
									}
								} }>{ i.name }</p>
								{ !this.props.disabled && (
									<Button type="button" onClick={ () => this.props.onDelete(index,i.id) }>
										<Icon name="trash" />
									</Button>
								) }
							</div>
						)						
					})
				}
	        	{
	        		!this.props.disabled && (
	        			<React.Fragment>
		        			<div className="item-add-file" onClick={ () => this.file.click() }>
				        		<input ref={ ref => this.file = ref } type="file" name={ this.props.name } onChange={ this.change } />
				        		<p>Adjuntar</p>
				        		<Icon name="paperclip" />
				        	</div>
		        			<p className="formats">Formatos permitidos: { permitidos.join(', ') }</p>
		        		</React.Fragment>
	        		)
	        	}
	        </div>
		)
	}
}

export default AddFiles;