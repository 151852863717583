import React from 'react';
import { Icon, Button, Table, Textarea } from 'components';
import { Globals, Constants, Socket, Format } from 'utils';
import { AppointmentService } from 'services';

interface AppointmentDataProps {
	patient: any,
	appointment: any,
	updateAppointment: (appointment: any) => void,
	isTimedOut: (item: any) => boolean,
	getStatus: (item: any) => string
}

class AppointmentData extends React.Component<AppointmentDataProps> {

	pending = () => {
		Globals.confirm('¿Desea confirmar la cita?',async () => {
			const res: any = await AppointmentService.changeStatus({
				id: this.props.appointment.id,
				status: Constants.APPOINTMENTS.CONFIRMED
			});
			this.props.updateAppointment(res.appointment);
			Globals.showSuccess("Se ha confirmado la cita correctamente");
			Socket.emit(Constants.APPOINTMENTS_ACTIONS.CONFIRM,{
				id: this.props.appointment.id
			});
		});
	}

	finish = () => {
		Globals.confirm('¿Desea finalizar la consulta médica?',async () => {
			const res: any = await AppointmentService.changeStatus({
				id: this.props.appointment.id,
				status: Constants.APPOINTMENTS.DONE
			});
			this.props.updateAppointment(res.appointment);
			Globals.showSuccess("Se ha cambiado el estatus de la consulta médica");
			Socket.emit(Constants.APPOINTMENTS_ACTIONS.FINISH,{
				id: this.props.appointment.id
			});
		});
	}
	
	render() {
		const patient: any = this.props.patient;
		const appointment: any = this.props.appointment;
		const { PENDING, CONFIRMED } = Constants.APPOINTMENTS;

		return (
			<div className="row">
				{ patient && (
					<div className="col-md-6">
						<Table data={ 1 } title="Datos del paciente">
							<tr>
								<th>Nombre</th>
								<td>{ patient.person.name + ' ' + patient.person.lastname }</td>
							</tr>
							<tr>
								<th>Correo Electrónico</th>
								<td>{ patient.email }</td>
							</tr>
							<tr>
								<th>Teléfono</th>
								<td>{ patient.person.phone }</td>
							</tr>
							<tr>
								<th>País</th>
								<td>{ patient.person.country.name }</td>
							</tr>
							<tr>
								<th>Dirección</th>
								<td>{ patient.person.address }</td>
							</tr>
							<tr>
								<th>Fecha de Nacimiento</th>
								<td>{ patient.data.birthdate }</td>
							</tr>
							{patient.data.birthdate && (<tr>
								<th>Edad</th>
								<td>{ Format.age(patient.data.birthdate) > 0 ? Format.age(patient.data.birthdate) : '' }</td>
							</tr>)}
							<tr>
								<th>Genero</th>
								<td>{ patient.data.gender === 'M' ? 'Masculino' : 'Femenino' }</td>
							</tr>
							<tr>
								<th>Procedimientos Quirúrgicos Anteriores</th>
								<td>{ patient.data.previous_procedures }</td>
							</tr>
							<tr>
								<th>¿Padece de alguna enfermedad?</th>
								<td>{ patient.data.diseases }</td>
							</tr>
							<tr>
								<th>¿Toma algún medicamento?</th>
								<td>{ patient.data.medicines }</td>
							</tr>
							<tr>
								<th>¿Es alérgico a algún medicamento?</th>
								<td>{ patient.data.allergies }</td>
							</tr>
					    </Table>
					</div>
				) }
				{ appointment && (
					<div className="col-md-6">
					    { [PENDING,CONFIRMED].indexOf(appointment.status) != -1 && !this.props.isTimedOut(appointment) && <div className="row row-buttons">
							<div className="col-md-12">
								{ appointment.status == PENDING && <Button  className="btn-finish" onClick={ this.pending }>
									<Icon name="check" />
									Confirmar
								</Button> }
								{ appointment.status == CONFIRMED && <Button  className="btn-finish" onClick={ this.finish }>
									<Icon name="check" />
									Finalizar											
								</Button> }
							</div>
						</div> }
						<Table data={ 1 } title="Datos de la cita">
							<tr>
								<th>Estatus</th>
								<td>{ this.props.getStatus(appointment) }</td>
							</tr>
							<tr>
								<td colSpan={ 2 }>
									<Textarea
					                    disabled={ true }
					                    value={ appointment.description } />
								</td>
							</tr>
					    </Table>
					</div>
				) }
			</div>
		)
	}
}

export default AppointmentData;