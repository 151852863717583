import React from 'react';
import { Table, Owl, Viewer, Textarea, Input } from 'components';
import { ENV, Format } from 'utils';

interface PatientProps {
	evaluation: any
}


class PatientData extends React.Component<PatientProps> {

	state: any = {
		visible: false,
		image: '',
		evaluation: this.props.evaluation,
		startPositionPhotos: 0,
		startPositionReference: 0,
		imc: 0
	}
	componentDidMount() {
		if (this.props.evaluation ) {
			if(this.state.evaluation.weight_unit.name === 'lb' && this.state.evaluation.height_unit.name === 'ft'){
				const peso = this.props.evaluation.weight / 2.2046;
				const altura = this.props.evaluation.height / 3.2808;
				const calculateImc = peso/ (altura * altura);
				this.setState({
					imc: calculateImc 
					});

			}
			if(this.state.evaluation.weight_unit.name === 'Kg' && this.state.evaluation.height_unit.name === 'Mts'){
				const calculateImc = this.props.evaluation.weight / (this.props.evaluation.height * this.props.evaluation.height);
				this.setState({
					imc: calculateImc 
					});
			}
		}
	}


	updateCarouselPosition = (object: any, type: string) => {
		if (object.item.index != this.state[`startPosition${ type }`])
			this.setState({ 
				[`startPosition${ type }`]: object.item.index
			});
	}

	
		
	render() {	
		const { evaluation } = this.state;
		const { user: patient, procedures } = evaluation;

		return (
			<React.Fragment>

				<Viewer
			      visible={ this.state.visible }
			      onClose={ () => this.setState({ visible: false }) }
			      images={ [
					{ src: this.state.image, alt: '' },
			      ] } />

				<div className="row">
					<div className="col-md-6">
						<Table data={ 1 } title="Datos del paciente">
							<tr>
								<th>Nombre</th>
								<td>{ patient.person.name + ' ' + patient.person.lastname }</td>
							</tr>
							<tr>
								<th>Correo Electrónico</th>
								<td>{ patient.email }</td>
							</tr>
							<tr>
								<th>Teléfono</th>
								<td>{ patient.person.phone }</td>
							</tr>
							<tr>
								<th>País</th>
								<td>{ patient.person.country.name }</td>
							</tr>
							{patient.data && (
								<React.Fragment>
									{patient.data.gender && (<tr>
										<th>Genero</th>
										<td>{ patient.data.gender }</td>
									</tr>)}
									{patient.data.birthdate && (<tr>
										<th>Fecha de nacimiento</th>
										<td>{ patient.data.birthdate }</td>
									</tr>)}
									{patient.data.birthdate && (<tr>
										<th>Edad</th>
										<td>{ Format.age(patient.data.birthdate) > 0 ? Format.age(patient.data.birthdate) : '' }</td>
									</tr>)}
									{patient.data.previous_procedures && (<tr>
										<th>Procedimientos anteriores</th>
										<td>{ patient.data.previous_procedures }</td>
									</tr>)}
									{patient.data.medicines && (<tr>
										<th>Medicamento que toma</th>
										<td>{ patient.data.medicines }</td>
									</tr>)}
									{patient.data.diseases && (<tr>
										<th>Enfermendades</th>
										<td>{ patient.data.diseases }</td>
									</tr>)}
									{patient.data.allergies && (<tr>
										<th>Alergias a medicamentos</th>
										<td>{ patient.data.allergies }</td>
									</tr>)}
								</React.Fragment>
							)}
					    </Table>
					</div>
					<div className="col-md-6">
						<div className="row">
							{ this.state.evaluation.weight && (
								<div className="col-md-6">
									<Input
										color="gray"
										label={ `Peso (${ this.state.evaluation.weight_unit.name })` }
										disabled={ true }
										value={ this.state.evaluation.weight } />
								</div>
							) }
							
							{ this.state.evaluation.height && (
								<div className="col-md-6">
									<Input
										color="gray"
										label={ `Altura (${ this.state.evaluation.height_unit.name })` }
										disabled={ true }
										value={ this.state.evaluation.height } />
								</div>
							) }

							{ this.state.imc > 0 && (
								<div className="col-md-12">
									<Input
										color="gray"
										label={ `I.M.C (índice de masa corporal)` }
										disabled={ true }
										value={ this.state.imc.toFixed(2) } />
								</div>
							) }
							
							{ this.state.evaluation.bust_size && (
								<div className="col-md-4">
									<Input
										color="gray"
										label="Tamaño de busto (cm)"
										disabled={ true }
										value={ this.state.evaluation.bust_size } />
								</div>
							) }
							
							{ this.state.evaluation.hip_measurement && (
								<div className="col-md-4">
									<Input
										color="gray"
										label="Medida de cadera (cm)"
										disabled={ true }
										value={ this.state.evaluation.hip_measurement } />
								</div>
							) }
							
							{ this.state.evaluation.waist_measurement && (
								<div className="col-md-4">
									<Input
										color="gray"
										label="Medida de cintura (cm)"
										disabled={ true }
										value={ this.state.evaluation.waist_measurement } />
								</div>
							) }
						</div>

						{ this.state.evaluation.description && <Textarea
		                    label="Descripción"
		                    disabled={ true }
		                    value={ this.state.evaluation.description }
		                /> }

						<Table data={ procedures.length } title="Procedimientos">
							{
								procedures.map((i: any,index: number) => (
									<tr>
										<td>{ i.name }</td>
									</tr>
								))
							}
						</Table>
					</div>
			    </div>

			    <h2 className="h2-title-carousel">Fotos para la evaluación</h2>

				<Owl
					startPosition={ this.state.startPositionPhotos }
					onDragged={ (object: any) => this.updateCarouselPosition(object,'Photos') }
					items={ this.state.evaluation.photos.map((i: any) => {
						const image: string = ENV.BasePublic + i.file;

						return (
							<div onClick={ () => {
								this.setState({
									visible: true,
									image: image
								})
							} } className="item-photo" style={ {
								backgroundImage: `url(${ image })`
							} }></div>
						)							
					}) } />

				{ this.state.evaluation.references.length > 0 && (
					<React.Fragment>
						<h2 className="h2-title-carousel">Más Fotos (opcional)</h2>

						<Owl
							startPosition={ this.state.startPositionReference }
							onDragged={ (object: any) => this.updateCarouselPosition(object,'Reference') }
							items={ this.state.evaluation.references.map((i: any) => {
								const image: string = ENV.BasePublic + i.file;

								return (
									<div className="item">
										<div onClick={ () => {
											this.setState({
												visible: true,
												image: image
											})
										} } className="item-photo" style={ {
											backgroundImage: `url(${ image })`
										} }></div>
									</div>
								)							
							}) } />
					</React.Fragment>
				) }
			</React.Fragment>
		)
	}
}

export default PatientData;