import React from "react";
import { Table, Button } from "components";
import { Globals } from "utils";
import { BudgetService } from "services";
import { saveAs } from "file-saver";
// import PrintJS from "print-js";

interface BudgetsProps {
  evaluation: any;
}

class Budgets extends React.Component<BudgetsProps> {
  generate = async (id: number) => {
    const blob: any = await BudgetService.print({
      id,
    });

    saveAs(blob.url, blob.name);

    // PrintJS({
    //   printable: blob.url,
    //   onLoadingEnd: async () => {
    //     await BudgetService.deletePrint({
    //       name: blob.name,
    //       withoutLoading: true,
    //     });
    //   },
    // });
  };

  render() {
    const budget =
      this.props.evaluation && this.props.evaluation.budgets.length > 0
        ? this.props.evaluation.budgets[0]
        : null;

    return (
      <React.Fragment>
        {budget != null ? (
          <div className="container-budget">
            <Table title="Presupuesto" data={1}>
              <tr>
                <th>Monto</th>
                <td>
                  {Globals.formatMiles(
                    budget.amount,
                    true,
                    budget.currency.code
                  )}
                </td>
              </tr>
              {/* <tr>
									<th>Procedimientos</th>
									<td>
										<ul>
											{
												budget.procedures.map((i: any,index: number) => (
													<li>{ i.name }</li>
												))
											}
										</ul>
									</td>
								</tr> */}
            </Table>

            <div className="row">
              <div className="col-md-12 text-center">
                <Button type="button" onClick={() => this.generate(budget.id)}>
                  Generar Formato
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <p className="no-payment">No se ha enviado un presupuesto</p>
        )}
      </React.Fragment>
    );
  }
}

export default Budgets;
