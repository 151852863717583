import React from 'react';
import { Button, Textarea, Select, DatePicker, Icon, Epayco, Paypal, Tabs, Modal, Input } from 'components';
import { Globals, Constants, ENV  } from 'utils';
import { AppointmentService, ScheduleService } from 'services';
import { connect } from 'react-redux';
import moment, * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import Holidays from 'colombia-holidays';


interface CreateAppointmentProps {
	user: any,
	prices: any,
	document_types: any,
	onClose: (user:any) => void
}

class CreateAppointment extends React.Component<CreateAppointmentProps, any> {

	state = {
		visible: false,
		selected: 0,
		hours: [],
		usd: 0,
		cop: 0,
		showForm: false,
		showPayment: false,
		form: {
			type: '',
			date: '',
			description: '',
			hour: '',
			gender: '',
			birthdate: '',
			previous_procedures: '',
			diseases: '',
			medicines: '',
			allergies: '',
			payment_code:''
		},
		pendingData: [],
		hasAllPatientData: false,
		showMessage: false,
		showGetCode: false,
	}

	SHIFTS = Object.freeze([
		{ label: 'Mañana', value: 1 },
		{ label: 'Tarde', value: 2 },
	]);

	checkIfHoliday(date: string,callback: () => void) {
		const days = Holidays.getColombiaHolidaysByYear(moment(date,'DD-MM-YYYY').format('YYYY')).map((i: any) => i.holiday);
		if (days.indexOf(moment(date,'DD-MM-YYYY').format('YYYY-MM-DD')) != -1) {
			Globals.showError("Lo sentimos, el día seleccionado es un día festivo");
			this.setState({
				form: {
					...this.state.form,
					date: ''
				}
			});
		}
		else {
			callback();
		}
	}

	async componentDidMount() {
		const { user, prices:pricesProps } = this.props;
		const patientData = this.checkHasAllPatientData(user.data);
		const prices: any = pricesProps.filter((i: any) => i.type === Constants.PRICES_TYPES.APPOINTMENTS);
		this.setState((state:any) => ({
			...state,
			usd: prices.find((i: any) => i.currency_id === Constants.CURRENCIES.USD).amount,
			cop: prices.find((i: any) => i.currency_id === Constants.CURRENCIES.COP).amount,
			hasAllPatientData: patientData.hasAllPatientData,
			form: {
				...state.form,
				gender: patientData.gender,
				birthdate: patientData.birthdate,
				previous_procedures: patientData.previous_procedures,
				diseases: patientData.diseases,
				medicines: patientData.medicines,
				allergies: patientData.allergies,
			},
			pendingData: patientData.pendingData
		}));

		const res: any = await ScheduleService.all();
		if (res.schedules == 0) {
			this.setState({
				showMessage: true
			});
		}
	}

	changeSection = (num: number) => {
		this.setState({
			selected: num
		});
	}

	change = (e: any, callback?: () => void) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		}, callback);
	}

	showGetCode = () => {
		this.setState({
			showGetCode: true
		});
	}

	processPayment = (payment: any, method: number) => {
		switch (method) {
			case Constants.PAYMENT_METHODS.PAYPAL:
				return {
					result: payment ?.paid,
					response_code: payment ?.paymentID
				}
				break;

			case Constants.PAYMENT_METHODS.EPAYCO:
				let response: string = '';
				if (payment.pay)
					response = payment?.pay?.data?.ref_payco;
				else
					response = payment?.description;
				return {
					result: payment?.pay?.data?.estado == Constants.EPAYCO_STATUS.ACEPTADA,
					response_code: response
				}
				break;
			case Constants.PAYMENT_METHODS.PAYMENT_CODE:
				return {
					result: payment?.code,
					response_code: payment?.code
				}
				break;
		}
	}

	onSuccess = async (payment: any, method: number) => {
		const { form, usd, cop } = this.state;
		const { user } = this.props;
		const hourMoment = moment(form.hour, 'H:mm:ss');
		const momentAtMidnight = hourMoment.clone().startOf('day');
		const minutesDiff = hourMoment.diff(momentAtMidnight, 'minutes');
		let pendingData:any = this.state.pendingData;
		let birthdate = form.birthdate;

		if(pendingData.includes('birthdate')){
			birthdate = moment(birthdate)
			.startOf('day')
			.format('YYYY-MM-DD')
		}

		let data: any = {
			...form,
			date: moment(form.date)
				.startOf('day')
				.add(minutesDiff, 'minutes')
				.format('YYYY-MM-DD HH:mm:ss'),
			birthdate: birthdate,
			user_id: user.id,
			type: form.type,
			description: form.description,
		}
		payment = this.processPayment(payment, method);
		if (payment.result) {
			setTimeout(async () => {
				const res:any = await AppointmentService.create({
					hasFile: true,
					user_id: user.id,
					...data,
					payment: JSON.stringify({
						user_id: user.id,
						method_id: method,
						response_code: payment.response_code,
						currency_id: method == Constants.PAYMENT_METHODS.PAYPAL ? Constants.CURRENCIES.USD : Constants.CURRENCIES.COP,
						amount: method == Constants.PAYMENT_METHODS.PAYPAL ? usd : cop,
						status: 1,
					})
				});
				this.props.onClose(res.user);
				Globals.showSuccess("Se ha solicitado correctamente la cita");
			},10);
		}
		else {
			this.onError(payment, method, false);
		}
	}

	onError = async (err: any, method: number, process_payment: boolean = true) => {
		if (process_payment)
			err = this.processPayment(err, method);
		await AppointmentService.paymentError({
			user_id: this.props.user.id,
			method_id: method,
			response_code: err.response_code,
			currency_id: method == Constants.PAYMENT_METHODS.PAYPAL ? Constants.CURRENCIES.USD : Constants.CURRENCIES.COP,
			amount: method == Constants.PAYMENT_METHODS.PAYPAL ? this.state.usd : this.state.cop,
			status: 0
		});
		Globals.showError("Lo sentimos, no se pudo procesar su pago");
	}

	showForm = (status: boolean) => {
		this.setState({
			showForm: status
		});
	}

	generateHours = ({ start, end }: any) => {
		const asMoment = (date: string): moment.Moment => moment(date, 'H:mm:ss');
		
		const rangeMoment = extendMoment(Moment);

		const range = rangeMoment.range(asMoment(start), asMoment(end));
		const test = Array.from(range.by('minutes', { step: 15, excludeEnd: true })).map(it =>
			({ value: it.format('LT'), label: it.format('LT') }),
		);
		
		return test
	};

	fetchHours = () => {

		const { date, type } = this.state.form
		if (!date || !type) {
			return
		}
		
		ScheduleService.getClient({ date: moment(date).format('YYYY-MM-DD HH:mm:ss'), type })
			.then(it => {
				if (!it) {
					Globals.showError('No hay citas disponibles para este turno')
					return;
				}			
			
				
				const newHours = it ? this.generateHours(it) : [];
				
				this.setState((oldState: any) => ({
					hours: newHours,
					form: {
						...oldState.form,
						hour: newHours[0]?.value,

					}
				}
				));
			})
	}

	validateForm = () => {
		const { type, date, description, hour, birthdate, gender, 
			previous_procedures, diseases, medicines, allergies } = this.state.form;

		// const checkForm:boolean = (type == null || type === '' ||
		// date == null || date === '' ||
		// description == null || description === '' ||
		// hour == null || hour === '' || birthdate == null || birthdate === '' || gender == null || gender === ''
		// || previous_procedures == null || previous_procedures === '' || diseases == null || diseases === '' || 
		// medicines == null || medicines === '' || allergies == null || allergies === '')

		const checkForm:boolean = (type == null || type === '' ||
		date == null || date === '' ||
		description == null || description === '' ||
		hour == null || hour === '')
		
		return checkForm
	}

	checkHasAllPatientData = (patientData:any) => {
		let hasAll:boolean = false;
		let pendingData = [];
		if(!patientData) {
			pendingData = ['birthdate', 'gender', 'previous_procedures', 'diseases', 'medicines', 'allergies'];
			return {
				hasAllPatientData: hasAll,
				birthdate: '',
				gender: '',
				previous_procedures: '',
				diseases: '',
				medicines: '',
				allergies: '',
				pendingData: pendingData
			}
		} else {

			if(patientData.birthdate && patientData.gender && patientData.previous_procedures 
				&& patientData.diseases && patientData.medicines && patientData.allergies) {
					hasAll = true;
			}
			if(!patientData.birthdate) {
				pendingData.push('birthdate')
			}
			if(!patientData.gender) {
				pendingData.push('gender')
			}
			if(!patientData.previous_procedures) {
				pendingData.push('previous_procedures')
			}
			if(!patientData.diseases) {
				pendingData.push('diseases')
			}
			if(!patientData.medicines) {
				pendingData.push('medicines')
			}
			if(!patientData.allergies) {
				pendingData.push('allergies')
			}
			return {
				hasAllPatientData: hasAll,
				birthdate: patientData.birthdate,
				gender: patientData.gender,
				previous_procedures: patientData.previous_procedures,
				diseases: patientData.diseases,
				medicines: patientData.medicines,
				allergies: patientData.allergies,
				pendingData: pendingData
			}
		}
	}

	render() {
		let amount: string | number = this.state.usd;
		amount = Globals.formatMiles(amount,true, 'USD');
		const pendingData:any = this.state.pendingData

		return (
			<React.Fragment>
				<div id="client-appointments-view">
					
					{
						this.state.showMessage && (
							<p className="message-no-appointments">
								Lo sentimos, actualmente no estamos agendando consulta presencial
							</p>
						)
					}
					{this.state.showGetCode && (
					<Modal
						title={'Ingresar código de pago'}
						visible={this.state.showGetCode}
						onClose={() => {
							this.setState({
								showGetCode: false
							});
						}}
					>
						<Input
							type="text"
							color="gray"
							containerStyle={{ flex: 1 }}
							value={this.state.form.payment_code}
							maxLength="8"
							name="payment_code"
							placeholder="Código de pago"
							onChange={this.change}
						/>
						<Button
							block="true"
							type="button"
							onClick={() =>
								this.onSuccess(
									{
										code: this.state.form.payment_code
									},
									Constants.PAYMENT_METHODS.PAYMENT_CODE
								)
							}
						>
							Enviar
						</Button>
					</Modal>
				)}

					<Tabs
						onChange={(i: number) => {
							// this.setState({
							// 	selected: i
							// });
						}}
						selected={this.state.selected}
						items={[
							{ value: 0, label: '' },
							{ value: 1, label: '' }
						]}
					/>
					{this.state.selected === 0 &&
						(<form onSubmit={ (e: any) => e.preventDefault() }>
							<div className="row">
								<div className="col-md-4">
									<DatePicker
										selected={Date.now()}
										label="Fecha"
										minDate={ moment().add(1,'day').toDate() }
										onChange={(text: string) => {
											this.setState({
												form: {
													...this.state.form,
													date: text
												}
											}, () => this.checkIfHoliday(text,() => {
												this.fetchHours();
											}));
										}}
										value={this.state.form.date}
									/>
								</div>
								<div className="col-md-4">
									<Select
										color="gray"
										name="type"
										label="Turno"
										defaultLabel={'Turno'}
										onChange={(e: any) => {
											this.change(e, () => {
												this.fetchHours()
											});
										}}
										value={this.state.form.type}
										options={this.SHIFTS} />
								</div>
								<div className="col-md-4">
									<Select
										disabled={!this.state.form.type || !this.state.form.date}
										color="gray"
										name="hour"
										label="Hora"
										onChange={this.change}
										value={this.state.form.hour}
										options={this.state.hours} />
								</div>
							</div>
							<Textarea
								label="Descripción"
								name="description"
								value={this.state.form.description}
								onChange={this.change} />
							<div className="row">
								{(pendingData.includes('birthdate')) && (
									<div className="col-md-4">
										<DatePicker
											showYearDropdown={true}
											maxDate={ moment().subtract(18, 'years').toDate() }
											label="Fecha de Nacimiento"
											onChange={ (text: string) => {
												this.setState({
													form: {
														...this.state.form,
														birthdate: text
													}										
												});
											} }
											value={ this.state.form.birthdate }
										/>
									</div>
								)}
								{(pendingData.includes('gender')) && (
									<div className="col-md-4">
										<Select
											color="gray"
											name="gender"
											placeholder="Seleccione"
											disabledFirst={true}
											label="Genero"
											onChange={ this.change }
											value={ this.state.form.gender }
											options={ Constants.GENDERS } 
										/>
									</div>
								)}
								{(pendingData.includes('previous_procedures')) && (
									<div className="col-md-4">
										<Textarea
											rows="2"
											label="Procedimientos Quirúrgicos Anteriores"
											name="previous_procedures"
											value={ this.state.form.previous_procedures }
											onChange={ this.change }
										/>
									</div>
								)}
								{(pendingData.includes('diseases')) && (
									<div className="col-md-4">
										<Textarea
											rows="2"
											label="¿Padece de alguna enfermedad?"
											name="diseases"
											value={ this.state.form.diseases }
											onChange={ this.change }
										/>
									</div>
								)}
								{(pendingData.includes('medicines')) && (
									<div className="col-md-4">
										<Textarea
											rows="3"
											label="¿Toma algún medicamento?"
											name="medicines"
											value={ this.state.form.medicines }
											onChange={ this.change }
										/>
									</div>
								)}
								{(pendingData.includes('allergies')) && (
									<div className="col-md-4">
										<Textarea
											rows="2"
											label="¿Es alérgico a algún medicamento?"
											name="allergies"
											value={ this.state.form.allergies }
											onChange={ this.change }
										/>
									</div>
								)}
							</div>
							<p className="text-center">Nota: Las citas solo se agendan los días lunes</p>
							<div className="container-buttons">
								<div className="text-center">
									<Button type="button" disabled={this.validateForm()} onClick={ async () => {
										await AppointmentService.check({
											date: moment(this.state.form.date).format('YYYY-MM-DD HH:mm:ss'),
											type: this.state.form.type
										});
										this.checkIfHoliday(this.state.form.date,() => {
											this.changeSection(1);
										});															
									} }>
										Siguiente
									</Button>
								</div>
							</div>
						</form>)

					}
					{ this.state.selected == 1 &&
						(<div className="container-buttons">
							{!this.state.showForm ? (
								<h3>Para finalizar, debe realizar el pago correspondiente</h3>
							) : (
									<h3>Ingrese los datos de su tarjeta de crédito para pagar con ePayco</h3>
								)}
							<Button onClick={() => this.state.showForm ? this.showForm(false) : this.changeSection(0)}>
								<Icon name="arrow-left" />
								Volver
							</Button>
							<div className="row">
								<div className="col-md-12">
									<p className="amount"><span className="bold">Monto a Pagar:</span> { amount }</p>
								</div>
							</div>
							<div className="row row-pay">
										<div className="col-md-12 text-center">
											<Paypal
												total={this.state.usd}
												onSuccess={this.onSuccess}
												onError={this.onError}
												showPayment={this.state.showPayment}
												updatePayment={(status: boolean) => {
													this.setState({
														showPayment: status
													});
												}}
											/>
										</div>														
							</div>
						</div>)


					}
					{/* <p className="text-center">Nota: Las citas solo se agendan los días lunes</p> */}
				</div>
			</React.Fragment>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user
	}
})(CreateAppointment);