import { Api } from 'utils';

class PaymentCodesService {
	
	static get = async (params?: any) => Api.createResponse('paymentcode',params);
	static create = async (params?: any) => Api.createResponse('paymentcode/create',params);
	static edit = async (params?: any) => Api.createResponse('paymentcode/edit',params);
	static delete = async (params?: any) => Api.createResponse('paymentcode/delete',params);
}

export default PaymentCodesService;