import React from 'react';
import { Input, Button, Textarea, Select, DatePicker } from 'components';
import { LoginService } from 'services';
import { Globals } from 'utils';
import { connect } from 'react-redux';
import moment from 'moment';

interface CompleteFormProps {
	onClose: (res?: any) => void,
	countries: Array<any>,
	dispatch: any,
	social: string,
	data_social: any
}

class CompleteForm extends React.Component<CompleteFormProps> {

	state = {
		countries: this.props.countries,
		form: {
			phone: '',
			country_id: '',
			// gender: '',
			// birthdate: '',
			// address: '',
			// previous_procedures: '',
			// diseases: '',
			// medicines: '',
			// allergies: ''
		}
	}

	disabled = () => {
		const fields:any = Object.values(this.state.form).map(field => !!field);
		return fields.every((field:any) => field === true)
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		const _data: any = this.props.data_social;
		let data: any = {};
		if (this.props.social == 'google') {
			data = {
				name: _data.profileObj.givenName,
				lastname: _data.profileObj.familyName || '.',
				email: _data.profileObj.email,
				google: 1,
				google_id: _data.googleId
			}
		}
		else if (this.props.social == 'facebook') {
			data = {
				name: _data.first_name,
				lastname: _data.last_name || '.',
				email: _data.email,
				facebook: 1,
				facebook_id: _data.id
			}
		}
		const res: any = await LoginService.registerSocial({
			...data,
			country_id: this.state.form.country_id,
			phone: this.state.form.phone,
			// address: this.state.form.address,
		});
		this.props.onClose(res);
	}
	
	render() {
		return (
			<form onSubmit={ this.submit } className="complete-form">
				<p className="message">Para continuar, por favor ingrese los siguientes datos</p>
				<div className="row">
					<div className="col-md-6">
						<Input
		                    color="gray"
		                    type="number"
		                    value={ this.state.form.phone }
		                    name="phone" 
		                    placeholder="Teléfono"
		                    onChange={ this.change } />
					</div>
					<div className="col-md-6">
						<Select
							color="gray"
							name="country_id"
							placeholder="País"
							onChange={ this.change }
							value={ this.state.form.country_id }
							options={ this.state.countries.map((i: any) => {
								return {
									value: i.id,
									label: i.name
								}
							}) } />
					</div>
					{/* <div className="col-md-6">
						<DatePicker
							showYearDropdown={true}
							maxDate={ moment().toDate() }
							textDefault="Fecha de Nacimiento"
							onChange={ (text: string) => {
								this.setState({
									form: {
										...this.state.form,
										birthdate: text
									}										
								});
							} }
							value={ this.state.form.birthdate }
						/>
					</div>
					<div className="col-md-6">
						<Select
							color="gray"
							name="gender"
							placeholder="Genero"
							onChange={ this.change }
							value={ this.state.form.gender }
							options={ [{
								value: 'M',
								label: 'M'
							},{
								value: 'F',
								label: 'F'
							}] } />
					</div>
					<div className="col-md-12">
						<Textarea
							rows="2"
		                    placeholder="Dirección"
		                    name="address"
		                    value={ this.state.form.address }
		                    onChange={ this.change }
		                />
					</div>
					<div className="col-md-12">
						<Textarea
							rows="2"
							placeholder="Procedimientos Quirúrgicos Anteriores"
							name="previous_procedures"
							value={ this.state.form.previous_procedures }
							onChange={ this.change }
						/>	
					</div>
					<div className="col-md-12">
						<Textarea
							rows="2"
							placeholder="¿Padece de alguna enfermedad?"
							name="diseases"
							value={ this.state.form.diseases }
							onChange={ this.change }
						/>
					</div>
											
					<div className="col-md-12">
						<Textarea
							rows="2"
							placeholder="¿Toma algún medicamento?"
							name="medicines"
							value={ this.state.form.medicines }
							onChange={ this.change }
						/>
					</div>
					<div className="col-md-12">
						<Textarea
							rows="2"
							placeholder="¿Es alérgico a algún medicamento?"
							name="allergies"
							value={ this.state.form.allergies }
							onChange={ this.change }
						/>
					</div> */}
				</div>
				<div className="text-center">
					<Button  block="true" type="submit" disabled={ !this.disabled() }>
						Continuar
					</Button>
				</div>
			</form>
		)
	}
}

export default connect(null)(CompleteForm);