import React from "react";
import { Provider, connect } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import {
  HashRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import "moment/locale/es";
import { RouteAuth } from "components";
import { Socket, Constants, Globals } from "utils";
import { emit } from "jetemit";

// Polyfill
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// Core-js
import "core-js/features/array/flat-map";

// Screens
import Login from "screens/admin/login/login";
import Procedures from "screens/admin/procedures/procedures";
import Profile from "screens/admin/profile/profile";
import Patients from "screens/admin/patients/patients";
import Evaluations from "screens/admin/evaluations/evaluations";
import Schedule from "screens/admin/schedule/schedule";
import Appointments from "screens/admin/appointments/appointments";
import Chat from "screens/admin/chat/chat";
import Prices from "screens/admin/prices/prices";
import Moderators from "screens/admin/moderators/moderators";
import { Emails } from "screens/admin/emails";
import PaymentCodes from "screens/admin/paymentcodes/paymentcodes";

// Screens (Client)
import EvaluationsClient from "screens/client/evaluations/evaluations";
import AppointmentsClient from "screens/client/appointments/appointments";
import ChatClient from "screens/client/chat/chat";
import Mobile from "screens/mobile";

// Scripts
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import { on } from "jetemit";

// Services
import { LoginService, ChatService } from "services";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./scss/main.scss";

declare var window: any;

class _Container extends React.Component<any> {
  componentDidMount() {
    // Evaluations
    Socket.on(Constants.EVALUATIONS.SEND_MESSAGE, (data: any) =>
      emit(Constants.EVALUATIONS.SEND_MESSAGE, data)
    );
    Socket.on(Constants.EVALUATIONS.BUDGET, (data: any) =>
      emit(Constants.EVALUATIONS.BUDGET, data)
    );
    Socket.on(Constants.EVALUATIONS.FINISH, (data: any) =>
      emit(Constants.EVALUATIONS.FINISH, data)
    );

    // Patients
    Socket.on(Constants.PATIENTS.USER_DELETE, (data: any) =>
      emit(Constants.PATIENTS.USER_DELETE, data)
    );
    Socket.on(Constants.PATIENTS.USER_DISABLE, (data: any) =>
      emit(Constants.PATIENTS.USER_DISABLE, data)
    );

    // Chat
    Socket.on(Constants.CHAT.MESSAGE, (data: any) => {
      this.countChat();
      emit(Constants.CHAT.MESSAGE, data);
    });
    Socket.on(Constants.CHAT.RELOAD, (data: any) =>
      emit(Constants.CHAT.RELOAD, data)
    );

    // Appointments
    Socket.on(Constants.APPOINTMENTS_ACTIONS.CONFIRM, (data: any) =>
      emit(Constants.APPOINTMENTS_ACTIONS.CONFIRM, data)
    );
    Socket.on(Constants.APPOINTMENTS_ACTIONS.FINISH, (data: any) =>
      emit(Constants.APPOINTMENTS_ACTIONS.FINISH, data)
    );
    Socket.on(Constants.APPOINTMENTS_ACTIONS.CANCEL, (data: any) =>
      emit(Constants.APPOINTMENTS_ACTIONS.CANCEL, data)
    );

    // Procedures
    Socket.on(Constants.PROCEDURES.CHANGE, (data: any) =>
      emit(Constants.PROCEDURES.CHANGE, data)
    );

    $(window).on("resize", () => {
      const heihtHeader = 100; // px
      const _height: any = $(window).outerHeight();
      $(".menu").height(_height);
      const height: any = $(document).height();
      $(".menu").height(height - heihtHeader);
    });

    this.countChat();
    this.props.history.listen(this.countChat);
    on(Constants.CHAT.RELOAD_BADGE, this.countChat);

    on(Constants.PATIENTS.USER_DELETE, (data: any) => {
      if (this.props.user && this.props.user.id == data) {
        Globals.setLoading();
        setTimeout(() => {
          this.props.dispatch({
            type: "SET_USER",
            payload: null,
          });
          this.props.history.push("/login");
          Globals.quitLoading();
          Globals.showError("Lo sentimos, su usuario ha sido eliminado");
        }, 1500);
      }
    });

    on(Constants.PATIENTS.USER_DISABLE, (data: any) => {
      if (this.props.user && this.props.user.id == data) {
        Globals.setLoading();
        setTimeout(() => {
          this.props.dispatch({
            type: "SET_USER",
            payload: null,
          });
          this.props.history.push("/login");
          Globals.quitLoading();
          Globals.showError("Lo sentimos, su usuario ha sido desactivado");
        }, 1500);
      }
    });

    if (this.props.user) {
      this.checkUser();
    }
  }

  countChat = async () => {
    if (this.props.user) {
      const res: any = await ChatService.count({
        user_id: this.props.user.id,
        withoutLoading: true,
      });

      console.log("dispatch set chat", res.count);
      this.props.dispatch({
        type: "SET_CHAT",
        payload: res.count,
      });
    }
  };

  checkUser = async () => {
    const res: any = await LoginService.checkUser({
      id: this.props.user.id,
      withoutLoading: true,
    });
    if (!res.result) {
      Globals.setLoading();
      setTimeout(() => {
        this.props.dispatch({
          type: "SET_USER",
          payload: null,
        });
        this.props.history.push("/login");
        Globals.quitLoading();
        Globals.showError(`Lo sentimos, su usuario ha sido ${res.message}`);
      }, 1500);
    }
  };

  detectMobile = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  render() {

      return (
        <Switch>
          <Route exact path="/login" component={Login} />
          <RouteAuth exact public path="/profile" component={Profile} />
          <RouteAuth
            exact
            path="/"
            component={
              this.props.user && this.props.user.level == Constants.LEVELS.USER
                ? EvaluationsClient
                : Patients
            }
          />
          <RouteAuth exact path="/chat" component={Chat} />

          {/* Rutas de Admin */}
          <RouteAuth admin exact path="/procedures" component={Procedures} />
          <RouteAuth admin exact path="/evaluations" component={Evaluations} />
          <RouteAuth admin exact path="/schedule" component={Schedule} />
          <RouteAuth
            admin
            exact
            path="/appointments"
            component={Appointments}
          />
          <RouteAuth admin exact path="/prices" component={Prices} />
          <RouteAuth admin exact path="/moderators" component={Moderators} />
          <RouteAuth admin exact path="/emails" component={Emails} />
          <RouteAuth admin exact path="/paymentcodes" component={PaymentCodes} />

          {/* Rutas de User */}
          <RouteAuth
            exact
            path="/clients/appointments"
            component={AppointmentsClient}
          />
          <RouteAuth exact path="/chat-client" component={ChatClient} />
        </Switch>
      );
  }
}

const Container = connect((state: any) => {
  return {
    user: state.user,
  };
})(withRouter(_Container));

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Container />
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
