import React from 'react';
import { Table, Button, Input, Textarea, Icon } from 'components';
import { AppointmentService } from 'services';
import { Globals, Constants } from 'utils';
import moment from 'moment';
import PrintJS from 'print-js';

interface RecipeProps {
	appointment: any,
	modal: any,
	updateAppointment: (appointment: any) => void
}

class Recipes extends React.Component<RecipeProps> {

	state: any = {
		header: [
			"Medicamentos",
			"Fecha",
			"Acciones"
		],
		create: false,
		items: [],
		item_id: null
	}

	create = () => {
		this.setState({
			create: true,
			items: [
				{ name: '', indications: '' }
			]
		});
	}

	change = (e: any,index: number) => {
		const items: any = [...this.state.items];
		items[index] = {
			...items[index],
			[e.target.name]: e.target.value
		}
		this.setState({
			items
        });
	}

	add = () => {
		const items: any = [...this.state.items];
		items.push(
			{ name: '', indications: '' }
		)
		this.setState({
			items
		},() => {
			this.props.modal._modal.dialog.scrollTop = this.props.modal._modal.dialog.scrollHeight;
		});	
	}

	remove = (index: number) => {
		const items: any = [...this.state.items];
		items.splice(index,1);
		this.setState({
			items
		});
	}

	back = () => {
		this.setState({
			items: [],
			create: false,
			item_id: null
		});
	}

	submit = () => {
		if (this.state.items.length == 0) {
			Globals.showError("Debe ingresar al menos un médicamento");
			return false;
		}
		const cant: number = this.state.items.filter((i: any) => i.name == '' || i.name == null || i.indications == '' || i.indications == null).length;
		if (cant > 0) {
			Globals.showError("Debe ingresar todos los datos de la receta");
			return false;
		}
		Globals.confirm('¿Desea guardar la receta?',async () => {
			const res: any = await AppointmentService.createRecipe({
				appointment_id: this.props.appointment.id,
				details: this.state.items,
				id: this.state.item_id
			});
			let appointment: any = { ...this.props.appointment };
			appointment.recipes = res.recipes;
			this.props.updateAppointment(appointment);
			this.setState({
				create: false,
				items: [],
				item_id: null
			});
			Globals.showSuccess("Se ha guardado correctamente la receta");
		});
	}

	print = async (item: any) => {
		const res: any = await AppointmentService.print({
			id: item.id
		});
		PrintJS({
			printable: res.url,
			onLoadingEnd: async () => {
				await AppointmentService.deletePrint({
					name: res.name,
					withoutLoading: true
				});
			}
		});		
	}

	edit = (item: any) => {
		this.setState({
			create: true,
			item_id: item.id,
			items: item.details.map((i: any) => {
				return { name: i.name, indications: i.indications }
			})
		})
	}

	delete = (item: any,index: number) => {
		Globals.confirm('¿Desea eliminar la receta médica?',async () => {
			await AppointmentService.deleteRecipe({
				id: item.id
			});
			let appointment: any = { ...this.props.appointment };
			appointment.recipes = appointment.recipes.filter((i: any) => i.id != item.id);
			this.props.updateAppointment(appointment);
		});
	}

	send = (item: any) => {
		Globals.confirm(`¿Desea enviar la receta por correo a ${ this.props.appointment.user.email }?`,async () => {
			await AppointmentService.send({
				id: item.id,
				email: this.props.appointment.user.email
			});
			Globals.showSuccess("Se ha enviado el correo electrónico");
		});
	}

	render() {
		const recipes: any = this.props.appointment && this.props.appointment.recipes;

		return (
			<div className="container-recipes">

				{
					!this.state.create && (
						<React.Fragment>
							{ this.props.appointment && this.props.appointment.status == Constants.APPOINTMENTS.CONFIRMED && <div className="text-right btn-new">
								<Button type="button" onClick={ this.create } >
				                    Nueva
				                </Button>
							</div> }
							<Table data={ recipes && recipes.length } title="Recetas médicas" header={ this.state.header }>
								{
									recipes && recipes.map((i: any,index: number) => (
										<tr>
											<td>{ i.details.length }</td>
											<td>{ moment(i.created_at).format('DD/MM/YYYY HH:mm') }</td>
											<td>
												<Button title="Editar" small="true" onClick={ () => this.edit(i) }>
													<Icon name="edit" />									
												</Button>
												<Button  title="Imprimir" small="true" onClick={ () => this.print(i) }>
													<Icon name="print" />									
												</Button>
												<Button  title="Enviar por correo" small="true" onClick={ () => this.send(i) }>
													<Icon name="envelope" />									
												</Button>
												<Button  title="Eliminar" small="true" onClick={ () => this.delete(i,index) }>
													<Icon name="trash" />									
												</Button>
											</td>
										</tr>
									))									
								}
							</Table>
						</React.Fragment>
					)
				}

				{
					this.state.create && (
						<React.Fragment>
							<div className="text-left btn-new">
								<Button type="button" onClick={ this.back } >
									<Icon name="arrow-left" />
				                    Volver
				                </Button>
							</div>
							{
								this.state.items.map((i: any, index: number) => (
									<div className="row">
										<div className="col-md-5">
											<Input
												label="Nombre"
							                    color="gray"
							                    value={ this.state.items[index].name }
							                    name="name"
							                    onChange={ (event: any) => this.change(event,index) } />
										</div>
										<div className="col-md-5">
											<Textarea
												label="Indicaciones"
							                    name="indications"
							                    rows={ 2 }
							                    value={ this.state.items[index].indications }
							                    onChange={ (event: any) => this.change(event,index) }
							                />
										</div>
										<div className="col-md-2" style={ {
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center'
										} }>
											<Button  title="Eliminar" small="true" onClick={ () => this.remove(index) }>
												<Icon name="trash" />												
											</Button>
										</div>
									</div>
								))
							}
							<div className="row row-buttons-recipes">
								<div className="btn-new col-md-6 text-right">
									<Button type="button" onClick={ this.add } >
					                    Agregar
					                </Button>
								</div>
								<div className="btn-new col-md-6">
									<Button  type="button" onClick={ this.submit } >
					                    Guardar
					                </Button>
								</div>
							</div>
						</React.Fragment>
					)
				}
			</div>
		)
	}
}

export default Recipes;