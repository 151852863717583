import React from 'react';
import OwlCarousel from 'react-owl-carousel';

interface OwlProps {
	items: Object[],
	onDragged?: (object: any) => void,
	startPosition?: number,
	itemsAmount?: number,
	video?: boolean,
	dots?: boolean,
	autoplay?: boolean,
	mouseDrag?: boolean,
	touchDrag?: boolean,
	pullDrag?: boolean,
	nav?: boolean,
	navText?: any
}

const Owl = React.forwardRef((props: OwlProps, ref: any) => (
	
	<OwlCarousel
		ref={ ref }
	    className="owl-theme"
	    loop={ false }
	    autoplay={ props.autoplay }
	    items={ props.itemsAmount }
	    autoplayTimeout={ 2500 }
	    autoplayHoverPause={ true }
	    onDragged={ props.onDragged }
		startPosition={ props.startPosition }
		video={ props.video }
		dots={ props.dots }
		mouseDrag={ props.mouseDrag }
		touchDrag={ props.touchDrag }
		pullDrag={ props.pullDrag }
		nav={props.nav}
		navText={props.navText}
	>
		{
			props.items.map((i: any, index: number) => (
				<div className="item" key={index}>
					{ i }
				</div>
			))
		}
	</OwlCarousel>
))

export default Owl;