import React from 'react';
import {
	Button, Icon, Image, Input, Textarea, Owl, Viewer,
	PaperClip, ProgressBar, Paypal, Epayco, ImageUpload, Tabs, Select, DatePicker, Stripe, Modal
} from 'components';
import { Globals, Constants, ENV, Format } from 'utils';
import { EvaluationService } from 'services';
import { connect } from 'react-redux';
import { on } from 'jetemit';
import arrowPrev from '../../../assets/icons/arrow-left.svg'
import arrowNext from '../../../assets/icons/arrow-right.svg'
import arrowPrevDark from '../../../assets/icons/arrowleft-dark.svg'
import arrowNextDark from '../../../assets/icons/arrowright-dark.svg'
import arrowPrevDisabled from '../../../assets/icons/arrow-left-disabled.svg'
import arrowNextDisabled from '../../../assets/icons/arrow-right-disabled.svg'
import arrowleftSvg from '../../../assets/icons/arrowleft.svg'
import arrowrightSvg from '../../../assets/icons/arrowright.svg'
import eyeSvg from '../../../assets/icons/eye.svg'
import moment from 'moment'
import Swal from 'sweetalert2';

const _CANT_MAX = 3;
const { CREATE_EVALUATION_SELECT, CREATE_EVALUATION_OPERATIONS_SELECT } = Constants;
const {
	OPERATIONS,
	PROCEDURE_PHOTOS,
	PROCEDURE_PHOTOS_REFERENCE,
	PATIENT_INFO,
	PAYMENT,
	TREATMENT_INFO,
	VIEW_PHOTOS,
	VIEW_PHOTOS_REFERENCE
} = CREATE_EVALUATION_SELECT;
const {
	PROCEDURES,
	TREATMENTS
} = CREATE_EVALUATION_OPERATIONS_SELECT;

interface CreateEvaluationProps {
	ref: (ref: any) => void,
	setStep: (step: number) => void,
	procedures: any,
	user: any,
	prices: any,
	document_types: any,
	onClose: () => void,
	treatments: any,
	contact: any,
	typePhotos: any,
	dispatch: any,
	units: any,
	evaluation: any,
}

interface defaultObjLayout {
	[property: string]: any;
}

interface arrayObjLayout {
	[index: number]: defaultObjLayout
}

const initSelectedTreatment: defaultObjLayout = {};

class CreateEvaluation extends React.Component<CreateEvaluationProps> {

	emiter: any
	videoRef: any = null
	photosRef: any = null
	uploadRef: any = null
	state = {
		selected: 0,
		visible: false,
		image: null,
		form: {
			description: "",
			height: '',
			weight: '',
			gender: '',
			birthdate: '',
			bust_size: '',
			hip_measurement: '',
			waist_measurement: '',
			previous_procedures: '',
			diseases: '',
			medicines: '',
			allergies: '',
			height_unit_id: this.props.units.find((i: any) => i.type == 2).id,
			weight_unit_id: this.props.units.find((i: any) => i.type == 1).id,
			payment_code: '',
		},
		procedures: [],
		treatments: [],
		usd: 0,
		cop: 0,
		references: [],
		photos: [],
		showForm: false,
		showPayment: false,
		subscriber: () => null,
		innerSelected: 0,
		selectedTreatment: initSelectedTreatment,
		typePhotos: [],
		tabPhotoSelected: 0,
		showGetCode: false,
		not_save: false,
	}

	componentDidMount() {
		const prices: any = this.props.prices.filter((i: any) => i.type === Constants.PRICES_TYPES.EVALUATIONS);
		const treatments: any = this.props.treatments.map((i: any) => {
			i.selected = false;
			return i;
		})
		const procedures: any = this.props.procedures.map((i: any) => {
			i.selected = false;
			return i;
		});
		const typePhotos = this.props.typePhotos.map((photo: defaultObjLayout) => {
			photo.uploaded = false;
			return photo;
		})
		this.setState({
			usd: prices.find((i: any) => i.currency_id === Constants.CURRENCIES.USD).amount,
			cop: prices.find((i: any) => i.currency_id === Constants.CURRENCIES.COP).amount,
			procedures,
			subscriber: on(Constants.PROCEDURES.CHANGE, (procedures: any) => {
				const selected: any = [...this.state.procedures.filter((i: any) => i.selected === true).map((i: any) => i.id)];
				this.setState({
					procedures: procedures.map((i: any) => {
						i.selected = false;
						if (selected.indexOf(i.id) !== -1) {
							i.selected = true;
						}
						return i;
					})
				});
			}),
			treatments,
			typePhotos
		});
		this.emiter = on('CLOSE_PHOTOS', () => this.changeSection(PAYMENT))

		// if (this.props.evaluation !== null) {
		// 	if (this.props.evaluation.form.description == '') {
		// 		this.props.dispatch({
		// 			type: 'SET_EVALUATION',
		// 			payload: null
		// 		});
		// 	}
		// 	else {
		// 		Globals.confirm('Posees una consulta pendiente por cancelar. Haz clic en aceptar para culminar el proceso de pago', async () => {
		// 			console.log('this.props.evaluation: ', this.props.evaluation);
		// 			this.state = this.props.evaluation;
		// 			console.log('this.state: ', this.state)
		// 			this.changeSection(PAYMENT)
		// 			return
		// 		});
		// 	}
		// }

		this.checkEvaluationPendig();
	}

	componentWillUnmount() {
		this.state.subscriber();
		//this.saveInfo();
	}

	saveInfo() {
		this.props.dispatch({
			type: 'SET_EVALUATION',
			//payload: null
			payload: {
				...this.state
			}
		});
	}

	checkEvaluationPendig = async () => {
		const response: any = await EvaluationService.checkEvaluationPending({
			user_id: this.props.user.id,
		});
		if(response){
			if (response.evaluation) {	
				this.setState({
					procedures: response.evaluation.procedures.map((i: any) => {
						i.selected = true;
						return i;
					}),
					not_save: true,
					form: {
						...this.state.form,
						description: response.evaluation.description,
						height: response.evaluation.height,
						weight: response.evaluation.weight,
						gender: response.evaluation.user.data.gender,
						birthdate: response.evaluation.user.data.birthdate,
						bust_size: response.evaluation.bust_size,
						hip_measurement: response.evaluation.hip_measurement,
						waist_measurement: response.evaluation.waist_measurement,
						previous_procedures: response.evaluation.user.data.previous_procedures,
						diseases: response.evaluation.user.data.diseases,
						medicines: response.evaluation.user.data.medicines,
						allergies: response.evaluation.user.data.allergies,
						height_unit_id: response.evaluation.height_unit_id,
						weight_unit_id: response.evaluation.weight_unit_id,
					}
				});
				this.changeSection(PAYMENT)		
			}
		}
		else {
			console.log(response.error);
		}
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	changeSection = (num: number) => {

		const { procedures, treatments, photos, typePhotos: typePhotosState } = this.state;
		const { typePhotos: typePhotosProps } = this.props;

		let msg: string = '';
		let canContinue: boolean = true;
		let typePhotosFiltered: any = typePhotosState;
		let photosFiltered: any = photos;

		switch (num) {
			case PROCEDURE_PHOTOS:
				if (procedures.filter((i: any) => i.selected === true).length === 0) {
					msg = 'Debe seleccionar al menos un procedimiento para continuar!';
					canContinue = false;
				}
				const filteredProcedures = procedures.filter((i: any) => i.selected === true);

				typePhotosFiltered = Format.filterPhotosTypes(typePhotosProps, filteredProcedures);
				if (photos.length > 0) {
					photosFiltered = Format.filterPhotos(typePhotosFiltered, photos);
					typePhotosFiltered = Format.getUploadedPhotosTypes(typePhotosFiltered, typePhotosState)
				}
				break;
			case PATIENT_INFO:
				const cant = typePhotosFiltered.length;
				if (typePhotosFiltered.filter((i: any) => i.uploaded === false).length > 0) {
					msg = `Para la evaluación debe cargar un total de ${cant} fotografías`;
					Swal.fire({
						title: "",
						text: msg,
						type: "error",
						showCancelButton: false,
						customClass: {
							content: 'msg-evaluation-photos',
							popup: 'swal-custom-popup',
							header: 'swal-custom-color-icons',
							actions: 'swal-custom-actions',
							confirmButton: 'swal-custom-confirm-button',
							cancelButton: 'swal-custom-cancel-button'
						}
					});
					return;
				}
				break;
			case TREATMENT_INFO:
				if (treatments.filter((i: any) => i.selected === true).length === 0) {
					msg = 'Debe seleccionar al menos un tratamiento para continuar!';
					canContinue = false;
				}
				break;
			case PAYMENT:
				if(this.state.not_save === false) this.saveData();				
				break;
			default:
				break;
		}
		if (!canContinue) {
			Globals.showError(msg)
			return
		}
		this.props.setStep(num)
		this.setState({
			selected: num,
			typePhotos: typePhotosFiltered,
			photos: photosFiltered
		});
		//this.saveInfo();
	}

	changeInnerSection = (num: number) => {
		const { treatments, procedures } = this.state
		let treatmentsMapped: arrayObjLayout = [];
		let proceduresMapped: arrayObjLayout = [];
		switch (num) {
			case PROCEDURES:
				treatmentsMapped = treatments.map((treatment: defaultObjLayout) => {
					return {
						...treatment,
						selected: false
					}
				})
				proceduresMapped = procedures;
				break;
			case TREATMENTS:
				proceduresMapped = procedures.map((procedure: defaultObjLayout) => {
					return {
						...procedure,
						selected: false
					}
				})
				treatmentsMapped = treatments;
				break;
			default:
				break;
		}
		// this.props.setStep(num)
		this.setState({
			innerSelected: num,
			treatments: treatmentsMapped,
			procedures: proceduresMapped,
			selectedTreatment: initSelectedTreatment,
		});
	}

	handleRadioChange = (index: number) => {
		const { treatments } = this.state;
		const treatmentsMapped: arrayObjLayout = treatments.map((treatment: defaultObjLayout, key: number) => {
			return {
				...treatment,
				selected: key === index ? true : false
			}
		})
		const selectedTreatment: defaultObjLayout = treatmentsMapped[index];
		this.setState(state => ({
			...state,
			treatments: treatmentsMapped,
			selectedTreatment: selectedTreatment
		}), () => {
			this.changeSection(TREATMENT_INFO)
		});
	}

	handleCheckBoxChange = (index: number) => {
		const { procedures } = this.state;
		const proceduresMapped: arrayObjLayout = procedures.map((procedure: defaultObjLayout, key: number) => {
			return {
				...procedure,
				selected: key === index ? !procedure.selected ? true : false : procedure.selected
			}
		})
		this.setState(state => ({
			...state,
			procedures: proceduresMapped
		}));
	}

	rawProceduresPhotos = (Items: any) => {
		return Items.map((Item: any) => {
			return Item.photos.map((photo: any) => {
				return photo
			})
		}).flat();
	}

	processPayment = (payment: any, method: number) => {
		switch (method) {
			case Constants.PAYMENT_METHODS.PAYPAL:
				return {
					result: payment?.paid,
					response_code: payment?.paymentID
				}
				break;

			case Constants.PAYMENT_METHODS.EPAYCO:
				let response: string = '';
				if (payment.pay)
					response = payment?.pay?.data?.ref_payco;
				else
					response = payment?.description;
				return {
					result: payment?.pay?.data?.estado == Constants.EPAYCO_STATUS.ACEPTADA,
					response_code: response
				}
				break;

			case Constants.PAYMENT_METHODS.STRIPE:
				return {
					result: payment?.status,
					response_code: payment?.id
				}
				break;
			case Constants.PAYMENT_METHODS.PAYMENT_CODE:
				return {
					result: payment?.code,
					response_code: payment?.code
				}
				break;
		}
	}

	saveData = async () => {
		const response: any = await EvaluationService.create({
			hasFile: true,
			user_id: this.props.user.id,
			...this.state.form,
			photos: this.state.photos.map((i: any) => i.value),
			references: this.state.references.map((i: any) => i.value),
			procedures: this.state.procedures.filter((i: any) => i.selected === true).map((i: any) => i.id),
			onlysave: true,
		});

		if (response.data) {
			const newUser = { ...this.props.user }
			newUser.data = response.data
			this.props.dispatch({
				type: 'SET_USER',
				payload: newUser
			});
			console.log("Se ha solicitado correctamente la evaluación");
			this.props.dispatch({
				type: 'SET_EVALUATION',
				payload: null
			});
		}
		else {
			console.log(response.error);
		}
	}

	onSuccess = async (payment: any, method: number) => {
		payment = this.processPayment(payment, method);
		if (payment.result) {
			const response: any = await EvaluationService.create({
				hasFile: true,
				user_id: this.props.user.id,
				...this.state.form,
				payment: JSON.stringify({
					user_id: this.props.user.id,
					method_id: method,
					response_code: payment.response_code,
					currency_id: method == Constants.PAYMENT_METHODS.PAYPAL || method == Constants.PAYMENT_METHODS.STRIPE ? Constants.CURRENCIES.USD : Constants.CURRENCIES.COP,
					amount: method == Constants.PAYMENT_METHODS.PAYPAL || method == Constants.PAYMENT_METHODS.STRIPE ? this.state.usd : this.state.cop,
					status: 1,
				}),
				photos: this.state.photos.map((i: any) => i.value),
				references: this.state.references.map((i: any) => i.value),
				procedures: this.state.procedures.filter((i: any) => i.selected === true).map((i: any) => i.id),
				onlypayment: true,
			});

			if (response.data) {
				const newUser = { ...this.props.user }
				newUser.data = response.data
				this.props.dispatch({
					type: 'SET_USER',
					payload: newUser
				});
				this.props.onClose();
				Globals.showSuccess("Se ha solicitado correctamente la evaluación");
				this.props.dispatch({
					type: 'SET_EVALUATION',
					payload: null
				});
			}
			else {
				Globals.showError(response.error);
			}

		}
		else {
			this.onError(payment, method, false);
		}
	}

	onError = async (err: any, method: number, process_payment: boolean = true) => {
		if (process_payment)
			err = this.processPayment(err, method);
		await EvaluationService.paymentError({
			user_id: this.props.user.id,
			method_id: method,
			response_code: err.response_code,
			currency_id: method == Constants.PAYMENT_METHODS.PAYPAL || method == Constants.PAYMENT_METHODS.STRIPE ? Constants.CURRENCIES.USD : Constants.CURRENCIES.COP,
			amount: method == Constants.PAYMENT_METHODS.PAYPAL || method == Constants.PAYMENT_METHODS.STRIPE ? this.state.usd : this.state.cop,
			status: 0
		});
		Globals.showError("Lo sentimos, no se pudo procesar su pago");
	}

	showForm = (status: boolean) => {
		this.setState({
			showForm: status
		});
	}

	showGetCode = () => {
		this.setState({
			showGetCode: true
		});
	}

	validateForm = () => {
		const { description, height, weight } = this.state.form;
		return description == null || description == '' ||
			height == null || height == '' ||
			weight == null || weight == '';
		//    bust_size == null || bust_size == '' ||
		//    waist_measurement == null || waist_measurement == '' ||
		//    hip_measurement == null || hip_measurement == '';
	}

	sendWhatsapp = () => {
		const { selectedTreatment, form } = this.state;
		const { contact } = this.props;
		if (!form.description) {
			Globals.showError('Debe ingresar una descripcion del tratamiento que desea realizar')
			return
		}
		let text: string = "";
		text += 'Tratamiento: ' + selectedTreatment.name + '\n';
		text += '%20' + form.description;
		window.open(`https://web.whatsapp.com/send?phone=${contact.phone}&text=${text}&source=&data=`);
		this.props.onClose();
	}

	evalMediaType = (image: defaultObjLayout) => {
		const image_formats = ['jpg', 'jpeg', 'png'];
		const isImage = image && image_formats.indexOf(image.file.split('.').pop()) !== -1;
		let displayType: defaultObjLayout;
		const url = ENV.BasePublic + image.file;

		if (isImage) {
			displayType = <div onClick={() => {
				this.setState({
					visible: true,
					image: url
				})
			}} className="item-photo w-60" style={{
				backgroundImage: `url(${url})`
			}}></div>
		} else {
			displayType =
				<div className="item-video text-center">
					<video width="480" height="320" controls>
						<source src={`${url}`} type="video/mp4" />
					</video>
				</div>
		}

		return displayType;
	}

	handleImageUpload = (emitter: any, typePhotoSelected: defaultObjLayout, typePhotoIndex: number) => {

		let photos: any = [...this.state.photos];
		const { typePhotos } = this.state;

		const indexPhoto = photos.findIndex((photo: defaultObjLayout) => photo.typePhotoId === typePhotoSelected.id);
		let objPhoto: defaultObjLayout;
		objPhoto = {
			...emitter.target,
			name: typePhotoSelected.name,
			typePhotoId: typePhotoSelected.id
		}
		indexPhoto > -1 ? photos[indexPhoto] = objPhoto : photos.push(objPhoto);
		const typePhotosMapped: arrayObjLayout = typePhotos.map((typePhoto: defaultObjLayout, key: number) => {
			let uploaded: boolean = false;
			if (key === typePhotoIndex) {
				uploaded = true;
			} else {
				uploaded = typePhoto.uploaded;
			}
			return {
				...typePhoto,
				uploaded: uploaded
			}
		});
		this.setState({
			photos,
			typePhotos: typePhotosMapped
		}, () => {
			this.forceUpdate();
		});
	}

	evalOperationsName = (procedures: any) => {
		return procedures
			.filter((procedure: any) => procedure.selected)
			.reduce((prev: any, next: any, index: number) => {
				const nextItem = index === 0 ? prev + ' ' + next.name : next.selected ?
					prev + ', ' + next.name : ''
				return nextItem
			}, '')
	}

	render() {
		const { user } = this.props
		const _genderSelected = Constants.GENDERS.find((i: any) => i.value == this.state.form.gender);
		let amount: string | number = this.state.usd;
		amount = Globals.formatMiles(amount, true, 'USD');

		const afterOperations: any = this.state.innerSelected === PROCEDURES ?
			PROCEDURE_PHOTOS : TREATMENT_INFO;

		const sectionsProgressBar = afterOperations === TREATMENT_INFO ?
			['Operaciones'] : ['Operaciones', 'Fotos', 'Informacion', 'Verificar'];

		
		return (
			<div id="client-evaluations-view">

				<Viewer
					visible={this.state.visible}
					onClose={() => this.setState({ visible: false })}
					images={[
						{ src: this.state.image, alt: '' },
					]} />

				{/* <ProgressBar 
				  	variant="warning"
				  	selected={ this.state.selected }
				  	setSelected={ (_item: number) => this.changeSection(_item)  }
				  	items={ sectionsProgressBar } /> */}

				{this.state.showGetCode && (
					<Modal
						title={'Ingresar código de pago'}
						visible={this.state.showGetCode}
						onClose={() => {
							this.setState({
								showGetCode: false
							});
						}}
					>
						<Input
							type="text"
							color="gray"
							containerStyle={{ flex: 1 }}
							value={this.state.form.payment_code}
							maxLength="8"
							name="payment_code"
							placeholder="Código de pago"
							onChange={this.change}
						/>
						<Button
							block="true"
							type="button"
							onClick={() =>
								this.onSuccess(
									{
										code: this.state.form.payment_code
									},
									Constants.PAYMENT_METHODS.PAYMENT_CODE
								)
							}
						>
							Enviar
						</Button>
					</Modal>
				)}

				{
					this.state.selected === OPERATIONS && (
						<div className="container-buttons">
							<div className="bg-primary custom-progress py-1 text-white">
								<div className="custom-progress-item ml-4"></div>
								<div className="custom-progress-item text-center">Operaciones</div>
								<div className="custom-progress-item text-right mr-4 custom-progress-item--inactive">{this.state.innerSelected === PROCEDURES ? 'Fotos' : ''}</div>
							</div>
							<h3>Seleccione las operaciones a consultar</h3>
							<div className="px-4">
								{this.state.innerSelected === PROCEDURES && (
									<div className="row">
										{
											this.state.procedures.map((i: any, index: number) => {
												return (
													<div className="col-md-3" key={index}>
														<Image
															rounded
															width={60}
															height={60}
															onClick={() => this.handleCheckBoxChange(index)}
															classcontainer={i.selected ? 'border-golden' : ''}
															source={i.icon}
															label={i.name}
														/>
													</div>
												)
											})
										}
									</div>
								)}
								{this.state.innerSelected === TREATMENTS && (
									<div className="px-5 mx-5">
										<div className="mx-3">
											<div className="treatments-title">
												<span className="line"></span>
												<span className="text">
													Tratamientos
												</span>
												<span className="line"></span>
											</div>
											<div className="row mb-5 pb-4">
												{
													this.state.treatments.map((i: any, index: number) => {
														return (
															<div className="col-md-4" key={index}>
																<Image
																	height={80}
																	width={80}
																	rounded
																	onClick={() => this.handleRadioChange(index)}
																	classcontainer={i.selected ? 'border-golden' : ''}
																	source={i.icon}
																	label={i.name}
																/>
															</div>
														)
													})
												}
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="controls">
								<div className="prev" onClick={() => this.changeInnerSection(PROCEDURES)}>
									<img src={this.state.innerSelected !== PROCEDURES ? arrowPrev : arrowPrevDisabled} alt="" />
								</div>
								<div className="dots">
									<div className={`dot ${this.state.innerSelected === PROCEDURES ? 'dot--selected' : ''}`}></div>
									<div className={`dot ${this.state.innerSelected !== PROCEDURES ? 'dot--selected' : ''} `}></div>
								</div>
								<div className="next" onClick={() => this.changeInnerSection(TREATMENTS)}>
									<img src={this.state.innerSelected !== TREATMENTS ? arrowNext : arrowNextDisabled} alt="" />
								</div>
							</div>
							<div className="text-center">
								<Button onClick={() => this.changeSection(afterOperations)}>
									Siguiente
								</Button>
							</div>
						</div>
					)
				}

				{
					this.state.selected === PROCEDURE_PHOTOS && (
						<React.Fragment>
							<div className="bg-primary custom-progress py-1 text-white">
								<div className="custom-progress-item ml-4 custom-progress-item--inactive">Operaciones</div>
								<div className="custom-progress-item text-center">Fotos</div>
								<div className="custom-progress-item text-right mr-4 custom-progress-item--inactive">Observaciones</div>
							</div>
							<Tabs
								onChange={(i: number) => {
									this.setState({
										selected: i
									});
								}}
								selected={this.state.selected}
								items={[
									{ value: 1, label: 'Apariencia Actual' },
									{ value: 2, label: 'Más fotos (opcional)' }
								]}
							/>
							{this.state.photos.length > 0 ? (<div className="bg-cream px-4 py-3 photos-patient">
								<Owl
									ref={ref => (this.photosRef = ref)}
									dots={false}
									autoplay={false}
									itemsAmount={5}
									items={this.state.photos.map((image: any) => {
										return (
											<div onClick={() => {
												this.setState({
													visible: true,
													image: image.dataURL
												})
											}} className="item-photo" style={{
												backgroundImage: `url(${image.dataURL})`
											}}></div>
										)
									})}
								/>
								{this.state.photos.length > 5 && (
									<>
										<div className="prev-btn prev--float" onClick={() => this.photosRef.prev()}>
											<img src={arrowNextDark} alt="next" width={30} />
										</div>
										<div className="next-btn next--float" onClick={() => this.photosRef.next()}>
											<img src={arrowPrevDark} alt="prev" width={30} />
										</div>
									</>
								)}
							</div>) : (
								<div className="px-3 py-3"></div>
							)}
							<div className="row uploads">
								<div className="col-md-2 pr-0 btn-img">
									<div className="bg-white h-100 d-flex justify-content-center">
										<div onClick={() => this.uploadRef.prev()} style={{ cursor: 'pointer' }} className="h-100 d-flex justify-content-center">
											<img src={arrowleftSvg} alt="arrow" width="70" />
										</div>
									</div>
								</div>
								<div className="col-sm-8 pl-0 pr-0">
									<Owl
										ref={ref => (this.uploadRef = ref)}
										dots={false}
										autoplay={false}
										itemsAmount={this.state.typePhotos.length < 5 ? this.state.typePhotos.length : 5}
										items={this.state.typePhotos.map((typePhoto: defaultObjLayout, index: number) => {
											return (
												<ImageUpload
													width={30}
													uploaded={typePhoto.uploaded}
													onChange={(emitter: any) => this.handleImageUpload(emitter, typePhoto, index)}
													source={typePhoto.icon}
													label={typePhoto.name}
												/>
											)
										})}
									/>
								</div>
								<div className="col-md-2 pl-0 btn-img">
									<div className="bg-white h-100 d-flex justify-content-center">
										<div onClick={() => this.uploadRef.next()} style={{ cursor: 'pointer' }} className="h-100 d-flex justify-content-center">
											<img src={arrowrightSvg} alt="arrow" width="70" />
										</div>
									</div>
								</div>
							</div>
							<h3 className="mb-4">Carga las fotos de tu apariencia actual</h3>
							<div className="container-buttons">
								<div className="text-center">
									<Button onClick={() => this.changeSection(OPERATIONS)} color="gray-blue">
										Volver
									</Button>
									<Button
										onClick={() => this.changeSection(PATIENT_INFO)}>
										Siguiente
									</Button>
								</div>
							</div>
						</React.Fragment>
					)
				}

				{
					this.state.selected === PROCEDURE_PHOTOS_REFERENCE && (
						<React.Fragment>
							<div className="bg-prymary custom-progress py-1 text-white">
								<div className="custom-progress-item ml-4 custom-progress-item--inactive">Fotos</div>
								<div className="custom-progress-item text-center">Fotos</div>
								<div className="custom-progress-item text-right mr-4 custom-progress-item--inactive">Observaciones</div>
							</div>
							<Tabs
								onChange={(i: number) => {
									this.setState({
										selected: i
									});
								}}
								selected={this.state.selected}
								items={[
									{ value: 1, label: 'Apariencia Actual' },
									{ value: 2, label: 'Más fotos (opcional)' }
								]}
							/>
							<h3>Si lo prefiere, puede enviarnos más imágenes. ¿Que característica le gustaría tener? (Opcional, máximo {_CANT_MAX})</h3>
							<div className="container-paper-clip text-center">
								<PaperClip
									onChange={(e: any) => {
										const references: any = [...this.state.references];
										if (references.length < _CANT_MAX) {
											references.push(e.target)
											this.setState({
												references
											}, () => {
												this.forceUpdate();
											});
										}
										else {
											Globals.showError(`Lo sentimos, no pude subir más de ${_CANT_MAX} imagenes`);
										}
									}} />
							</div>
							<div className="p-4">
								<Owl items={this.state.references.map((image: any) => (
									<div onClick={() => {
										this.setState({
											visible: true,
											image: image.dataURL
										})
									}} className="item-photo" style={{
										backgroundImage: `url(${image.dataURL})`
									}}></div>
								)
								)} />
							</div>
							<div className="container-buttons">
								<div className="text-center">
									<Button onClick={() => this.changeSection(OPERATIONS)}>
										Volver
									</Button>
									<Button onClick={() => this.changeSection(PATIENT_INFO)}>
										Siguiente
									</Button>
								</div>
							</div>
						</React.Fragment>
					)
				}

				{
					this.state.selected === PATIENT_INFO && (
						<React.Fragment>
							<div className="bg-primary custom-progress py-1 text-white">
								<div className="custom-progress-item ml-4 custom-progress-item--inactive">Fotos</div>
								<div className="custom-progress-item text-center">Observaciones</div>
								<div className="custom-progress-item text-right mr-4 custom-progress-item--inactive">Verificar</div>
							</div>
							<h3 className="my-4">Indique su peso, altura y características físicas</h3>
							<div className="px-8 mx-4 patient-info">
								{/* <div className="row">
									<div className="col-md-4"> */}
								<div className="d-flex mb-4">
									<div className="patient-info-inputs">

										<div className="container-select-height">
											<MandatoryItem />
											<Input
												type="decimal"
												color="gray"
												containerStyle={{ flex: 1 }}
												value={this.state.form.height}
												maxLength="8"
												name="height"
												placeholder="Estatura"
												onChange={this.change} />
											<Select
												color="gray"
												name="height_unit_id"
												placeholder="Unidad"
												onChange={(e: any) => {
													const height = this.props.units.find((i: any) => i.id == e.target.value);
													const weight = this.props.units.find((i: any) => i.category == height.category && i.type != height.type);
													this.setState({
														form: {
															...this.state.form,
															height_unit_id: height.id,
															weight_unit_id: weight.id
														}
													})
												}}
												value={this.state.form.height_unit_id}
												options={this.props.units.filter((i: any) => i.type == 2).map((i: any) => {
													return {
														value: i.id,
														label: i.name
													}
												})} />
										</div>

										<div className="container-select-weight">
											<MandatoryItem />
											<Input
												containerStyle={{ flex: 1 }}
												type="decimal"
												color="gray"
												value={this.state.form.weight}
												maxLength="8"
												name="weight"
												placeholder="Peso"
												onChange={this.change} />
											<Select
												color="gray"
												name="weight_unit_id"
												placeholder="Unidad"
												disabled={true}
												onChange={this.change}
												value={this.state.form.weight_unit_id}
												options={this.props.units.filter((i: any) => i.type == 1).map((i: any) => {
													return {
														value: i.id,
														label: i.name
													}
												})} />
										</div>

										{(!user.data || !user.data.gender) && (
											<div style={{ display: 'flex' }}>
												<NonMandatoryItem />
												<Select
													color="gray"
													name="gender"
													placeholder="Genero"
													containerStyle={{ flex: 1 }}
													onChange={this.change}
													value={this.state.form.gender}
													options={Constants.GENDERS} />
											</div>
										)}

										{(!user.data || !user.data?.birthdate) && (
											<div style={{ display: 'flex' }}>
												<NonMandatoryItem />
												<DatePicker
													containerStyle={{ flex: 1 }}
													showYearDropdown={true}
													maxDate={moment().subtract(18, 'years').toDate()}
													textDefault="Fecha de Nacimiento"
													onChange={(text: string) => {
														this.setState({
															form: {
																...this.state.form,
																birthdate: text
															}
														});
													}}
													value={this.state.form.birthdate}
												/>
											</div>
										)}

										{(!user.data || !user.data?.previous_procedures) && (
											<div style={{ display: 'flex' }}>
												<NonMandatoryItem />
												<Textarea
													containerStyle={{ flex: 1 }}
													rows="2"
													placeholder="Procedimientos Quirúrgicos Anteriores"
													name="previous_procedures"
													value={this.state.form.previous_procedures}
													onChange={this.change}
												/>
											</div>
										)}


										{(!user.data || !user.data?.diseases) && (
											<div style={{ display: 'flex' }}>
												<NonMandatoryItem />
												<Textarea
													containerStyle={{ flex: 1 }}
													rows="2"
													placeholder="¿Padece de alguna enfermedad?"
													name="diseases"
													value={this.state.form.diseases}
													onChange={this.change}
												/>
											</div>
										)}

										{(!user.data || !user.data?.medicines) && (
											<div style={{ display: 'flex' }}>
												<NonMandatoryItem />
												<Textarea
													containerStyle={{ flex: 1 }}
													rows="2"
													placeholder="¿Toma algún medicamento?"
													name="medicines"
													value={this.state.form.medicines}
													onChange={this.change}
												/>
											</div>
										)}

										{(!user.data || !user.data?.allergies) && (
											<div style={{ display: 'flex' }}>
												<NonMandatoryItem />
												<Textarea
													containerStyle={{ flex: 1 }}
													rows="2"
													placeholder="¿Es alérgico a algún medicamento?"
													name="allergies"
													value={this.state.form.allergies}
													onChange={this.change}
												/>
											</div>
										)}
									</div>
									<div className="patient-info-area" style={{ display: 'flex' }}>
										<MandatoryItem />
										<Textarea
											containerStyle={{ flex: 1 }}
											// rows={10.5}
											placeholder={"¿Algun comentario para el " + ENV.NAME + "?"}
											name="description"
											value={this.state.form.description}
											onChange={this.change}
										/>
									</div>
								</div>
								{/* </div>
									<div className="col-md-8"> */}
								{/* </div> */}
								{/* </div> */}
							</div>
							<div className="px-5 mx-4 text-danger" style={{ marginBottom: '2em' }}>
								<p style={{ marginLeft: '1.5em' }}>* Estos campos son obligatorios para continuar el proceso</p>
							</div>
							<div className="container-buttons">
								<div className="text-center">
									<Button onClick={() => this.changeSection(PROCEDURE_PHOTOS)} color="gray-blue">
										Volver
									</Button>
									<Button disabled={this.validateForm()} onClick={() => {
										if (isNaN(parseFloat(this.state.form.height))) {
											Globals.showError("Lo sentimos, el valor de la altura no es válido");
											return false;
										}
										if (isNaN(parseFloat(this.state.form.weight))) {
											Globals.showError("Lo sentimos, el valor del peso no es válido");
											return false;
										}
										this.changeSection(PAYMENT);
									}}>
										Siguiente
									</Button>
								</div>
							</div>
						</React.Fragment>
					)
				}

				{
					this.state.selected === PAYMENT && (
						<React.Fragment>
							<div className="bg-primary custom-progress py-1 text-white">
								<div className="custom-progress-item ml-4 custom-progress-item--inactive">Observaciones</div>
								<div className="custom-progress-item text-center">Verificar</div>
								<div className="custom-progress-item text-right mr-4 custom-progress-item--inactive"></div>
							</div>
							<div className="col-md-12 text-center my-2 py-4">
								<div className="container">
									<div className="row col-md-6 offset-md-3 text-justify justify-content-md-center">
										<div className="col-md-12">
											<div className="d-flex justify-content-between mb-4 align-items-center">
												<Button onClick={() => this.changeSection(VIEW_PHOTOS)} color="primary" className="radius">
													<div className="d-flex justify-content-between mb-4align-items-center">
														<img src={eyeSvg} alt="eye" width={25} className="mr-1" />
														Ver fotos actuales
													</div>
												</Button>
												<Button onClick={() => this.changeSection(VIEW_PHOTOS_REFERENCE)} color="primary" className="radius">
													<div className="d-flex justify-content-between mb-4align-items-center">
														<img src={eyeSvg} alt="eye" width={25} className="mr-1" />
														Más fotos (opcional)
													</div>
												</Button>
											</div>
										</div>
										<div className="col-md-12">
											<b>Operación(es):</b> <span className="font-light">{this.evalOperationsName(this.state.procedures)}</span>
										</div>
										<div className="col-md-12">
											<b>Estatura:</b> <span className="font-light">{this.state.form.height} {this.props.units.find((i: any) => i.id == this.state.form.height_unit_id).name}</span>
										</div>
										<div className="col-md-12">
											<b>Peso:</b> <span className="font-light">{this.state.form.weight} {this.props.units.find((i: any) => i.id == this.state.form.weight_unit_id).name}</span>
										</div>

										{this.state.form.gender && (<div className="col-md-12">
											<b>Genero:</b> <span className="font-light">{_genderSelected && _genderSelected.label}</span>
										</div>)}
										{this.state.form.birthdate && (<div className="col-md-12">
											<b>Fecha de nacimiento:</b> <span className="font-light">{moment(this.state.form.birthdate).format('DD MMMM YYYY')}</span>
										</div>)}
										{this.state.form.previous_procedures && (<div className="col-md-12">
											<b>Procedimientos Quirúrgicos Anteriores:</b> <span className="font-light">{this.state.form.previous_procedures}</span>
										</div>)}
										{this.state.form.diseases && (<div className="col-md-12">
											<b>Enfermedades que padece:</b> <span className="font-light">{this.state.form.diseases}</span>
										</div>)}
										{this.state.form.medicines && (<div className="col-md-12">
											<b>Medicamentos que toma:</b> <span className="font-light">{this.state.form.medicines}</span>
										</div>)}
										{this.state.form.allergies && (<div className="col-md-12">
											<b>Alergias a medicamentos:</b> <span className="font-light">{this.state.form.allergies}</span>
										</div>)}
										{/* <div className="col-md-12">
											<b>Tamaño de busto:</b> <span className="font-light">{this.state.form.bust_size}</span>
										</div>
										<div className="col-md-12">
											<b>Medida de cadera:</b> <span className="font-light">{this.state.form.hip_measurement}</span>
										</div>
										<div className="col-md-12">
											<b>Medida de cintura:</b> <span className="font-light">{this.state.form.hip_measurement}</span>
										</div> */}
										<div className="col-md-12">
											<b>Descripción:</b> <span className="font-light">{this.state.form.description}</span>
										</div>
										<div className="col-md-12">
											<hr style={{ borderTop: '1px solid rgba(0,0,0,.5)' }} />
											<div className="text-center text-black">Monto a Pagar: </div>
											<div className="font-bold text-center">{amount}</div>
										</div>
									</div>
								</div>
								<div className="row text-center">
									<div className="col-md-6 offset-md-3 text-center container-buttons mt-4">
										{/*
										!this.state.showForm && !Globals.isColombia(this.props.user) && (
											// <div className="col-md-6 text-center">
												<Paypal
												total={ this.state.usd }
												onSuccess={ this.onSuccess }
												onError={ this.onError }
												showPayment={ this.state.showPayment }
												updatePayment={ (status: boolean) => {
													this.setState({
														showPayment: status
													});
												} }
												/>
												// </div>
											)
										*/}

										{/*
											Globals.isColombia(this.props.user) && 
											// <div className="col-md-6 text-center">
												<Epayco
													onPay={ () => this.showForm(true) }
													onBack={ () => this.showForm(false) }
													showForm={ this.state.showForm }
													total={ this.state.cop }
													onSuccess={ this.onSuccess }
													onError={ this.onError }
													showPayment={ true }
													document_types={ this.props.document_types }
												/>
											// </div> 
										*/}

										<div className="col-md-12 text-center">
											{/* <Stripe
												total={ this.state.usd }
												onSuccess={ this.onSuccess }
												onError={ this.onError }
											/> */}
											<Button
												className="mt-3"
												style={{ width: '350px', paddingTop: '10px', paddingBottom: '10px' }}
												onClick={() => window.open("https://cards.bio/drgabriel-lobo", "_blank")}
											>
												Realizar un pago manual
											</Button>

											<Button
												className="mt-3"
												style={{ width: '350px', paddingTop: '10px', paddingBottom: '10px' }}
												onClick={() => this.showGetCode()}
											>
												Tengo un código
											</Button>
										</div>

										<Button
											className="mt-3"
											style={{ width: '350px', paddingTop: '10px', paddingBottom: '10px' }}
											onClick={() => this.state.showForm ?
												this.showForm(false) : this.changeSection(PATIENT_INFO)}
										>
											Volver
										</Button>
									</div>

								</div>
							</div>
						</React.Fragment>
					)
				}

				{
					this.state.selected === TREATMENT_INFO && (
						<React.Fragment>
							<div className="bg-primary custom-progress py-1 text-white">
								<div className="custom-progress-item ml-4"></div>
								<div className="custom-progress-item text-center">Operaciones</div>
								<div className="custom-progress-item text-right mr-4 custom-progress-item--inactive"></div>
							</div>
							<div className="treatment-info">
								<div className="treatment-info-header">
									<h3 className="font-bold">{this.state.selectedTreatment?.name}</h3>
									<h3 className="font-regular text-gray-dark px-3">{this.state.selectedTreatment?.description}</h3>
									{this.state.selectedTreatment?.other === 0 && (
										<Owl ref={(ref) => (this.videoRef = ref)} video={true} itemsAmount={1} items={this.state.selectedTreatment?.files.map((image: any) => {
											return (
												this.evalMediaType(image)
											)
										})} />
									)}
									{this.state.selectedTreatment?.files?.length > 1 && (
										<>
											<div className="prev-btn prev--float" onClick={() => this.videoRef.prev()}>
												<img src={arrowPrev} alt="" />
											</div>
											<div className="next-btn next--float" onClick={() => this.videoRef.next()}>
												<img src={arrowNext} alt="" />
											</div>
										</>
									)}
								</div>

								<Textarea
									placeholder={"¿Algun comentario para el " + ENV.NAME + "?"}
									name="description"
									value={this.state.form.description}
									onChange={this.change}
								/>

							</div>
							<div className="treatment-footer">
								<div className="container-buttons">
									<div className="text-center">
										<Button onClick={() => this.changeSection(OPERATIONS)} color="gray-blue">
											Volver
										</Button>
										<Button onClick={() => this.sendWhatsapp()}>
											Ir al Whatsapp
										</Button>
									</div>
								</div>
							</div>
						</React.Fragment>
					)
				}

				{
					this.state.selected === VIEW_PHOTOS && (
						<React.Fragment>
							<div className="bg-primary custom-progress py-1 text-white">
								<div className="custom-progress-item ml-4 custom-progress-item--inactive">Observaciones</div>
								<div className="custom-progress-item text-center">Verificar</div>
								<div className="custom-progress-item text-right mr-4 custom-progress-item--inactive"></div>
							</div>
							<div className="p-4">
								<Owl
									dots={false}
									autoplay={false}
									itemsAmount={3}
									items={this.state.photos.map((image: any) => {
										return (
											<div onClick={() => {
												this.setState({
													visible: true,
													image: image.dataURL
												})
											}} className="item-photo" style={{
												backgroundImage: `url(${image.dataURL})`
											}}></div>
										)
									})}
								/>
								{this.state.photos.length === 0 && (<p className="text-center">No cargó imagenes</p>)}
							</div>
							<div className="container-buttons">
								<div className="text-center">
									<Button onClick={() => this.changeSection(PAYMENT)} color="gray-blue">
										{/* <Icon name="arrow-left" /> */}
										Volver
									</Button>
								</div>
							</div>
						</React.Fragment>
					)
				}

				{
					this.state.selected === VIEW_PHOTOS_REFERENCE && (
						<React.Fragment>
							<div className="bg-primary custom-progress py-1 text-white">
								<div className="custom-progress-item ml-4 custom-progress-item--inactive">Observaciones</div>
								<div className="custom-progress-item text-center">Verificar</div>
								<div className="custom-progress-item text-right mr-4 custom-progress-item--inactive"></div>
							</div>
							<div className="p-4">
								<Owl
									dots={false}
									autoplay={false}
									itemsAmount={3}
									items={this.state.references.map((image: any) => {
										return (
											<div onClick={() => {
												this.setState({
													visible: true,
													image: image.dataURL
												})
											}} className="item-photo" style={{
												backgroundImage: `url(${image.dataURL})`
											}}></div>
										)
									})}
								/>
								{this.state.references.length === 0 && (<p className="text-center">No cargó imagenes</p>)}
							</div>
							<div className="container-buttons">
								<div className="text-center">
									<Button onClick={() => this.changeSection(PAYMENT)} color="gray-blue">
										{/* <Icon name="arrow-left" /> */}
										Volver
									</Button>
								</div>
							</div>
						</React.Fragment>
					)
				}
			</div>
		)
	}
}

const MandatoryItem = () => (
	<span
		className="text-danger"
		style={{
			width: '1.5em',
			userSelect: 'none',
			paddingTop: '0.75em',
		}}
	>
		*
	</span>
)

const NonMandatoryItem = () => (
	<span
		style={{
			width: '1.5em',
			alignSelf: 'center',
			justifySelf: 'center',
			userSelect: 'none',
			paddingBottom: '0.5em',
		}}
	/>
)

export default connect((state: any) => {
	return {
		user: state.user,
		evaluation: state.evaluation,
	}
})(CreateEvaluation);