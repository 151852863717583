import React from 'react';
import { CardElement, ElementsConsumer, Elements } from '@stripe/react-stripe-js';
import { ENV, Globals, Constants } from 'utils';
import { loadStripe } from '@stripe/stripe-js';
import { StripeLogo } from 'assets/img';
import { StripeService } from 'services';
import { store } from 'store';

const stripePromise = loadStripe(ENV.STRIPE_PUBLIC_KEY);

interface StripeProps extends StripeContainerProps {
  stripe: any,
  elements: any
}

interface StripeContainerProps {
  total: number,
  onSuccess: (payment: any, method: number) => void,
  onError: (err: any, method: number) => void,
}

class Stripe extends React.Component<StripeProps> {

	submit = async (e: any) => {
		e.preventDefault();
		const { stripe, elements } = this.props;
	    if (!stripe || !elements) {
	      return;
	    }

	    Globals.setLoading();

	    const card = elements.getElement(CardElement);
	    const result = await stripe.createToken(card);
	    if (result.error) {
	      Globals.quitLoading();
	      Globals.showError(result.error.message);
	    } else {
	      const res: any = await StripeService.create({
	      	user_id: store.getState().user.id,
	      	token: result.token.id,
	      	total: this.props.total,
	      	withoutLoading: true
	      });
	      setTimeout(() => {
			  if (res.result) {
		      	this.props.onSuccess(res.payment,Constants.PAYMENT_METHODS.STRIPE);
		      }
		      else {
		      	this.props.onError(res.error,Constants.PAYMENT_METHODS.STRIPE);
		      }
	      },100);	      
	    }
	}
	
	render() {
	  const { stripe } = this.props;

	  return (
	  	<div className="stripe-container">
		  	{
		  		!stripe ? (
		  		  <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
		  		) : (
		  		  <form onSubmit={ this.submit }>
		  		  	<img src={ StripeLogo } />
			        <CardElement />
	        		<button type="submit" disabled={ !stripe }>
			          Pagar
			        </button>     
			      </form>
		  		)
		  	}
	  	</div>  	
	  );
	}
}

const StripeContainer = (props: StripeContainerProps) => (
	<Elements stripe={ stripePromise }>
		<ElementsConsumer>
	  		{({elements, stripe}) => (
		    	<Stripe 
			    	{...props}
			    	elements={ elements } 
			    	stripe={ stripe }
			    />
		    )}
		</ElementsConsumer>
	</Elements>
)

export default StripeContainer;