import React from 'react';
import { Input, Button } from 'components';
import { PatientService } from 'services';
import { Globals } from 'utils';

interface ChangePasswordProps {
	onClose: () => void,
	patient: any
}

class ChangePassword extends React.Component<ChangePasswordProps> {

	state = {
		form: {
			password: '',
			password_confirmation: ''
		}
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	submit = async (e: any) => {
		e.preventDefault();
		let params: any = { ...this.state.form };
		params.id = this.props.patient.id;
		await PatientService.changePass(params);
		this.props.onClose();
		Globals.showSuccess("Se ha cambiado correctamente la contraseña");
	}
		
	render() {
		return (
			<form onSubmit={ this.submit } className="modal-change-password">
				<h2 className="h2-title">Contraseña de { this.props.patient.person.name + ' ' + this.props.patient.person.lastname }</h2>
				<Input
	            	type="password"
	                color="gray"
	                value={ this.state.form.password }
	                name="password" 
	                label="Nueva Contraseña"
	                onChange={ this.change } />
	            <Input
	            	type="password"
	                color="gray"
	                value={ this.state.form.password_confirmation }
	                name="password_confirmation" 
	                label="Confirmar contraseña"
	                onChange={ this.change } />
	            <Button block="true" type="submit">
                    Guardar
                </Button>
			</form>
		)
	}
}

export default ChangePassword;