import { Api } from 'utils';

class ProcedureService {
	
	static get = async (params?: any) => Api.createResponse('procedures/get',params);
	static create = async (params?: any) => Api.createResponse('procedures/create',params);
	static edit = async (params?: any) => Api.createResponse('procedures/edit',params);
	static delete = async (params?: any) => Api.createResponse('procedures/delete',params);
}

export default ProcedureService;