import React from 'react';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import Icon from './icon';
import moment from 'moment';

registerLocale('es', es)
setDefaultLocale('es')

const CustomInput = (props: any) => (
	<div className={ `container-datepicker ${ props.color ? props.color : '' }` } 
		onClick={ props.onClick }>
		<p>{ props.value }</p>
		<Icon name="clock-o" />
	</div>
)

interface TimeProps {
	includeTimes?: Date[],
	label?: string,
	onChange: (date: Date) => void,
	value?: Date,
	color?: string,
	timeInterval?: number
}

const _TimePicker = (props: TimeProps) => (
	<div className="form-group">
		{ props.label && <label className="label-datepicker">{ props.label }</label> }
		<DatePicker
			showTimeSelect
		    showTimeSelectOnly
		    timeIntervals={ props.timeInterval ? props.timeInterval : 30 }
		    timeCaption="Hora"
		    includeTimes={ props.includeTimes }
		    selected={ moment(props.value,'HH:mm:ss').toDate() }
		    onChange={ props.onChange }
		    dateFormat="HH:mm"
		    customInput={ <CustomInput { ...props } /> }
		/>
	</div>
)

export default _TimePicker;