import { Api } from "utils";

class AppointmentService {
  static get = async (params?: any) => Api.createResponse("appointments/get", params);
  static changeStatus = async (params?: any) => Api.createResponse("appointments/change-status", params);
  static createRecipe = async (params?: any) => Api.createResponse("appointments/create-recipe", params);
  static deleteRecipe = async (params?: any) => Api.createResponse("appointments/delete-recipe", params);
  static print = async (params?: any) => Api.createResponse("appointments/print-recipe", params);
  static deletePrint = async (params?: any) => Api.createResponse("appointments/delete-print", params);
  static send = async (params?: any) => Api.createResponse("appointments/send", params);

  // Clients
  static getClient = async (params?: any) => Api.createResponse("clients/appointments/get", params);
  static create = async (params?: any) => Api.createResponse("clients/appointments/create", params);
  static paymentError = async (params?: any) => Api.createResponse("clients/evaluations/payment-error", params);
  static check = async (params?: any) => Api.createResponse("clients/appointments/check", params);
}

export default AppointmentService;
