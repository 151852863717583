import React from 'react';
import { Input, Textarea, Button, Avatar } from 'components';
import { ProcedureService } from 'services';
import { Socket, Constants, ENV } from 'utils';

class CreateEditProcedure extends React.Component<any> {

	state = {
		form: {
			name: '',
			description: '',
			id: null,
			icon: {
				file: null,
				preview: null
			},
		},
		image: null
	}

	componentDidMount() {
		const item: any = this.props.item;

		if (item) {
			this.setState({
				form: {
					...this.state.form,
					name: item.name,
					description: item.description,
					id: item.id
				},
				image: item.icon ? (ENV.BasePublic + item.icon) : null
			});
		}
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	submit = async () => {
		let data: any = { ...this.state.form };
		if (data.icon.file) {
			data.file = data.icon.file;
			data.hasFile = true;
		}		
		const res: any = await ProcedureService[this.props.item ? 'edit' : 'create'](data);
		this.props.onClose(res.procedure);
		Socket.emit(Constants.PROCEDURES.CHANGE,res.procedures);
	}

	render() {
		return (
			<div className="modal-create-edit">
				<Avatar
					name="icon"
					disabled={ this.props.view }
					onChange={ this.change }
					value={ this.state.form.icon.preview || this.state.image } />
				<Input
                    color="gray"
                    value={ this.state.form.name }
                    name="name" 
                    label="Nombre"
                    disabled={ this.props.view }
                    onChange={ this.change } />
                <Textarea
                    label="Descripción"
                    name="description"
                    disabled={ this.props.view }
                    value={ this.state.form.description }
                    onChange={ this.change }
                />
                { !this.props.view && <Button block="true" type="button" onClick={ this.submit } >
                    Guardar
                </Button> }
			</div>
		)
	}
}

export default CreateEditProcedure;