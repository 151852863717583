import React from 'react';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';
import { ENV, Constants } from 'utils';

declare let paypal: any;

interface PaypalProps {
  isScriptLoaded?: boolean,
  isScriptLoadSucceed?: boolean,
  total: number,
  onSuccess: (payment: any, method: number) => void,
  onError: (err: any, method: number) => void,
  showPayment: boolean,
  updatePayment: (status: boolean) => void
}

class PaypalButton extends React.Component<PaypalProps> {

  componentDidMount() {
    window.React = React;
    window.ReactDOM = ReactDOM;

    const {
      isScriptLoaded,
      isScriptLoadSucceed
    } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.props.updatePayment(true);
    }
  }

  componentWillReceiveProps(nextProps: any) {
    const {
      isScriptLoaded,
      isScriptLoadSucceed,
    } = nextProps;
    const isLoadedButWasntLoadedBefore =
      !this.props.showPayment &&
      !this.props.isScriptLoaded &&
      isScriptLoaded;
    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.props.updatePayment(true);
      }
    }
  }

  render() {
    const {
      total,
      onSuccess,
      onError,
      showPayment
    } = this.props;

    const payment = () =>
      paypal.rest.payment.create(ENV.PAYPAL_MODE, ENV.PAYPAL_CLIENT, {
        transactions: [
          {
            amount: {
              total,
              currency: ENV.PAYPAL_CURRENCY,
            }
          },
        ],
      });

    const onAuthorize = (data: any, actions: any) =>
      actions.payment.execute()
        .then(() => {
          actions.payment.get().then((_data: any) => {
            const payment = {
              paid: true,
              cancelled: false,
              payerID: data.payerID,
              paymentID: _data.transactions[0].related_resources[0].sale.id,
              paymentToken: data.paymentToken,
              returnUrl: data.returnUrl,
            };
            onSuccess(payment, Constants.PAYMENT_METHODS.PAYPAL);
          });          
        });
  
    if (this.props.showPayment)
      return (
        <React.Fragment>
          { showPayment && <paypal.Button.react
            env={ ENV.PAYPAL_MODE }
            client={ ENV.PAYPAL_CLIENT }
            commit={ true }
            payment={ payment }
            onAuthorize={ onAuthorize }
            onError={ (err: any) => onError(err,Constants.PAYMENT_METHODS.PAYPAL) }
            style={ {
             color: 'blue',
             size: 'large',
             label: 'paypal',
             text: false
            } }
          /> }
        </React.Fragment>
      )
    else
      return <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
  }

}

export default scriptLoader('https://www.paypalobjects.com/api/checkout.js')(PaypalButton);