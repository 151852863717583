import React from 'react';
import { Textarea, Button, Icon, PaperClip, Viewer } from 'components';
import { EvaluationService } from 'services';
import { connect } from 'react-redux';
import { Socket, Constants, ENV, Globals } from 'utils';
import { on } from 'jetemit';
import moment from 'moment';

interface MessagesProps {
	user: any,
	evaluation: any,
	updateEvaluation: (messages: any) => void
}

class Messages extends React.Component<MessagesProps> {

	private scroll: any;

	state: any = {
		form: {
			message: '',
			file: null
		},
		subscriber: null,
		messages: [],
		visible: false,
		image: null
	}

	async componentDidMount() {
		const evaluation: any = this.props.evaluation
		evaluation.messages.forEach((item: any) => {
			if (item.user_id != this.props.user.id) {
				item.view = 1;
			}
		});
		evaluation.badge = undefined;
		this.props.updateEvaluation(evaluation);
		const messages: any = [ ...evaluation.messages ].reverse();
		messages.forEach((item: any) => {
			item.position = 'left';
			if (this.props.user.id == item.user.id) {
				item.position = 'right';
			}
		});

		this.setState({
			messages,
			subscriber: on(Constants.EVALUATIONS.SEND_MESSAGE,(data: any) => {
				let messages = [...this.state.messages];
				data.position = 'left';
				if (this.props.user.id == data.user.id) {
					data.position = 'right';
				}
				messages.push(data);
				this.setState({
					messages
				},this.scrollToBottom);
				const evaluation = { ...this.props.evaluation };
				evaluation.messages = [...messages].reverse();
				this.props.updateEvaluation(evaluation);
				if (this.props.user.id != data.user.id) {
					Socket.emit(Constants.EVALUATIONS.MESSAGE_VIEWED,{
						id: data.id
					});
				}
			})
		},this.scrollToBottom);

		await EvaluationService.viewMessages({
			user_id: this.props.user.id,
			evaluation_id: this.props.evaluation.id,
			withoutLoading: true
		});
	}

	scrollToBottom = () => {
		this.scroll.scrollTop = this.scroll.scrollHeight;
	}

	componentWillUnmount() {
		if (this.state.subscriber) {
			this.state.subscriber();
		}
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	submit = async (e: any = null) => {
		if (e)
			e.preventDefault();
		const res: any = await EvaluationService.send({
			user_id: this.props.user.id,
			evaluation_id: this.props.evaluation.id,
			text: this.state.form.message,
			file: this.state.form.file,
			hasFile: this.state.form.file != null
		});
		this.setState({
			form: {
				message: '',
				file: null
			}
		});
		Socket.emit(Constants.EVALUATIONS.SEND_MESSAGE,res.message);
	}

	enter = (event: any) => {
		if (event.key == 'Enter') {
			event.preventDefault();
			this.submit();
		}
	}

	view = (image: string) => {
		this.setState({
			visible: true,
			image
		});
	}
	
	render() {
		return (
			<React.Fragment>
				<Viewer
			      visible={ this.state.visible }
			      onClose={ () => this.setState({ visible: false }) }
			      images={ [
					{ src: this.state.image, alt: '' },
			      ] } />

				<div className="container-messages">
					<div className="messages-box" ref={ ref => this.scroll = ref }>
						{
							this.state.messages.map((i: any) => {

								const image: string = i.file_url;

								return (
									<div className={ `item-message ${ i.position }` }>
										<p className="name">{ i.user.person.name + ' ' + i.user.person.lastname }</p>
										<p dangerouslySetInnerHTML={{__html: Globals.urlify(i.text)}}></p>
										{
											i.file && (
												<img onClick={ () => this.view(image) } className="photo-message" src={ image } />
											)
										}
										<p className="date">{ moment(i.created_at).format('DD/MM/YYYY HH:mm') }</p>
									</div>
								)								
							})
						}
					</div>
					{ this.props.evaluation && this.props.evaluation.status == 1 && (
						<form onSubmit={ this.submit }>
							<Textarea
								onKeyDown={ this.enter }
								rows={ 3 }
			            	    placeholder="Mensaje"
			            	    name="message"
			            	    value={ this.state.form.message }
			            	    onChange={ this.change }
			            	/>
							<div className="row-buttons-container">
								<div className="row row-buttons-messages">
									<div className="col-md-6">
										<PaperClip
											name="file"
											value={ this.state.form.file } 
											onChange={ (e: any) => {
												this.setState({
													form: {
														...this.state.form,
														file: e.target.value,
														message: this.state.form.message || "Imagen Adjunta"
													}
										        });
												this.submit();
											} } />
									</div>
									<div className="col-md-6">
										<Button type="submit">
											Enviar
										</Button>
									</div>
								</div>
							</div>
						</form>
					) }
				</div>
			</React.Fragment>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user
	}
})(Messages);