import React from 'react';
import Menu from 'screens/menu';
import { Input, Button, Pagination, Table, Icon, Select, Modal, ButtonAdd } from 'components';
import { ModeratorService, CountryService } from 'services';
import { Globals, Socket, Constants } from 'utils';
import CreateEditModerators from './create-edit-moderators';
import ChangePassword from './change-password';
import Header from 'components/header';
import Action from 'components/action';

class Moderators extends React.Component<any> {

	state: any = {
		page: 1,
		last_page: 1,
		data: [],
		form: {
			search: '',
			country: 0,
			status: -1
		},
		edit: false,
		view: false,
		item: null,
		countries: [],
		loading: true,
		header: [
			'Nombre',
			'Correo Electrónico',
			'Teléfono',
			'País',
			'Estatus',
			'Acciones'
		],
		status: [
			{ value: -1, label: 'Todos' },
			{ value: 1, label: 'Activos' },
			{ value: 0, label: 'Inactivos' }
		],
		changePass: false,
		permissions: []
	}

	async componentDidMount() {
		await this.getData();
		this.load();
	}

	getData = async () => {
		const res: any = await Promise.all([
			CountryService.get({
				withoutLoading: true,
			}),
			ModeratorService.getPermissions({
				withoutLoading: true,
			})
		])
		this.setState({
			countries: [
				{
					id: 0,
					name: "Todos"
				},
				...res[0].countries,
			],
			permissions: res[1].permissions
		});
	}

	load = async () => {
		const params = {
			page: this.state.page,
			search: this.state.form.search,
			country: this.state.form.country,
			status: this.state.form.status
		}
		const res: any = await ModeratorService.get(params)
		if (res.moderators.data.length == 0 && this.state.page > 1) {
			this.setState({
				page: 1
			});
			this.load();
		}
		else {
			this.setState({
				data: res.moderators.data,
				last_page: res.moderators.last_page,
				loading: false
			});
		}		
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	delete = (item: any) => {
		Globals.confirm('¿Desea eliminar el moderador?',async () => {
			await ModeratorService.delete({
				id: item.id
			});
			this.load();
			Socket.emit(Constants.PATIENTS.USER_DELETE,item.id);
		});
	}

	onCloseEdit = async (item: any) => {
      await this.setState({
        edit: false,
        item: null
      });
      this.load();
	}

	changeStatus = (item: any,action: string) => {
		Globals.confirm(`¿Desea ${ action.toLowerCase() } al moderador?`,async () => {
			await ModeratorService.changeStatus({
				id: item.id,
				status: item.status == 1 ? 0 : 1
			});
			this.load();
			if (item.status == 1)
				Socket.emit(Constants.PATIENTS.USER_DISABLE,item.id);
		});
	}

	changePass = (item: any) => {
		this.setState({
			item,
			changePass: true
		});
	}

	render() {
		return (
			<>
			<Header />
			<Menu history={ this.props.history }>
				<div id="moderators">
					
					<Modal
			            title={ this.state.item != null ? 'Editar moderador' : 'Nuevo moderador' }
			            visible={ this.state.edit }
			            onClose={ () => {
			              this.setState({
			                edit: false,
			                item: null
			              });
			            } } 
			          >
			          <CreateEditModerators
			          	permissions={ this.state.permissions }
			          	countries={ this.state.countries }
			          	item={ this.state.item }
			          	onClose={ this.onCloseEdit } />
			        </Modal>

			        <Modal
			            title="Ver moderador"
			            visible={ this.state.view }
			            onClose={ () => {
			              this.setState({
			                view: false,
			                item: null
			              });
			            } }
			          >
			          <CreateEditModerators
			          	permissions={ this.state.permissions }
			          	view={ true }
			          	countries={ this.state.countries }
			          	item={ this.state.item }
			          	onClose={ () => {
			              this.setState({
			                view: false,
			                item: null
			              });
			            } } />
			        </Modal>

			        <Modal
			            title="Cambiar contraseña"
			            visible={ this.state.changePass }
			            onClose={ () => {
			              this.setState({
			                changePass: false
			              });
			            } }
			          >
			          <ChangePassword
			          	moderator={ this.state.item }
			          	onClose={ () => {
			              this.setState({
			                changePass: false
			              });
			            } } />
			        </Modal>

					<form className="row form-filter" onSubmit={ async (e: any) => {
						e.preventDefault();
						await this.setState({
							page: 1
						});
						this.load();
					} }>
						<div className="col-md-3">
							<Input
								name="search" 
								label="Búsqueda"
								onChange={ this.change } 
								value={ this.state.form.search }
								placeholder="Buscar por Nombre, Correo o Teléfono" />
						</div>
						<div className="col-md-3">
							<Select
								name="country"
								label="País"
								onChange={ this.change }
								value={ this.state.form.country }
								options={ this.state.countries.map((i: any) => {
									return {
										value: i.id,
										label: i.name
									}
								}) } />
						</div>
						<div className="col-md-3">
							<Select
								name="status"
								label="Estatus"
								onChange={ this.change }
								value={ this.state.form.status }
								options={ this.state.status } />
						</div>
						<div className="col-md-3">
							<Button className="btn-align-bottom text-black font-bold">
								Filtrar
							</Button>
						</div>
					</form>
					<Table loading={ this.state.loading } data={ this.state.data.length } title="Moderadores" header={ this.state.header } right={
		                //   <Button
		                //     title="Nuevo"
		                //     outline="true"
		                //     small="true"
		                //     onClick={ () => {
		                //       this.setState({
						// 		edit: true
		                //       });
		                //     } }
		                //   >
		                //     <Icon name="plus" />
						//   </Button>
						<ButtonAdd onClick={ () => {
							this.setState({
							edit: true
							});
						} }/>
		                }>
						{ this.state.data.map((i: any,index: number) => {
							const action: string = i.status == 0 ? 'Activar' : 'Desactivar';

							return (
								<tr key={ index }>
									<td className="ellipsis">{ i.person.name + ' ' + i.person.lastname }</td>
									<td>{ i.email }</td>
									<td>{ i.person.phone }</td>
									<td>{ i.person.country.name }</td>
									<td>{ i.status == 1 ? 'Activo' : 'Inactivo' }</td>
									<td>
										{/* <Button color="info" title="Ver" small="true" onClick={ () => {
											this.setState({
												item: i,
												view: true
											});
										} }>
											<Icon name="eye" />												
										</Button> */}
										<Action title="Ver" icon="show" onClick={ () => {
											this.setState({
												item: i,
												view: true
											});
										} }/>
										{/* <Button title="Editar" small="true" onClick={ () => {
											this.setState({
												item: i,
												edit: true
											});
										} }>
											<Icon name="edit" />												
										</Button> */}
										<Action title="Editar"  icon="edit" onClick={ () => {
											this.setState({
												item: i,
												edit: true
											});
										} }/>

										{/* { !Globals.isSocial(i) && <Button  title="Cambiar contraseña" small="true" onClick={ () => this.changePass(i) }>
											<Icon name="key" />												
										</Button> } */}
										
										{ !Globals.isSocial(i) &&<Action title="Cambiar contraseña"  icon="password" onClick={ () => this.changePass(i) }/>}
										

										<Action  title={ action } icon={i.status == 1 ? 'lock' : 'unlock'} onClick={ () => this.changeStatus(i,action) } />
										{/* <Button title={ action } small="true" onClick={ () => this.changeStatus(i,action) }>
											<Icon name={ i.status == 1 ? 'lock' : 'unlock' } />												
										</Button> */}
										
										{/* <Button  title="Eliminar" small="true" onClick={ () => this.delete(i) }>
											<Icon name="trash" />												
										</Button> */}

										<Action title="Eliminar"  icon="delete" onClick={ () => this.delete(i) }/>
									</td>
								</tr>
							)
						}) }
					</Table>

					<Pagination 
						pages={ this.state.last_page } 
						active={ this.state.page }
						onChange={ async (page: number) => {
							await this.setState({
								page: page
							});
							this.load();
					} } />
				</div>
			</Menu>
				</>
		)
	}
}

export default Moderators;