import React from 'react';
// import { Button } from 'components';
// import ver from '../assets/icons/show.svg'

const Action = (props: any) => {
	const { icon, ...rest } = props
	return (
		<div {...rest } style={{cursor: 'pointer', display: 'inline', margin: '2px'}}>
			<img src={require('../assets/icons/' + icon + '.svg')} alt="action" width="28"/>											
		</div>
	)
}

export default Action;