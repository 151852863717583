import React from 'react';

const onChange = (event: any,_onChange: (event: any) => void,type: string = 'text') => {
	if (type == 'number') {
		const regex = /^[0-9\b]+$/;
		if (regex.test(event.target.value) || event.target.value == '') {
			_onChange(event);
		}
	}
	else if (type == 'decimal') {
		const regex = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
		if (regex.test(event.target.value) || event.target.value == '') {
			_onChange(event);
		}
	}
	else {
		_onChange(event);
	}
}

const Input	= (props: any) => (
	<div className="form-group" style={props.containerStyle}>
		{ props.label && <label htmlFor={ props.name }>{ props.label }</label> }
		<input 
			{ ...props }
			onChange={ (e) => onChange(e,props.onChange,props.type) }
			type={ props.type == 'number' ? 'text' : props.type }
			className={ `form-control ${ props.color ? 'input-'+props.color : 'input-white' }` }
			name={ props.name } />
	</div>
)

export default Input;