import { Api } from 'utils';
import {axios} from 'utils';

class ScheduleService {
	
	static get = async (params?: any) => Api.createResponse('schedule/get',params);
	static save = async (params?: any) => Api.createResponse('schedule/save',params);

	//Clients
	static getClient = async (params?: any) => Api.createResponse('/clients/schedules/get',params);
	static all = async (params?: any) => Api.createResponse('/clients/schedules/all',params);
}

export default ScheduleService;