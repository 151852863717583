import { AxiosError, AxiosResponse } from "axios";
import { Action, Button, Header, Modal, Table } from "components";
import {
  ContentBlock,
  DraftEditorCommand,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
  convertFromHTML,
  ContentState,
} from "draft-js";
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Menu from "screens/menu";
import { axios, Globals } from "utils";
import { stateToHTML } from "draft-js-export-html";

const emailTemplates = [
  {
    type: 1,
    name: "Recuperación de contraseña",
    hasBrackets: true,
  },
  {
    type: 2,
    name: "Presupuesto",
    hasBrackets: false,
  },
  {
    type: 3,
    name: "Presupuesto (PDF adjunto)",
    hasBrackets: true,
  },
  {
    type: 4,
    name: "Cita",
    hasBrackets: false,
  },
  {
    type: 5,
    name: "Cita (PDF adjunto)",
    hasBrackets: true,
  },
  {
    type: 6,
    name: "Recordatorio de presupuesto (10 días)",
    hasBrackets: false,
  },
  {
    type: 7,
    name: "Recordatorio de presupuesto (15 días)",
    hasBrackets: false,
  },
  {
    type: 8,
    name: "Recordatorio de cita",
    hasBrackets: false,
  },
  {
    type: 9,
    name: "Respuesta de evaluación",
    hasBrackets: false,
  },
  {
    type: 10,
    name: "Nueva evaluación",
    hasBrackets: true,
  },
];

type EmailTemplate = typeof emailTemplates[0];

type EditEmailProps = {
  template: EmailTemplate | null;
  onDismiss: () => unknown;
};

const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block: ContentBlock) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return "";
  }
}

type StyleButtonProps = {
  active: boolean;
  label: string;
  style: string;
  onToggle: (style: string) => unknown;
};

const StyleButton = ({ active, label, style, onToggle }: StyleButtonProps) => {
  return (
    <span
      className={`RichEditor-styleButton ${
        active ? "RichEditor-activeButton" : ""
      }`}
      onMouseDown={(event) => {
        event.preventDefault();
        onToggle(style);
      }}
    >
      {label}
    </span>
  );
};

const blockStyles = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  { label: "Cita", style: "blockquote" },
  { label: "Lista", style: "unordered-list-item" },
  { label: "Lista ordenada", style: "ordered-list-item" },
];

type BlockStyleControlsProps = {
  editorState: EditorState;
  onToggle: (style: string) => unknown;
};

const BlockStyleControls = ({
  editorState,
  onToggle,
}: BlockStyleControlsProps) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {blockStyles.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

var inlineStyles = [
  { label: "Negrita", style: "BOLD" },
  { label: "Cursiva", style: "ITALIC" },
  { label: "Subrayada", style: "UNDERLINE" },
];

type InlineStyleControlsProps = {
  editorState: EditorState;
  onToggle: (style: string) => void;
};

const InlineStyleControls = ({
  editorState,
  onToggle,
}: InlineStyleControlsProps) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {inlineStyles.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const EditEmail = ({ template, onDismiss }: EditEmailProps) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleKeyCommand = (
    command: DraftEditorCommand,
    editorState: EditorState
  ) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }

    return "not-handled";
  };

  const mapKeyToEditorCommand = (event: KeyboardEvent<{}>) => {
    if (event.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(event, editorState, 4);

      if (newEditorState !== editorState) {
        setEditorState(newEditorState);
      }

      return null;
    }

    return getDefaultKeyBinding(event);
  };

  const toggleBlockType = (blockType: string) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  useEffect(() => {
    setEditorState(EditorState.createEmpty());

    if (template === null) {
      return;
    }

    Globals.setLoading();
    axios
      .get(`emails/${template.type}`)
      .then(({ data }: AxiosResponse<{ text: string | null }>) => {
        const blocksFromHTML = convertFromHTML(data.text || "");
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );

        setEditorState(EditorState.createWithContent(contentState));
      })
      .catch((err: AxiosError) => {
        console.log("EditEmail: useEffect: ", err);
        onDismiss();
        Globals.showError(
          "Lo sentimos, ha ocurrido un error al cargar el correo"
        );
      })
      .finally(() => {
        Globals.quitLoading();
      });
  }, [template, onDismiss]);

  let className = "RichEditor-editor";
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== "unstyled") {
      className += " RichEditor-hidePlaceholder";
    }
  }

  const editorRef = useRef<Editor>(null);
  const focus = () => {
    editorRef.current?.focus();
  };

  const [title, setTitle] = useState("");
  useEffect(() => {
    if (!template) {
      return;
    }

    setTitle(template.name);
  }, [template]);

  return (
    <Modal
      className="modal-lg"
      title={`Editar email "${title}"`}
      visible={template !== null}
      onClose={onDismiss}
    >
      <div className="RichEditor-root">
        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
        />
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
        <div className={className} onClick={focus}>
          <Editor
            blockStyleFn={getBlockStyle}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            onChange={setEditorState}
            placeholder="Escriba el contenido del correo"
            ref={editorRef}
            spellCheck={true}
          />
        </div>
      </div>
      {template?.hasBrackets && (
        <p
          style={{
            paddingTop: "1em",
            marginBottom: "0px",
            paddingLeft: "0.5em",
          }}
          className="text-danger"
        >
          <strong>Nota</strong>: No eliminar las palabras marcadas en llaves,
          (por ejemplo {"{texto}"}) debido a que forman parte del funcionamiento
          del sistema.
        </p>
      )}
      <Button
        className="btn-align-bottom text-black font-bold"
        onClick={() => {
          Globals.setLoading();
          axios
            .post(`emails/${template?.type ?? 0}`, {
              text: stateToHTML(editorState.getCurrentContent()),
              _method: "PUT",
            })
            .then(() => {
              Globals.showSuccess("Se ha editado el correo");
              onDismiss();
            })
            .catch((err: AxiosError) => {
              console.log("EditEmail: onSubmit: ", err);
              Globals.showError(
                "Lo sentimos, ha ocurrido un error al editar el correo"
              );
            })
            .finally(() => {
              Globals.quitLoading();
            });
        }}
      >
        Guardar
      </Button>
    </Modal>
  );
};

export const Emails = () => {
  const history = useHistory();

  const [
    currentEmailTemplate,
    setCurrentEmailTemplate,
  ] = useState<EmailTemplate | null>(null);

  return (
    <>
      <Header />
      <Menu history={history}>
        <Table data={emailTemplates.length} title="Correos">
          {emailTemplates.map(({ type, name, hasBrackets }: EmailTemplate) => {
            return (
              <tr key={type}>
                <td className="text-left d-flex">
                  <div className="flex-fill">{name}</div>
                  <Action
                    title="Editar"
                    icon="edit"
                    onClick={() =>
                      setCurrentEmailTemplate({ type, name, hasBrackets })
                    }
                  />
                </td>
              </tr>
            );
          })}
        </Table>
      </Menu>
      <EditEmail
        template={currentEmailTemplate}
        onDismiss={() => setCurrentEmailTemplate(null)}
      />
    </>
  );
};
