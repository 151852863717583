import React from 'react';
import { Input, Textarea, Button, Select, DatePicker } from 'components';
import moment from 'moment';
import { PatientDataService } from 'services';
import { Globals } from 'utils';

interface PatientDataProps {
	item: any,
	view?: boolean,
	document_types: any,
	updatePatient: (data: any) => void,
	onClose: () => void
}

class PatientData extends React.Component<PatientDataProps> {

	state = {
		form: {
			id: this.props.item?.id,
			birthdate: '',
			document_type_id: '',
			document: '',
			gender: '',
			marital_status: '',
			occupation: '',
			residence: '',
			responsable: '',
			responsable_relationship: '',
			responsable_phone: '',
			companion: '',
			companion_phone: '',
			insurance: '',
			type_union: '',
			city: '',
			previous_procedures: '',
			medicines: '',
			diseases: '',
			allergies: ''
		}
	}

	componentDidMount() {
		if (this.props.item && this.props.item.data) {
			const item: any = { ...this.props.item.data };
			this.setState({
				form: {
					...this.state.form,
					birthdate: item.birthdate ? moment(item.birthdate).toDate() : '',
					document_type_id: item.document_type_id || '',
					document: item.document || '',
					gender: item.gender || '',
					marital_status: item.marital_status || '',
					occupation: item.occupation || '',
					residence: item.residence || '',
					responsable: item.responsable || '',
					responsable_relationship: item.responsable_relationship || '',
					responsable_phone: item.responsable_phone || '',
					companion: item.companion || '',
					companion_phone: item.companion_phone || '',
					insurance: item.insurance || '',
					type_union: item.type_union || '',
					city: item.city || '',
					previous_procedures: item.previous_procedures || '',
					medicines: item.medicines || '',
					diseases: item.diseases || '',
					allergies: item.allergies || '',
				}
			});
		}
	}

	submit = async () => {
		let data: any = { ...this.state.form };
		data.birthdate = data.birthdate ? moment(data.birthdate).format('YYYY-MM-DD') : null;
		await PatientDataService.save(data);
		Globals.showSuccess("Se ha guardado correctamente los datos del paciente");
		this.props.onClose();
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}
	
	render() {
		return (
			<div className="row">
				<div className="col-md-6">
					<DatePicker
						color="gray"
						label="Fecha de Nacimiento"
						maxDate={ moment().subtract(18, 'years').toDate() }
						disabled={ this.props.view }
						onChange={ (text: string) => {
							this.setState({
								form: {
									...this.state.form,
									birthdate: text
								}	
							});
						} }
						value={ this.state.form.birthdate }
					/>
				</div>
				<div className="col-md-6">
					<Input
	                    color="gray"
	                    value={ this.state.form.city }
	                    name="city" 
	                    label="Ciudad"
	                    disabled={ this.props.view }
	                    onChange={ this.change } />
				</div>
				<div className="col-md-6">
					<Select
						color="gray"
						name="gender"
						label="Género"
						onChange={ this.change }
						value={ this.state.form.gender }
						disabled={ this.props.view }
						options={ [
							{ value: 'M', label: 'M' },
							{ value: 'F', label: 'F' }
						] } />
				</div>
				<div className="col-md-6">
					<Select
						color="gray"
						name="document_type_id"
						label="Tipo de Documento"
						onChange={ this.change }
						value={ this.state.form.document_type_id }
						disabled={ this.props.view }
						options={ this.props.document_types.map((i: any) => {
							return {
								value: i.id,
								label: i.name
							}
						}) } />
				</div>
				<div className="col-md-6">
					<Input
	                	type="number"
	                    color="gray"
	                    value={ this.state.form.document }
	                    name="document" 
	                    label="Documento de Identidad"
	                    disabled={ this.props.view }
	                    onChange={ this.change } />
				</div>
				<div className="col-md-6">
					<Input
	                    color="gray"
	                    value={ this.state.form.marital_status }
	                    name="marital_status" 
	                    label="Estado Civil"
	                    disabled={ this.props.view }
	                    onChange={ this.change } />
				</div>
				<div className="col-md-6">
					<Input
	                    color="gray"
	                    value={ this.state.form.occupation }
	                    name="occupation" 
	                    label="Ocupación"
	                    disabled={ this.props.view }
	                    onChange={ this.change } />
				</div>
				<div className="col-md-6">
					<Textarea
	                    label="Lugar de Residencia"
	                    name="residence"
	                    disabled={ this.props.view }
	                    value={ this.state.form.residence }
	                    onChange={ this.change }
	                />
				</div>
				<div className="col-md-6">
					<Input
	                    color="gray"
	                    value={ this.state.form.responsable }
	                    name="responsable" 
	                    label="Responsable"
	                    disabled={ this.props.view }
	                    onChange={ this.change } />
				</div>
				<div className="col-md-6">
					<Input
	                    color="gray"
	                    value={ this.state.form.responsable_relationship }
	                    name="responsable_relationship" 
	                    label="Parentesco del Responsable"
	                    disabled={ this.props.view }
	                    onChange={ this.change } />
				</div>
				<div className="col-md-6">
					<Input
						type="number"
	                    color="gray"
	                    value={ this.state.form.responsable_phone }
	                    name="responsable_phone" 
	                    label="Teléfono del Responsable"
	                    disabled={ this.props.view }
	                    onChange={ this.change } />
				</div>
				<div className="col-md-6">
					<Input
	                    color="gray"
	                    value={ this.state.form.companion }
	                    name="companion" 
	                    label="Acompañante"
	                    disabled={ this.props.view }
	                    onChange={ this.change } />
				</div>
				<div className="col-md-6">
					<Input
						type="number"
	                    color="gray"
	                    value={ this.state.form.companion_phone }
	                    name="companion_phone"
	                    label="Teléfono del Acompañante"
	                    disabled={ this.props.view }
	                    onChange={ this.change } />
				</div>
				<div className="col-md-6">
					<Input
	                    color="gray"
	                    value={ this.state.form.insurance }
	                    name="insurance" 
	                    label="Aseguradora"
	                    disabled={ this.props.view }
	                    onChange={ this.change } />
				</div>
				<div className="col-md-6">
					<Input
	                    color="gray"
	                    value={ this.state.form.type_union }
	                    name="type_union" 
	                    label="Tipo de Vinculación"
	                    disabled={ this.props.view }
	                    onChange={ this.change } />
				</div>
				<div className="col-md-6">
					<Textarea
	                    label="Procedimientos Quirúrgicos Anteriores"
	                    name="previous_procedures"
	                    disabled={ this.props.view }
	                    value={ this.state.form.previous_procedures }
	                    onChange={ this.change }
	                />
				</div>
				<div className="col-md-6">
					<Textarea
	                    label="Enfermedades"
	                    name="diseases"
	                    disabled={ this.props.view }
	                    value={ this.state.form.diseases }
	                    onChange={ this.change }
	                />
				</div>
				<div className="col-md-6">
					<Textarea
	                    label="Medicamentos"
	                    name="medicines"
	                    disabled={ this.props.view }
	                    value={ this.state.form.medicines }
	                    onChange={ this.change }
	                />
				</div>
				<div className="col-md-6">
					<Textarea
	                    label="Alergias"
	                    name="allergies"
	                    disabled={ this.props.view }
	                    value={ this.state.form.allergies }
	                    onChange={ this.change }
	                />
				</div>
				<div className="col-md-12">
					{ !this.props.view && <Button block="true" type="button" onClick={ this.submit } >
	                    Guardar
	                </Button> }
				</div>
			</div>
		)
	}
}

export default PatientData;