import React from 'react';
import { Button, Select, Input, Textarea, AddAvatar, DatePicker } from 'components';
import { LoginService } from 'services';
import { Globals } from 'utils';
import moment from 'moment';

interface RegisterProps {
	back: (res?:any) => void,
	countries: Array<any>
}

class Register extends React.Component<RegisterProps> {

	state = {
		countries: this.props.countries,
		form: {
			name: '',
			lastname: '',
			country_id: '',
			gender: '',
			birthdate: '',
			phone: '',
			// address: '',
			email: '',
			password: '',
			password_confirmation: '',
			profile_picture: {
				file: null,
				preview: null
			},
			// previous_procedures: '',
			// diseases: '',
			// medicines: '',
			// allergies: ''
		}
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	back = () => {
		this.props.back();
	}

	submit = async (e: any) => {
		e.preventDefault();
		const data: any = { 
			...this.state.form,
			hasFile: !!this.state.form.profile_picture.file,
			file: this.state.form.profile_picture.file
		}
		const res:any = await LoginService.register(data);
		this.setState({
			form: {
				name: '',
				country_id: '',
				phone: '',
				address: '',
				email: '',
				password: '',
				password_confirmation: '',
				profile_picture: {
					file: null,
					preview: null
				}
			}
		});
		// Globals.showSuccess("Se ha registrado correctamente");
		this.props.back(res);
	}
		
	render() {
		return (
			<React.Fragment>
				<div className="register-form">
					<form onSubmit={ this.submit }>
						<div className="register-form-header">
							<AddAvatar 
								name="profile_picture" 
								onChange={ this.change }
								value={ this.state.form.profile_picture.preview} 
								view={true} 
							/>
							<div className="register-form-title">Registro</div>
						</div>
						<Input
							color="gray"
							value={ this.state.form.name }
							name="name" 
							placeholder="Nombre"
							onChange={ this.change } />
						<Input
							color="gray"
							value={ this.state.form.lastname }
							name="lastname" 
							placeholder="Apellido"
							onChange={ this.change } />
						<Input
							color="gray"
							value={ this.state.form.email }
							name="email" 
							placeholder="Correo electrónico"
							onChange={ this.change } />
						<Select
							color="gray"
							name="country_id"
							placeholder="País"
							onChange={ this.change }
							value={ this.state.form.country_id }
							options={ this.state.countries.map((i: any) => {
								return {
									value: i.id,
									label: i.name
								}
							}) } />
						
						{/* <Select
							color="gray"
							name="gender"
							placeholder="Genero"
							onChange={ this.change }
							value={ this.state.form.gender }
							options={ [{
								value: 'M',
								label: 'M'
							},{
								value: 'F',
								label: 'F'
							}] } />

						<DatePicker
							showYearDropdown={true}
							maxDate={ moment().subtract(16, 'years').toDate() }
							textDefault="Fecha de Nacimiento"
							onChange={ (text: string) => {
								this.setState({
									form: {
										...this.state.form,
										birthdate: text
									}										
								});
							} }
							value={ this.state.form.birthdate }
						/> */}

						<Input
							color="gray"
							type="number"
							value={ this.state.form.phone }
							name="phone" 
							placeholder="Teléfono"
							onChange={ this.change } />
						
						{/* <Textarea
							rows="3"
							placeholder="Dirección"
							name="address"
							value={ this.state.form.address }
							onChange={ this.change }
						/> */}
						<Input
							color="gray"
							type="password"
							value={ this.state.form.password }
							name="password" 
							placeholder="Contraseña"
							onChange={ this.change } />
						<Input
							color="gray"
							type="password"
							value={ this.state.form.password_confirmation }
							name="password_confirmation" 
							placeholder="Repetir Contraseña"
							onChange={ this.change } />
						{/* <div className="register-form-title">Información médica</div>
						<hr style={{backgroundColor: 'white'}}/> */}
						
						{/* <Textarea
							rows="2"
							placeholder="Procedimientos Quirúrgicos Anteriores"
							name="previous_procedures"
							value={ this.state.form.previous_procedures }
							onChange={ this.change }
						/>	

						
						<Textarea
							rows="2"
							placeholder="¿Padece de alguna enfermedad?"
							name="diseases"
							value={ this.state.form.diseases }
							onChange={ this.change }
						/>

						<Textarea
							rows="2"
							placeholder="¿Toma algún medicamento?"
							name="medicines"
							value={ this.state.form.medicines }
							onChange={ this.change }
						/>

						<Textarea
							rows="2"
							placeholder="¿Es alérgico a algún medicamento?"
							name="allergies"
							value={ this.state.form.allergies }
							onChange={ this.change }
						/> */}
						<div className="register-form-buttons">
							<Button type="button" className="register-form-buttons-back" onClick={ this.back }>
								Volver
							</Button>
							<Button type="submit" className="register-form-buttons-submit">
								Registrar
							</Button>
						</div>
					</form>
				</div>
			</React.Fragment>
		)
	}
}

export default Register;