import React from 'react';
import Badge from './badge';

interface TabsItem {
	value: number,
	label: string,
	badge?: number
}

interface TabsProps {
	onChange: (i: number) => void,
	selected: number,
	items: TabsItem[]
}

const Tabs = (props: TabsProps) => (
	<div className="container-tabs">
		{
			props.items.map((i: TabsItem, index: number) => (
				<div
					key={index}
					onClick={ () => props.onChange(i.value) }
					className={ `tab-item ${ props.selected == i.value ? 'tab-selected' : '' }` }>
					{ i.label }
					<Badge value={ i.badge } />
				</div>
			))
		}
	</div>
)

export default Tabs;