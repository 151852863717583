import HoldOn from 'react-hold-on';
import Swal from 'sweetalert2';
import moment from 'moment';
import $ from 'jquery';

class Globals {

	setLoading = () => {
		HoldOn.open({
		     theme: "sk-bounce"
		});
	}

	quitLoading = () => {
		HoldOn.close();
	}

	formatMiles = (n: any, decimals: boolean = true, currency: string = '') => {
		var c: any = isNaN(c = Math.abs(c)) ? 2 : c,
			d: any = d == undefined ? "," : d,
			t: any = t == undefined ? "." : t,
			s: any = n < 0 ? "-" : "",
			i: any = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
			j: any = (j = i.length) > 3 ? j % 3 : 0;

		return currency + ' ' + s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
	}

	hideTooltip = () => {
		$('button').blur();
    }

	showSuccess = (message: string) => {
		Swal.fire({
            title: "",
            text: message,
            type: "success",
			showCancelButton: false,
			customClass: {
				popup: 'swal-custom-popup',
				header: 'swal-custom-color-icons',
				actions: 'swal-custom-actions',
				confirmButton: 'swal-custom-confirm-button',
				cancelButton: 'swal-custom-cancel-button'
			}
        });
	}

	showError = (message?: string) => {
		Swal.fire({
            title: "",
            text: message ? message : "Se ha producido un error",
            type: "error",
			showCancelButton: false,
			customClass: {
				popup: 'swal-custom-popup',
				header: 'swal-custom-color-icons',
				actions: 'swal-custom-actions',
				confirmButton: 'swal-custom-confirm-button',
				cancelButton: 'swal-custom-cancel-button'
			}
        });
	}

	confirm = (message: string,callback: any) => {
		this.hideTooltip();
		Swal.fire({
            title: "",
            text: message,
            type: "warning",
			showCancelButton: true,
            confirmButtonText: 'Aceptar',
			cancelButtonText: 'Cancelar',
			customClass: {
				popup: 'swal-custom-popup',
				header: 'swal-custom-color-icons',
				actions: 'swal-custom-actions',
				confirmButton: 'swal-custom-confirm-button',
				cancelButton: 'swal-custom-cancel-button'
			}
        }).then(confirm => {
        	if (confirm.value) {
        		callback();
        	}
        });
	}

	getDate = (date: string, to: string = 'DD/MM/YYYY', from: string = 'YYYY-MM-DD HH:mm:ss') => {
		return moment(date,from).format(to);
	}

	capitalize = (text: string) => {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}

	range = (start: string, end: string, interval: number) => {
	    var s = start.split(':').map((e: any) => +e);
	    var e = end.split(':').map((e: any) => +e);
	    var res = [];
	    var t = [];
	    while (!(s[0] == e[0] && s[1] > e[1])) {
	        t.push(s[0] + ':' + (s[1] < 10 ? '0' +s[1] : s[1]));
	        s[1] += interval;
	        if (s[1] > 59) {
	            s[0] += 1;
	            s[1] %= 60;
	        }
	    }
	    res.push(moment(start,'HH:mm:ss').format('HH:mm'));
	    for (var i = 0; i < t.length - 1; i++) {
	        res.push(t[i + 1]);
	    }
	    return res;
	}

	isSocial = (user: any) => {
		return user.google == 1 || user.facebook == 1;
	}

	downloadFile = (url: string, name: string) => {
		var a = $("<a>")
		    .attr("href", url)
		    .attr("download", name)
		    .appendTo("body");
		a[0].click();
		a.remove();
	}

	isColombia = (user: any) => {
		return true;
		// return user.person.country_id == 52; // El 52 es el ID de Colombia en la tabla Countries
	}

	urlify(text: string) {
	  var urlRegex = /(https?:\/\/[^\s]+)/g;
	  return text.replace(urlRegex, function(url) {
	    return '<a target="_blank" href="' + url + '">' + url + '</a>';
	  })
	}
}

export default new Globals();