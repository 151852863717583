import React from 'react';
import Menu from 'screens/menu';
import { ScheduleService } from 'services';
import { Input, Checkbox, Table, Select, Button, TimePicker } from 'components';
import moment from 'moment';
import { Globals, Constants, Socket } from 'utils';
import Header from 'components/header';

class Schedule extends React.Component<any> {

	state: any = {
		schedule: [],
		header: [
			'Nombre',
			'Desde',
			'Hasta',
			'Cantidad',
			'Abierto'			
		],
		loading: true,
		form: {
			type: 0
		},
		types: [
			{ value: 0, label: 'Todos' },
			{ value: Constants.SHIFTS.DAY, label: 'Diurno' },
			{ value: Constants.SHIFTS.LATE, label: 'Tarde' }
		],
		ranges: []
	}
	
	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await ScheduleService.get();
		res.ranges.day = Globals.range(res.ranges.day[0],res.ranges.day[1],15);
		res.ranges.late = Globals.range(res.ranges.late[0],res.ranges.late[1],15);
		this.setState({
			schedule: res.schedule,
			ranges: res.ranges,
			loading: false
		});
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	changeHour = (e: any,type: string, item: any) => {
		const schedule: any = [ ...this.state.schedule ];
		const index = schedule.findIndex((i: any) => i.id == item.id);
		schedule[index][type] = moment(e).format('HH:mm:ss');
		this.setState({
			schedule
		});
	}

	changeAmount = (e: any,type: string, item: any) => {
		const schedule: any = [ ...this.state.schedule ];
		const index = schedule.findIndex((i: any) => i.id == item.id);
		schedule[index][type] = e;
		this.setState({
			schedule
		});
	}

	changeCheck = (e: any, item: any) => {
		const schedule: any = [ ...this.state.schedule ];
		const index = schedule.findIndex((i: any) => i.id == item.id);
		schedule[index].open = schedule[index].open == 1 ? 0 : 1;
		this.setState({
			schedule
		});
	}

	submit = async () => {
		const cant: number = this.state.schedule.filter((i: any) => i.amount == '' || i.amount == null || i.amount < 0).length;
		if (cant > 0) {
			Globals.showError("Debe ingresar la cantidad de pacientes para todos los horarios");
			return false;
		}
		await ScheduleService.save({
			schedule: this.state.schedule
		});
		Globals.showSuccess("Se han guardado los cambios");
		Socket.emit(Constants.SCHEDULE.RELOAD);
	}

	render() {
		return (
			<>
			<Header />
			<Menu history={ this.props.history }>
				<div className="schedule">
					<div className="row">
						<div className="col-md-5">
							<Select
								name="type"
								label="Tipo de Horario"
								onChange={ this.change }
								value={ this.state.form.type }
								options={ this.state.types } />
						</div>
					</div>

					<Table loading={ this.state.loading } data={ this.state.schedule.length } title="Horario" header={ this.state.header } center={ [4] }>
						{
							this.state.schedule
								.filter((i: any) => this.state.form.type == 0 ? i : i.type == this.state.form.type)
								.map((i: any, index: number) => {
									const range: any = this.state.ranges[i.type == Constants.SHIFTS.DAY ? 'day' : 'late'];

									return (
										<tr>
											<td>{ Globals.capitalize(moment.weekdays(i.day)) + ' (' + i.turn + ')' }</td>
											<td>
												<TimePicker
													timeInterval={15}
													includeTimes={ range.map((i: string) => moment(i,'HH:mm').toDate()) }
													onChange={ (event: any) => this.changeHour(event,'start',i) } 
													value={ i.start } />
											</td>
											<td>
												<TimePicker
													timeInterval={15}
													includeTimes={ range.map((i: string) => moment(i,'HH:mm').toDate()) }
													onChange={ (event: any) => this.changeHour(event,'end',i) } 
													value={ i.end } />
											</td>
											<td>
												<Input
									            	type="number"
									                color="gray"
									                value={ i.amount }
									                name="amount" 
									                placeholder="Cantidad de pacientes"
									                onChange={ (event: any) => this.changeAmount(event.target.value,event.target.name,i) } />
											</td>
											<td className="text-center">
												<Checkbox
													inline={ true }
													value={ i.open }
													checked={ i.open }
													onChange={ (event: any) => this.changeCheck(event,i) } />
											</td>
										</tr>
									)									
								})
						}
					</Table>

					<div className="text-center">
						<Button type="button" onClick={ this.submit } >
		                    Guardar
		                </Button>
					</div>
				</div>
			</Menu>
			</>
		)
	}
}

export default Schedule;