import React from 'react';
import { Input, Select, Button, Checkbox, Table, Textarea } from 'components';
import { BudgetService, EvaluationService } from 'services';
import { Globals, Socket, Constants } from 'utils';
import { saveAs } from "file-saver";
// import PrintJS from "print-js";

interface BudgetsProps {
	procedures: any,
	currencies: any,
	evaluation: any,
	updateEvaluation: (item: any) => void
}

class Budgets extends React.Component<BudgetsProps> {

	state: any = {
		currencies: this.props.currencies.map((i: any) => {
			return {
				value: i.id,
				label: i.name
			}
		}),
		form: {
			amount: 0,
			currency: '',
			description: ''
		},
		checked: [],
		edit_budget: false
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	onSubmit = async (e: any, id?: number) => {
		e.preventDefault();
		let form: any = {
			amount: this.state.form.amount,
			currency_id: this.state.form.currency,
			evaluation_id: this.props.evaluation.id,
			description: this.state.form.description,
			id: id
		}
		form.procedures = [ ...this.state.checked ];
		await BudgetService.create(form);
		Globals.showSuccess("Se ha enviado el presupuesto");
		const res: any = await EvaluationService.get({
			id: this.props.evaluation.id
		});
		this.setState({
			form: {
				amount: 0,
				currency: '',
				description: ''
			},
			checked: [],
			edit_budget: false
		});
		this.props.updateEvaluation(res.evaluations);
		Socket.emit(Constants.EVALUATIONS.BUDGET,res.evaluations);
	}

	onChangeCheck = (item: number) => {
		const { checked } = this.state;
	    let _array: any = [];

	    if (checked.indexOf(item) == -1) {
	      _array = [...checked, item];
	    } else {
	      _array = checked.filter((a: any) => a !== item);
	    }
	    this.setState({ checked: _array });
	}

	edit = (budget: any) => {
		this.setState({
			edit_budget: true,
			form: {
				amount: budget.amount,
				currency: budget.currency_id,
				description: budget.description
			},
			checked: budget.procedures.map((i: any) => i.id)
		});
	}

	finish = () => {
		Globals.confirm('¿Desea finalizar la evaluación? Esta acción no se puede deshacer',async () => {
			await EvaluationService.finish({
				id: this.props.evaluation.id
			});
			Globals.showSuccess("Se ha finalizado la evaluación");
			const evaluation: any = { ...this.props.evaluation };
			evaluation.status = 0;
			this.props.updateEvaluation(evaluation);
			Socket.emit(Constants.EVALUATIONS.FINISH,evaluation);
		});
	}

	generate = async (id: number) => {
		const blob: any = await BudgetService.print({
			id
		});

		saveAs(blob.url, blob.name);

		// PrintJS({
		// 	printable: res.url,
		// 	onLoadingEnd: async () => {
		// 		await BudgetService.deletePrint({
		// 			name: res.name,
		// 			withoutLoading: true
		// 		});
		// 	}
		// });
	}
	
	render() {
		const budget = this.props.evaluation && this.props.evaluation.budgets.length > 0 ? this.props.evaluation.budgets[0] : null;

		return (
			<React.Fragment>
				{
					budget != null && !this.state.edit_budget ? (
						<div className="container-budget">
							<Table title="Presupuesto" data={ 1 }>
								<tr>
									<th>Monto</th>
									<td>{ Globals.formatMiles(budget.amount,true,budget.currency.code) }</td>
								</tr>
								{/* <tr>
									<th>Procedimientos</th>
									<td>
										<ul>
											{
												budget.procedures.map((i: any,index: number) => (
													<li>{ i.name }</li>
												))
											}
										</ul>
									</td>
								</tr> */}
								{ this.props.evaluation && this.props.evaluation.status == 1 && (
									<tr>
										<th>Acciones</th>
										<td>
											<p className="edit-budget" onClick={ () => this.edit(budget) }>Actualizar presupuesto</p>
										</td>
									</tr>
								) }
							</Table>

							<div className="row">
								{ this.props.evaluation && this.props.evaluation.status == 1 && (
									<div className="text-right col-md-6">
										<Button type="button" onClick={ this.finish }>
											Finalizar Evaluación
										</Button>
									</div>
								) }
								<div className={ `${this.props.evaluation && this.props.evaluation.status == 1 ? 'col-md-6' : 'col-md-12 text-center' }` }>
									<Button type="button" onClick={ () => this.generate(budget.id) }>
										Generar Formato
									</Button>
								</div>
							</div>
						</div>
					) : (
						<form onSubmit={ (event: any) => this.onSubmit(event,budget ? budget.id : null) }>
							<div className="row">
								<div className="col-md-6">
									<Input
										type="decimal"
										label="Presupuesto"
										color="gray"
										name="amount" 
										maxlength="14"
										placeholder="Monto (Ejemplo: 1000.00)"
										onChange={ this.change }
										value={ this.state.form.amount } />
								</div>
								<div className="col-md-6">
									<Select
										color="gray"
										name="currency"
										label="Moneda"
										onChange={ this.change }
										value={ this.state.form.currency }
										options={ this.state.currencies } />
								</div>
								<div className="col-md-12">
									<Textarea
										label="Descripción"
										name="description"
										value={ this.state.form.description }
										onChange={ this.change } />
								</div>
								{/* <div className="col-md-12">
									<h2 className="h2-title-carousel h2-margin-bottom">Seleccione los procedimientos a presupuestar</h2>
								</div>
								<div className="col-md-12 row">
									{
										this.props.procedures.map((i: any) => (
											<div className="col-md-4">
												<Checkbox
													inline={ true }
													value={ i.id }
													checked={ this.state.checked.indexOf(i.id) != -1 }
													onChange={ () => this.onChangeCheck(i.id) }
													label={ i.name } />
											</div>
										))
									}
								</div> */}
								<div className="col-md-12 text-center">
									<Button type="submit">
										Enviar presupuesto
									</Button>
								</div>
							</div>
						</form>
					)
				}
				
			</React.Fragment>	
		)
	}
}

export default Budgets;