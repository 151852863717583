import React from 'react';
import { Input, Textarea, Button, Avatar } from 'components';
import { PaymentCodesService } from 'services';
import { Socket, Constants, ENV, Globals } from 'utils';

class CreateEditPaymentCode extends React.Component<any> {

    state: any = {
        form: {
            code: '',
            user: '',
            id: null,
        },
        users: [],
        suggestions: [],
    }

    componentDidMount() {
        const item: any = this.props.item;
        if (item) {
            this.setState({
                form: {
                    ...this.state.form,
                    code: item.code,
                    user: item.user,
                    id: item.id
                },
                image: item.icon ? (ENV.BasePublic + item.icon) : null
            });
        }
        const users: any = this.props.users;
        if (users) {
            this.setState({
                users: users
            });
        }
    }

    AutoCompleteClients = (search_user: any) => {
        const { users } = this.props;
        const { form } = this.state;

        let isnum = /^[0-9]+$/;

        if (!search_user) {
            this.setState({ suggestions: [] });
            return;
        }

        if (!search_user.match(isnum)) { //Si la busqueda es por nombre
            let searchLowerCase = search_user.toLowerCase();

            const filterUsers = users.filter((Item: any) => {
                let nameLowerCase = Item.person.first_name.toLowerCase();
                return (
                    nameLowerCase.indexOf(searchLowerCase) !== -1 //&&
                );
            });

            this.setState({ suggestions: filterUsers });
        }
        else if (search_user.match(isnum)) { //Si la busqueda es por cedula o rif
            return;
            // let searchLowerCase = search_user.toLowerCase();

            // const filterUsers = users.filter((Item: any)  => {
            //     if (Item.person.identity_document != null) { //Si es por cedula
            //         let identityLowerCase = Item.person.identity_document.toLowerCase();
            //         return (
            //             identityLowerCase.indexOf(searchLowerCase) !== -1
            //         );
            //     }
            //     else if (Item.person.fiscal_identification != null) { //Si es por rif
            //         let fiscalLowerCase = Item.person.fiscal_identification.toLowerCase();
            //         return (
            //             fiscalLowerCase.indexOf(searchLowerCase) !== -1
            //         );
            //     }

            // });

            // this.setState({ suggestions: filterUsers });

        }

    };

    change = (e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    submit = async () => {
        let data: any = { ...this.state.form };
        const res: any = await PaymentCodesService[this.props.item ? 'edit' : 'create'](data);
        if(res.code){
            Globals.showSuccess(res.msg)
            this.props.onClose(res.code);
        }
        
    }

    render() {
        return (

            <div className="modal-create-edit">

                {this.state.form.id !== null
                    ?
                    <React.Fragment>
                        <Input
                            color="gray"
                            value={this.state.form.code}
                            name="code"
                            label="Código"
                            disabled={this.props.view}
                            onChange={this.change}
                        />
                        <Input
                            color="gray"
                            value={this.state.form.user.person ? this.state.form.user.person.name != '' ? this.state.form.user.person.name + ' '+ this.state.form.user.person.lastname : this.state.form.user.email : this.state.form.user.email }
                            name="user"
                            label={'Usuario ID: ' +  this.state.form.id}
                            disabled={this.props.view}
                            onChange={this.change} />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Input
                            color="gray"
                            value={this.state.form.user}
                            name="user"
                            label="Ingrese el ID del usuario"
                            disabled={this.props.view}
                            onChange={this.change}
                        />
                    </React.Fragment>
                }

                {!this.props.view && <Button block="true" type="button" onClick={this.submit} >
                    Generar código
                </Button>}
            </div>
        )
    }
}

export default CreateEditPaymentCode;