import { Api } from 'utils';

class LoginService {
	
	static auth = async (params?: any) => Api.createResponse('auth/login',params);
	static register = async (params?: any) => Api.createResponse('auth/register',params);
	static reset = async (params?: any) => Api.createResponse('auth/reset',params);
	static loginGoogle = async (params?: any) => Api.createResponse('auth/login/google',params);
	static loginFacebook = async (params?: any) => Api.createResponse('auth/login/facebook',params);
	static registerSocial = async (params?: any) => Api.createResponse('auth/register/social',params);
	static checkUser = async (params?: any) => Api.createResponse('auth/check-user',params);
}

export default LoginService;