import { Api } from 'utils';

class TreatmentService {
	
	static get = async (params?: any) => Api.createResponse('treatments/get',params);
	static create = async (params?: any) => Api.createResponse('treatments/create',params);
	static edit = async (params?: any) => Api.createResponse('treatments/edit',params);
	static delete = async (params?: any) => Api.createResponse('treatments/delete',params);
}

export default TreatmentService;