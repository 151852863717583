import React from 'react';
import _Viewer from 'react-viewer';

const Viewer = (props: any) => (
	<_Viewer
      visible={ props.visible }
      onClose={ props.onClose }
      drag={ false }
      attribute={ false }
      noNavbar={ true }
      disableMouseZoom={ true }
      zoomSpeed={ 0.4 }
      changeable={ false }
      images={ props.images } />
)

export default Viewer;