import React from 'react';
import addSvg from 'assets/icons/add.svg'

const ButtonAdd = (props: any) => {
    return (
        <button onClick={props.onClick} className="btn btn-add">
            <img src={addSvg} alt="add" width="35" height="35"/>
        </button>
    )
}

export default ButtonAdd;