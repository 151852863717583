import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Constants } from 'utils';

const RouteAuth = (props: any) => {

	let data;

	if (!props.user) {
		data = <Redirect to="/login" />
	}
	else {
		data = <Route { ...props } />
		if (props.user.level === Constants.LEVELS.ADMIN) {
			let permissions: any = [];
			if (props.user.permissions) {
				permissions = [ ...props.user.permissions ];
			}
			let _path = permissions.find((i: any) => i.path === props.path);
			if (!_path && !props.public) 
				data = <Redirect to={ permissions.length > 0 ? permissions[0].path : '/profile' } />
		}

		// Debe existir el prop "admin" o "userPage" para filtrar el acceso a un componente
		if ((props.admin && props.user.level == Constants.LEVELS.USER) || props.userPage && props.user.level != Constants.LEVELS.USER) {
			data = <Redirect to="profile" />
		}
	}
	
	return data;
}

export default connect((state: any) => {
	return {
		user: state.user
	}
})(RouteAuth);