import React from 'react';
import { Input, Textarea, Button, Select, Tabs } from 'components';
import { PatientService } from 'services';
import PatientData from './patient-data';

interface CreateEditPatientsProps {
	countries: any,
	item: any,
	view?: boolean,
	onClose: () => void,
	document_types: any,
	updatePatient: (data: any) => void
}

class CreateEditPatients extends React.Component<CreateEditPatientsProps> {

	state = {
		form: {
			email: '',
			name: '',
			lastname: '',
			address: '',
			country_id: '',
			phone: '',
			password: '',
			password_confirmation: ''
		},
		countries: this.props.countries.slice(1),
		selected: 1
	}

	componentDidMount() {
		const item: any = this.props.item;

		if (item) {
			this.setState({
				form: {
					id: item.id,
					email: item.email,
					name: item.person.name,
					lastname: item.person.lastname,
					address: item.person.address,
					country_id: item.person.country_id,
					phone: item.person.phone,
				}
			});
		}
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	submit = async () => {
		await PatientService[this.props.item ? 'edit' : 'create'](this.state.form);
		this.props.onClose();
	}

	render() {
		const selected: number = this.state.selected;

		return (
			<div className="modal-create-edit">
				{ this.props.item && (
					<Tabs
				    	onChange={ (i: number) => {
				    		this.setState({
								selected: i
				    		});
				    	} }
				    	selected={ selected }
				    	items={ [
							{ value: 1, label: 'Datos del Paciente' },
							{ value: 2, label: 'Datos Adicionales' },
					    ] } />
				) }

				{
					selected == 1 && (
						<React.Fragment>
							<Input
			                    color="gray"
			                    value={ this.state.form.name }
			                    name="name" 
			                    label="Nombre"
			                    disabled={ this.props.view }
			                    onChange={ this.change } />
			                <Input
			                    color="gray"
			                    value={ this.state.form.lastname }
			                    name="lastname" 
			                    label="Apellido"
			                    disabled={ this.props.view }
			                    onChange={ this.change } />
			                <Input
			                    color="gray"
			                    value={ this.state.form.email }
			                    name="email" 
			                    label="Correo electrónico"
			                    disabled={ this.props.view }
			                    onChange={ this.change } />
			                <Input
			                	type="number"
			                    color="gray"
			                    value={ this.state.form.phone }
			                    name="phone" 
			                    label="Teléfono"
			                    disabled={ this.props.view }
			                    onChange={ this.change } />
			                <Select
								color="gray"
								name="country_id"
								label="País"
								onChange={ this.change }
								disabled={ this.props.view }
								value={ this.state.form.country_id }
								options={ this.state.countries.map((i: any) => {
									return {
										value: i.id,
										label: i.name
									}
								}) } />
			                <Textarea
			                    label="Dirección"
			                    name="address"
			                    disabled={ this.props.view }
			                    value={ this.state.form.address }
			                    onChange={ this.change }
			                />
			                { !this.props.item && (
								<React.Fragment>
									<Input
					                	type="password"
					                    color="gray"
					                    value={ this.state.form.password }
					                    name="password" 
					                    label="Contraseña"
					                    onChange={ this.change } />
					                <Input
					                	type="password"
					                    color="gray"
					                    value={ this.state.form.password_confirmation }
					                    name="password_confirmation" 
					                    label="Confirmar contraseña"
					                    onChange={ this.change } />
								</React.Fragment>
							) }
			                { !this.props.view && <Button block="true" type="button" onClick={ this.submit } >
			                    Guardar
			                </Button> }
						</React.Fragment>
					)
				}

				{
					selected == 2 && <PatientData 
						onClose={ this.props.onClose }
						updatePatient={ this.props.updatePatient }
						document_types={ this.props.document_types }
						item={ this.props.item }
						view={ this.props.view } />
				}
			</div>
		)
	}
}

export default CreateEditPatients;