import React from 'react';
import Menu from 'screens/menu';
import { Pagination, Table, Input, Select, Button, Icon, Modal, DatePicker, Action } from 'components';
import { AppointmentService } from 'services';
import moment from 'moment';
import { Constants, Globals, Colors, Socket } from 'utils';
import ViewAppointment from './view-appointment';
import { on } from 'jetemit';
import Header from 'components/header';

class Appointments extends React.Component<any> {

	private modal: any;

	state: any = {
		data: [],
		page: 1,
		last_page: 1,
		item: null,
		view: false,
		loading: true,
		form: {
			status: -1,
			search: '',
			since: '',
			until: ''
		},
		header: [
			'Paciente',
			'Fecha',
			'Estatus',
			'Acciones'
		],
		status: [
			{ value: -1, label: 'Todos' },
			{ value: 0, label: 'Pendiente' },
			{ value: 1, label: 'Confirmado' },
			{ value: 2, label: 'Finalizada' },
			{ value: 3, label: 'Cancelado' },
			{ value: 4, label: 'Caducada' },
		],
		current_date: null,
		subscriberFinish: () => null,
		subscriberCancel: () => null,
		subscriberConfirm: () => null
	}

	componentDidMount() {
		this.load();

		this.setState({
			subscriberCancel: on(Constants.APPOINTMENTS_ACTIONS.CANCEL,(_data: any) => {
				let data: any = [ ...this.state.data ];
				const index: number = this.state.data.findIndex((i: any) => i.id == _data.id);
				if (index != -1) {
					data[index].status = Constants.APPOINTMENTS.CANCELED;
					data[index].status_description = Constants.APPOINTMENTS_DESCRIPTION.CANCELED;
					this.setState({
						data
					});
				}
			}),
			subscriberFinish: on(Constants.APPOINTMENTS_ACTIONS.FINISH,(_data: any) => {
				let data: any = [ ...this.state.data ];
				const index: number = this.state.data.findIndex((i: any) => i.id == _data.id);
				if (index != -1) {
					data[index].status = Constants.APPOINTMENTS.DONE;
					data[index].status_description = Constants.APPOINTMENTS_DESCRIPTION.DONE;
					this.setState({
						data
					});
				}
			}),
			subscriberConfirm: on(Constants.APPOINTMENTS_ACTIONS.CONFIRM,(_data: any) => {
				let data: any = [ ...this.state.data ];
				const index: number = this.state.data.findIndex((i: any) => i.id == _data.id);
				if (index != -1) {
					data[index].status = Constants.APPOINTMENTS.CONFIRMED;
					data[index].status_description = Constants.APPOINTMENTS_DESCRIPTION.CONFIRMED;
					this.setState({
						data
					});
				}
			})
		});
	}

	componentWillUnmount() {
		this.state.subscriberConfirm();
		this.state.subscriberFinish();
		this.state.subscriberCancel();
	}

	load = async () => {
		if (moment(this.state.form.since) > moment(this.state.form.until)) {
			Globals.showError("Lo sentimos, las fechas no son válidas");
			return false;
		}
		const res: any = await AppointmentService.get({
			page: this.state.page, 
			...this.state.form
		});
		this.setState({
			data: res.appointments.data,
			last_page: res.appointments.last_page,
			current_date: res.current_date,
			loading: false
		});
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	view = (item: any) => {
		this.setState({
			view: true,
			item
		});
	}

	changeStatus = (appointment: any,status: number,action: string,confirm: string) => {
		Globals.confirm(`¿Desea ${ confirm } la cita?`,async () => {
			const res: any = await AppointmentService.changeStatus({
				id: appointment.id,
				status
			});
			Globals.showSuccess(`Se ha ${ action } la cita correctamente`);
			const data: any = [ ...this.state.data ];
			this.setState({
				data: data.map((i: any) => i.id == appointment.id ? res.appointment : i)
			});

			switch(status) {
				case Constants.APPOINTMENTS.CANCELED:
					Socket.emit(Constants.APPOINTMENTS_ACTIONS.CANCEL,{
						id: appointment.id
					});
				break;

				case Constants.APPOINTMENTS.CONFIRMED:
					Socket.emit(Constants.APPOINTMENTS_ACTIONS.CONFIRM,{
						id: appointment.id
					});
				break;
			}
		});
	}

	getColor = (item: any) => {
		if (this.isTimedOut(item)) {
			return Colors.black;
		}
		switch(item.status) {
			case Constants.APPOINTMENTS.PENDING:
				return Colors.orange;
			break;

			case Constants.APPOINTMENTS.CONFIRMED:
				return Colors.blue;
			break;

			case Constants.APPOINTMENTS.DONE:
				return Colors.green;
			break;

			case Constants.APPOINTMENTS.CANCELED:
				return Colors.danger;
			break;
		}
	}

	getStatus = (item: any) => {
		if (this.isTimedOut(item)) {
			return "Caducada";
		}
		else {
			return item.status_description;
		}
	}

	updateAppointment = (appointment: any) => {
		const appointments: any = [ ...this.state.data ];
		this.setState({
			data: this.state.data.map((i: any) => i.id == appointment.id ? appointment : i),
			item: appointment
		});
	}

	isTimedOut = (item: any) => {
		const { PENDING } = Constants.APPOINTMENTS;
		return moment(item.date) < moment(this.state.current_date) && [PENDING].indexOf(item.status) != -1;
	}

	render() {
		return (
			<>
				<Header />
				<Menu history={ this.props.history }>
				<div id="appointments">
					<Modal
						ref={ ref => this.modal = ref }
						className="modal-lg"
			            title="Ver Cita"
			            visible={ this.state.item }
			            onClose={ () => {
			              this.setState({
			                view: false,
			                item: null
			              });
			            } } 
			          >
						<ViewAppointment
							getStatus={ this.getStatus }
							isTimedOut={ this.isTimedOut }
							modal={ this.modal }
							updateAppointment={ this.updateAppointment }
							appointment={ this.state.item } />
			        </Modal>

					<form className="row form-filter" onSubmit={ async (e: any) => {
						e.preventDefault();
						await this.setState({
							page: 1
						});
						this.load();
					} }>
						<div className="col-md-3">
							<Input
								name="search" 
								label="Búsqueda"
								onChange={ this.change } 
								value={ this.state.form.search }
								placeholder="Buscar por Paciente" />
						</div>
						<div className="col-md-3">
							<Select
								name="status"
								label="Estatus"
								onChange={ this.change }
								value={ this.state.form.status }
								options={ this.state.status } />
						</div>
						<div className="col-md-2">
							<DatePicker
								label="Desde"
								maxDate={ this.state.form.until ? moment(this.state.form.until).toDate() : null }
								onChange={ (text: string) => {
									this.setState({
										form: {
											...this.state.form,
											since: text
										}	
									});
								} }
								value={ this.state.form.since }
							/>
						</div>
						<div className="col-md-2">
							<DatePicker
								label="Hasta"
								minDate={ this.state.form.since ? moment(this.state.form.since).toDate() : null }
								onChange={ (text: string) => {
									this.setState({
										form: {
											...this.state.form,
											until: text
										}										
									});
								} }
								value={ this.state.form.until }
							/>
						</div>
						<div className="col-md-2">
							<Button className="btn-align-bottom text-black font-bold">
								Filtrar
							</Button>
						</div>
					</form>

					<Table loading={ this.state.loading } data={ this.state.data.length } title="Consulta presencial" header={ this.state.header }>
						{
							this.state.data.map((i: any,index: number) => {
								const { PENDING, CONFIRMED, CANCELED } = Constants.APPOINTMENTS;
								return (
									<tr>
										<td className="ellipsis">{ i.user.person.name + ' ' + i.user.person.lastname }</td>
										<td>{ moment(i.date).format('DD/MM/YYYY HH:mm') }</td>
										<td style={ {
											color: this.getColor(i)
										} }>{ this.getStatus(i) }</td>
										<td>
											{/* <Button title="Ver" small="true" onClick={ () => this.view(i) }>
												<Icon name="eye" />												
											</Button> */}
											<Action title="Ver" icon="show" onClick={ () => this.view(i) }/>
											{ i.status == PENDING && !this.isTimedOut(i) && <Button  title="Confirmar" small="true" onClick={ () => this.changeStatus(i,CONFIRMED,'confirmado','confirmar') }>
												<Icon name="check" />												
											</Button> }
					
											{/* { [PENDING,CONFIRMED].indexOf(i.status) != -1 && !this.isTimedOut(i) && <Button  title="Cancelar" small="true" onClick={ () => this.changeStatus(i,CANCELED,'cancelado','cancelar') }>
												<Icon name="trash" />												
											</Button> } */}
											{ [PENDING,CONFIRMED].indexOf(i.status) != -1 && !this.isTimedOut(i) && 
												<Action  
													title="Cancelar" 
													icon="delete" 
													onClick={ () => this.changeStatus(i,CANCELED,'cancelado','cancelar') }
												/>}
												
										</td>
									</tr>
								)
							})
						}
					</Table>

					<Pagination 
						pages={ this.state.last_page } 
						active={ this.state.page }
						onChange={ async (page: number) => {
							await this.setState({
								page: page
							});
							this.load();
					} } />
				</div>
			</Menu>
			</>
		)
	}
}

export default Appointments;