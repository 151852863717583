import React from 'react';
import { Input, Textarea, Button, Select, Checkbox } from 'components';
import { ModeratorService } from 'services';

class CreateEditModerators extends React.Component<any> {

	state = {
		form: {
			email: '',
			name: '',
			lastname: '',
			address: '',
			country_id: '',
			phone: '',
			password: '',
			password_confirmation: ''
		},
		countries: this.props.countries.slice(1),
		permissions: []
	}

	componentDidMount() {
		const item: any = this.props.item;

		let permissions: any = this.props.permissions;
		permissions.forEach((i: any) => {
			i.selected = false;

			if (item) {
				if (item.permissions.map((i: any) => i.id).indexOf(i.id) != -1) {
					i.selected = true;
				}
			}
		});
		this.setState({
			permissions
		});

		if (item) {
			this.setState({
				form: {
					id: item.id,
					email: item.email,
					name: item.person.name,
					lastname: item.person.lastname,
					address: item.person.address,
					country_id: item.person.country_id,
					phone: item.person.phone,
				}
			});
		}		
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	submit = async () => {
		let data: any = { ...this.state.form };
		data.permissions = this.state.permissions.filter((i: any) => i.selected).map((i: any) => i.id);
		await ModeratorService[this.props.item ? 'edit' : 'create'](data);
		this.props.onClose();
	}

	render() {
		return (
			<div className="modal-create-edit-moderators">
				<Input
                    color="gray"
                    value={ this.state.form.name }
                    name="name" 
                    label="Nombre"
                    disabled={ this.props.view }
                    onChange={ this.change } />
                <Input
                    color="gray"
                    value={ this.state.form.lastname }
                    name="lastname" 
                    label="Apellido"
                    disabled={ this.props.view }
                    onChange={ this.change } />
                <Input
                    color="gray"
                    value={ this.state.form.email }
                    name="email" 
                    label="Correo electrónico"
                    disabled={ this.props.view }
                    onChange={ this.change } />
                <Input
                	type="number"
                    color="gray"
                    value={ this.state.form.phone }
                    name="phone" 
                    label="Teléfono"
                    disabled={ this.props.view }
                    onChange={ this.change } />
                <Select
					color="gray"
					name="country_id"
					label="País"
					onChange={ this.change }
					disabled={ this.props.view }
					value={ this.state.form.country_id }
					options={ this.state.countries.map((i: any) => {
						return {
							value: i.id,
							label: i.name
						}
					}) } />
                <Textarea
                    label="Dirección"
                    name="address"
                    disabled={ this.props.view }
                    value={ this.state.form.address }
                    onChange={ this.change }
                />
                { !this.props.item && (
					<React.Fragment>
						<Input
		                	type="password"
		                    color="gray"
		                    value={ this.state.form.password }
		                    name="password" 
		                    label="Contraseña"
		                    onChange={ this.change } />
		                <Input
		                	type="password"
		                    color="gray"
		                    value={ this.state.form.password_confirmation }
		                    name="password_confirmation" 
		                    label="Confirmar contraseña"
		                    onChange={ this.change } />
					</React.Fragment>
				) }
				<h3>Permisos Asignados</h3>
				<div className="row">
					{
						this.state.permissions.map((i: any, index: number) => (
							<div className="col-md-6">
								<Checkbox
									inline={ true }
									value={ i.selected }
									checked={ i.selected }
									label={ i.name }
									disabled={ this.props.view }
									onChange={ () => {
										const permissions: any = [...this.state.permissions];
										permissions[index].selected = !permissions[index].selected;
										this.setState({
											permissions
										});
									} } />
							</div>
						))
					}							
				</div>
				{ !this.props.view && <Button block="true" type="button" onClick={ this.submit } >
                    Guardar
                </Button> }
			</div>
		)
	}
}

export default CreateEditModerators;