import React from 'react';

interface CheckboxProps {
	inline?: boolean,
	value: number | string,
	label: string,
	onChange: () => void
}

const Checkbox = (props: any) => (
	<div className={ `form-check form-group ${ props.inline ? 'form-check-inline' : '' }` }>
	  <input className="form-check-input" type="checkbox" { ...props } id={ `checkbox-${ props.label?.replace(' ','') }` } />
	  { props.label && <label className="form-check-label" htmlFor={ `checkbox-${ props.label?.replace(' ','') }` }> 
	    { props.label }
	  </label> }
	</div>
)

export default Checkbox;