import React from 'react';
import { Card, Input, Button, Icon, Modal } from 'components';
import { Globals, ENV } from 'utils';
import { connect } from 'react-redux';
import { LoginService, CountryService } from 'services';
import Register from './register';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Remember from './remember';
import CompleteForm from './complete-form';
import imagotipo from 'assets/img/logo/imagotipo.png'

declare let window: any;

class Login extends React.Component<any> {

	state = {
		form: {
			email: '',
			password: ''
		},
		register: false,
		visible: false,
		countries: [],
		social: '',
		visible_complete: false,
		data_social: null
	}

	componentDidMount() {
		if (this.props.user) {
			this.props.history.push('/');
		}
		this.loadCountries();
	}

	loadCountries = async () => {
		const res: any = await CountryService.get();
		this.setState({
			countries: res.countries
		});
	}

	continueLogin = (user: any) => {
		this.props.dispatch({
			type: 'SET_USER',
			payload: user
		});
		this.props.history.push('/');
	}

	submit = async (e: any) => {
		e.preventDefault();
		const res: any = await LoginService.auth(this.state.form);
		this.continueLogin(res.user);
	}

	disabled = () => {
		return this.state.form.email == '' || this.state.form.password == '';
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	register = () => {
		this.setState({
			register: true
		});
	}
		
	facebook = async (_data: any) => {
		window.FB.logout();
		if (_data.id) {
			const res: any = await LoginService.loginFacebook({
				facebook_id: _data.id,
				email: _data.email
			});
			if (!res.result && res.register)
				this.setState({
					visible_complete: true,
					social: 'facebook',
					data_social: _data
				});
			else
				this.continueLogin(res.user);
		}
	}

	google = async (_data: any) => {
		if (_data.googleId) {
			const res: any = await LoginService.loginGoogle({
				google_id: _data.googleId,
				email: _data.profileObj.email
			});
			if (!res.result && res.register)
				this.setState({
					visible_complete: true,
					social: 'google',
					data_social: _data
				});
			else
				this.continueLogin(res.user);
		}		
	}

	remember = (event: any) => {
		event.preventDefault()
		this.setState({
			visible: true
		});
	}

	onRegisterSocial = (res?: any) => {
		this.setState({
	        visible_complete: false
	    });
	    if (res) {
			this.continueLogin(res.user);
		}
	}

	onRegisterRegular = (res?: any) => {
		this.setState({
	        register: false
	    });
	    if (res) {
			this.continueLogin(res.user);
		}
	}

	render() {
		return (
			<React.Fragment>
				
				<Modal
					id="modal-password-recovery"
		            visible={ this.state.visible }
		            onClose={ () => {
		              this.setState({
		                visible: false
		              });
		            } } 
		          >
					<Remember onClose={ () => {
		              this.setState({
		                visible: false
		              });
		            } } />
		        </Modal>

		        <Modal
		            title="Completar datos"
		            visible={ this.state.visible_complete }
		            onClose={ () => {
		              this.setState({
		                visible_complete: false
		              });
		            } } 
		          >
					<CompleteForm
						data_social={ this.state.data_social }
						social={ this.state.social }
						countries={ this.state.countries }
						onClose={ this.onRegisterSocial } />
		        </Modal>

				{
					!this.state.register && (
						<div id="login" className="login">
							<BackgroundDoctor />
							<div className="login-form">
								<div className="login-form-logo">
									<img src={imagotipo} alt="logo"  className="img-fluid"/>
								</div>
								<form onSubmit={ this.submit }>
									<Input 
										color="gray"
										name="email" 
										placeholder="Correo Electrónico"
										onChange={ this.change } />
									<Input 
										color="gray"
										type="password" 
										name="password"
										placeholder="Contraseña"
										onChange={ this.change } />

									<div className="login-form-btn">
										<Button block="true" className="register-temp" type="submit" disabled={ this.disabled() }>
											Iniciar Sesión
										</Button>
									</div>

									<div className="login-form-btn">
										<Button block="true" className="text-black" type="submit" onClick={ this.register }>
											¿No tienes cuenta? Registrate
										</Button>
									</div>

									{/* <div className="login-social"> */}
										{/* <FacebookLogin
											appId={ ENV.FACEBOOK_ID }
											fields="first_name,last_name,email"
											callback={ this.facebook }
											render={ (renderProps: any) => (
											<Button className="btn-facebook" type="button" onClick={ renderProps.onClick }>
												<Icon name="facebook" />
												Entrar con Facebook
											</Button>
										) } /> */}
										{/* <GoogleLogout
											clientId={ ENV.GOOGLE_ID }
											render={ (data: any) => (
											<GoogleLogin
												clientId={ ENV.GOOGLE_ID }
												render={ (renderProps: any) => (
													<Button color="danger" className="btn-google" type="button" onClick={ () => {
													data.onClick();
													renderProps.onClick();
													} }>
													<Icon name="google" />
													Entrar con Google
													</Button>
												)}
												onSuccess={ this.google }
												onFailure={ (err: any) => {
													if(err.error) {
														Globals.showError(`Hubo un problema al conectarse con la plataforma de Google, 
														por favor, vuelva a intentarlo.`)
														return;
													}
													// console.log(err)
												} }
											/>
											) } /> */}
									{/* </div> */}

									<div className="text-center text-white login-form-remember">
										<div className="login-form-remember-line"></div>
										<button onClick={ this.remember } className="btn btn-link text-white login-form-remember-link">
											¿Olvido su contraseña?
										</button>
										<div className="login-form-remember-line"></div>
									</div>

									{/* <div className="text-center text-white mt-3 login-form-register">
										<span>¿No tienes cuenta?
										</span>
										<button onClick={ this.register } className="btn btn-link"> 
											Registrate
										</button>
									</div> */}
								</form>
							</div>
						</div>
					)
				}
				
				{
					this.state.register && (
						<div id="register" className="register">
							<BackgroundDoctor />
							<Register countries={ this.state.countries } back={ this.onRegisterRegular } />
						</div>
					)
				}
			</React.Fragment>
		)
	}
}

const BackgroundDoctor = () => (<div className="login-background"></div>)

export default connect((state: any) => {
	return {
		user: state.user
	}
})(Login);