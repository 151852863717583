import React from 'react';
import { Tabs } from 'components';
import Recipes from './recipes';
import AppointmentData from './appointment-data';
import MedicalHistory from '../patients/medical-history';
import PaymentData from './payment-data';

interface ViewAppointmentProps {
	appointment: any,
	updateAppointment: (appointment: any) => void,
	modal: any,
	isTimedOut: (item: any) => boolean,
	getStatus: (item: any) => string
}

class ViewAppointment extends React.Component<ViewAppointmentProps> {

	state = {
		selected: 1
	}

	render() {
		const selected = this.state.selected;

		return (
			<div className="view-appointment">
				
				<Tabs
			    	onChange={ (i: number) => {
			    		this.setState({
							selected: i
			    		});
			    	} }
			    	selected={ selected }
			    	items={ [
						{ value: 1, label: 'Datos' },
						{ value: 2, label: 'Historial Médico' },
						{ value: 3, label: 'Datos de Pago' },
						{ value: 4, label: 'Recetas' }
				    ] } />

				{
					// Datos de la cita
					selected == 1 && <AppointmentData
						getStatus={ this.props.getStatus }
						isTimedOut={ this.props.isTimedOut }
						updateAppointment={ this.props.updateAppointment }
						appointment={ this.props.appointment }
						patient={ this.props.appointment && this.props.appointment.user } />
				}
				
				{
					// Historial médico
					selected == 2 && <MedicalHistory
						setTitleHistory={ (title: string) => null }
						patient={ this.props.appointment?.user }
					/>
				}

				{
					// Datos de pago
					selected == 3 && <PaymentData appointment={ this.props.appointment } />
				}

				{
					// Recetas
					selected == 4 && <Recipes
						updateAppointment={ this.props.updateAppointment }
						modal={ this.props.modal }
						appointment={ this.props.appointment } />
				}
			</div>
		)
	}
}

export default ViewAppointment;