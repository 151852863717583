import React from 'react';
import { Input, Button, Textarea, Table, Icon, Pagination, DatePicker, AddFiles, Viewer } from 'components';
import { MedicalHistoryService } from 'services';
import { Globals, ENV } from 'utils';
import moment from 'moment';

interface MedicalHistoryProps {
	patient: any,
	onClose?: () => void,
	setTitleHistory: (title: string) => void
}

class MedicalHistory extends React.Component<MedicalHistoryProps> {

	state: any = {
		history: [],
		page: 1,
		last_page: 1,
		create: false,
		header: [
			"Título",
			"Fecha",
			"Archivos",
			"Acciones"
		],
		loading: true,
		form: {
			title: '',
			description: '',
			search: '',
			until: '',
			since: ''
		},
		view: false,
		item: null,
		files: [],
		files_deleted: [],
		visible: false,
		image: null
	}

	componentDidMount() {
		this.load();
	}

	create = () => {
		this.setState({
			create: true
		});
		this.props.setTitleHistory("Nuevo Historial");
	}

	reset = () => {
		this.setState({
			item: null,
			create: false,
			view: false,
			form: {
				...this.state.form,
				title: '',
				description: ''
			},
			files: [],
			files_deleted: []
		});
		this.props.setTitleHistory("Historial Médico");
	}

	load = async (withoutLoading: boolean = false) => {
		const res: any = await MedicalHistoryService.get({
			id: this.props.patient.id,
			page: this.state.page,
			withoutLoading,
			...this.state.form
		});
		await this.setState({
			history: res.history.data,
			last_page: res.history.last_page,
			loading: false
		});
		if (this.state.history.length == 0 && this.state.page > 1) {
			await this.setState({
				page: 1
			});
			this.load(false);
		}
	}

	submit = async (e: any) => {
		e.preventDefault();
		await MedicalHistoryService[this.state.item ? 'edit' : 'create']({
			title: this.state.form.title,
			content: this.state.form.description,
			user_id: this.props.patient.id,
			id: this.state.item?.id,
			hasFile: true,
			files: this.state.files.filter((i: any) => i.value).map((i: any) => i.value),
			files_deleted: this.state.files_deleted
		});
		Globals.showSuccess("Se ha creado correctamente el historial médico");
		this.reset();
		this.load(true);
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	edit = (item: any) => {
		const files: any = [ ...this.state.files ];
		item.files.forEach((i: any) => {
			files.push({
				name: i.file.replace('medical_history/',''),
				id: i.id,
				url: ENV.BasePublic + i.file
			});
		});
		this.setState({
			create: true,
			files,
			item,
			form: {
				...this.state.form,
				title: item.title,
				description: item.content
			}
        });
		this.props.setTitleHistory("Editar Historial");
	}

	delete = (item: any, index: number) => {
		Globals.confirm('¿Desea eliminar este registro médico? Esta acción no se puede deshacer',async () => {
			await MedicalHistoryService.delete({
				id: item.id
			});
			Globals.showSuccess("Se ha eliminado correctamente el registro médico");
			this.load(true);
		});
	}

	view = (item: any) => {
		const files: any = [ ...this.state.files ];
		item.files.forEach((i: any) => {
			files.push({
				name: i.file.replace('medical_history/',''),
				id: i.id,
				url: ENV.BasePublic + i.file
			});
		});
		this.setState({
			create: true,
			view: true,
			item,
			files,
			form: {
				...this.state.form,
				title: item.title,
				description: item.content
			}
		});
		this.props.setTitleHistory("Ver Historial");
	}

	changeFiles = (e: any) => {
		const files: any = [ ...this.state.files ];
		files.push(e.target);
		this.setState({
			files
		});
	}

	onDelete = (index: number, id?: number) => {
		const files: any = [ ...this.state.files ];
		files.splice(index,1);
		const files_deleted: any = [ ...this.state.files_deleted ];
		if (this.state.item) {
			files_deleted.push(id);
		}
		this.setState({
			files,
			files_deleted
		});
	}
	
	render() {
		let patient = '';
		if (this.props.patient) {
			patient = this.props.patient.person.name + ' ' + this.props.patient.person.lastname;
		}

		return (
			<div className="medical-history">
				<Viewer
			      visible={ this.state.visible }
			      onClose={ () => this.setState({ visible: false }) }
			      images={ [
					{ src: this.state.image, alt: '' },
			      ] } />

				{
					!this.state.create && (
						<React.Fragment>
							<h3 className="title-patient"><span>Paciente:</span> { patient }</h3>

							<form className="row form-filter" onSubmit={ async (e: any) => {
								e.preventDefault();
								await this.setState({
									page: 1
								});
								this.load();
							} }>
								<div className="col-md-3">
									<Input
										color="gray" 
										name="search" 
										label="Búsqueda"
										onChange={ this.change } 
										value={ this.state.form.search }
										placeholder="Buscar por Título" />
								</div>
								<div className="col-md-3">
									<DatePicker
										color="gray"
										label="Desde"
										maxDate={ this.state.form.until ? moment(this.state.form.until).toDate() : moment().toDate() }
										onChange={ (text: string) => {
											this.setState({
												form: {
													...this.state.form,
													since: text
												}	
											});
										} }
										value={ this.state.form.since }
									/>
								</div>
								<div className="col-md-3">
									<DatePicker
										color="gray"
										label="Hasta"
										minDate={ this.state.form.since ? moment(this.state.form.since).toDate() : null }
										maxDate={ moment().toDate() }
										onChange={ (text: string) => {
											this.setState({
												form: {
													...this.state.form,
													until: text
												}										
											});
										} }
										value={ this.state.form.until }
									/>
								</div>
								<div className="col-md-3">
									<Button className="btn-align-bottom text-black font-bold">
										Filtrar
									</Button>
								</div>
							</form>

							<Table loading={ this.state.loading } data={ this.state.history.length } title="Historial" header={ this.state.header } right={
				                  <Button
				                    title="Nuevo"
				                    outline="true"
				                    small="true"
				                    onClick={ this.create }
				                  >
				                    <Icon name="plus" />
				                  </Button>
				                }>
								{ this.state.history.map((i: any,index: number) => {
									return (
										<tr key={ index }>
											<td className="ellipsis">{ i.title }</td>
											<td>{ moment(i.created_at).format('DD/MM/YYYY HH:mm') }</td>
											<td>{ i.files.length }</td>
											<td>
												<Button title="Ver" small="true" onClick={ () => this.view(i) }>
													<Icon name="eye" />												
												</Button>
												<Button color="info" title="Editar" small="true" onClick={ () => this.edit(i) }>
													<Icon name="pencil" />												
												</Button>
												<Button  title="Eliminar" small="true" onClick={ () => this.delete(i,index) }>
													<Icon name="trash" />												
												</Button>
											</td>
										</tr>
									)
								}) }
							</Table>

							<Pagination 
								pages={ this.state.last_page } 
								active={ this.state.page }
								onChange={ async (page: number) => {
									await this.setState({
										page: page
									});
									this.load();
							} } />
						</React.Fragment>
					)
				}	

				{
					this.state.create && (
						<React.Fragment>
							<Button className="btn-back" onClick={ this.reset }>
								<Icon name="arrow-left" />
								Volver
							</Button>
							<form onSubmit={ this.submit }>
								<Input
									color="gray"
				                    value={ this.state.form.title }
				                    name="title" 
				                    label="Título"
				                    onChange={ this.change }
				                    disabled={ this.state.view }
				                />
								<Textarea
				                    label="Descripción"
				                    name="description"
				                    value={ this.state.form.description }
				                    onChange={ this.change }
				                    disabled={ this.state.view }
				                />
				                <AddFiles
				                	onDelete={ this.onDelete }
				                	files={ this.state.files }
				                	onChange={ this.changeFiles }
				                	disabled={ this.state.view }
				                	onOpen={ (url: string) => {
										this.setState({
											visible: true,
											image: url
										});
				                	} } />
								{ !this.state.view && (
									<div className="text-center">
										<Button type="submit">
											Guardar
										</Button>
									</div>
								) }
							</form>
						</React.Fragment>
					)
				}							
			</div>
		)
	}
}

export default MedicalHistory;