const { REACT_APP_BASE: Base, REACT_APP_SOCKET: SocketUrl, REACT_APP_PAYPAL_MODE: PaypalMode } = process.env;

const paypal: any = {
	PAYPAL_MODE: PaypalMode,
	PAYPAL_CLIENT: {
		sandbox: 'Aft76daxvyOed2HJ4uqNiIy4qPp0c8DLWoeV2bOlTJYaTpF6wDoyNGuOX3m2bCeoVRNFDxVkgec0YlwV',
  		production: 'ASEuhCdBPDg-PwSDRGadXP37rFrqmLiXccDGpSWhEkFUUv2tCN94pOQpuJZkfQXIvvwq81KzLjj5Ghue'
	},
	PAYPAL_CURRENCY: 'USD'
}


const google: any = {
	GOOGLE_ID: '512121222971-b97aapbqkv3h27vnavku1ijngvl41fdo.apps.googleusercontent.com'
}

const facebook: any = {
	FACEBOOK_ID: '607910083120378'
}

const data: any = {
	BaseURI: Base + '/api/public/',
	BasePublic: Base + '/api/public/storage/',
	Path: 'drlobo/admin', 
	Socket: SocketUrl,
	NAME: "Dr. Gabriel Lobo",
	...paypal,
	...facebook,
	...google,
	
}

export default data;