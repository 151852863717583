import React from 'react';
import Menu from 'screens/menu';
import { Input, Button, Table, Pagination, Icon, Modal, ButtonAdd, Action } from 'components';
import { ProcedureService } from 'services';
import { Globals } from 'utils';
import CreateEditProcedure from './create-edit-procedure';
import Header from 'components/header';

class Procedures extends React.Component<any> {

	state = {
		form: {
			search: '',
		},		
		data: [],
		page: 1,
		last_page: 1,
		loading: true,
		header: [
			'Nombre',
			'Descripción',
			'Acciones'
		],
		edit: false,
		item: null,
		view: false
	}
	
	componentDidMount() {
		this.load();
	}

	load = async () => {
		const params = {
			...this.state.form, 
			page: this.state.page
		}
		const res: any = await ProcedureService.get(params);
		if (res.procedures.data.length == 0 && this.state.page > 1) {
			this.setState({
				page: 1
			});
			this.load();
		}
		else {
			this.setState({
				data: res.procedures.data,
				last_page: res.procedures.last_page,
				loading: false
			});
		}		
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	delete = (item: any) => {
		Globals.confirm('¿Desea eliminar el procedimiento?',async () => {
			await ProcedureService.delete({
				id: item.id
			});
			this.load();
		});
	}

	onCloseEdit = async (item: any) => {
	  let data: any = [ ...this.state.data ];
  	  if (item) {
  		this.load();
  		await this.setState({
			page: 1
  		});
  	  }
      await this.setState({
        edit: false,
        item: null
      });
	}

	render() {
		return (
			<>
			<Header />
			<Menu history={ this.props.history }>
				<div id="procedures">
					
					<Modal
			            title={ this.state.item != null ? 'Editar procedimiento' : 'Nuevo procedimiento' }
			            visible={ this.state.edit }
			            onClose={ () => {
			              this.setState({
			                edit: false,
			                item: null
			              });
			            } } 
			          >
			          <CreateEditProcedure
			          	item={ this.state.item }
			          	onClose={ this.onCloseEdit } />
			        </Modal>

			        <Modal
			            title="Ver procedimiento"
			            visible={ this.state.view }
			            onClose={ () => {
			              this.setState({
			                view: false,
			                item: null
			              });
			            } }
			          >
			          <CreateEditProcedure
			          	view={ true }
			          	item={ this.state.item }
			          	onClose={ () => {
			              this.setState({
			                view: false,
			                item: null
			              });
			            } } />
			        </Modal>

					<form className="row form-filter" onSubmit={ async (e: any) => {
						e.preventDefault();
						await this.setState({
							page: 1
						});
						this.load();
					} }>
						<div className="col-md-5">
							<Input
								name="search" 
								label="Búsqueda"
								onChange={ this.change } 
								value={ this.state.form.search }
								placeholder="Buscar por Nombre o Descripción" />
						</div>
						<div className="col-md-2">
							<Button className="btn-align-bottom text-black font-bold">
								Filtrar
							</Button>
						</div>
					</form>
					<Table loading={ this.state.loading } data={ this.state.data.length } title="Procedimientos" header={ this.state.header } right={
						<ButtonAdd 
							onClick={ () => {
								this.setState({
								edit: true
								});
							} }
						/>
		                }>
						{ this.state.data.map((i: any,index: number) => {
							return (
								<tr key={ index }>
									<td className="ellipsis">{ i.name }</td>
									<td className="ellipsis">{ i.description }</td>
									<td>
										{/* <Button color="info" title="Ver" small="true" onClick={ () => {
											this.setState({
												item: i,
												view: true
											});
										} }>
											<Icon name="eye" />												
										</Button> */}

										<Action icon="show" title="Ver" small="true" onClick={ () => {
											this.setState({
												item: i,
												view: true
											});
										} }/>

										{/* <Button title="Editar" small="true" onClick={ () => {
											this.setState({
												item: i,
												edit: true
											});
										} }>
											<Icon name="edit" />												
										</Button> */}

										<Action icon="edit" title="Editar" onClick={ () => {
											this.setState({
												item: i,
												edit: true
											});
										} } />		

										{/* <Button  title="Eliminar" small="true" onClick={ () => this.delete(i) }>
											<Icon name="trash" />												
										</Button> */}

										<Action icon="delete" title="Eliminar" onClick={ () => this.delete(i) } />	
									</td>
								</tr>
							)
						}) }
					</Table>

					<Pagination 
						pages={ this.state.last_page } 
						active={ this.state.page }
						onChange={ async (page: number) => {
							await this.setState({
								page: page
							});
							this.load();
					} } />
				</div>
			</Menu>
				</>
		)
	}
}

export default Procedures;