import React from 'react';
import Menu from 'screens/menu';
import { Pagination, Input, Button, Table, Modal, Icon, Select } from 'components';
import { EvaluationService, CurrencyService, ProcedureService } from 'services';
import ViewEvaluation from './view-evaluation';
import moment from 'moment';
import { connect } from 'react-redux';
import { on } from 'jetemit';
import { Constants } from 'utils';
import Header from 'components/header';
import Action from 'components/action';

class Evaluations extends React.Component<any> {

	state: any = {
		form: {
			search: '',
			status: -1
		},
		currencies: [],
		procedures: [],
		data: [],
		last_page: 1,
		page: 1,
		item: null,
		view: false,
		loading: true,
		status: [
			{ value: -1, label: "Todos" },
			{ value: 1, label: "Activo" },
			{ value: 0, label: "Inactivo" }
		],
		header: [
			'Paciente',
			'Procedimientos',
			'Fotos',
			'Presupuesto',
			'Fecha',
			'Estatus',
			'Acciones'
		],
		subscriber: () => null,
		listen: true
	}

	componentDidMount() {
		this.loadData();
		this.load();

		this.setState({
			subscriber: on(Constants.EVALUATIONS.SEND_MESSAGE,(_data: any) => {
				if (_data.user_id != this.props.user.id && this.state.listen) {
					const data: any = [...this.state.data];
					const index = data.findIndex((i: any) => i.id == _data.evaluation_id);
					if (index != -1) {
						data[index].badge = _data.badge;
						data[index].messages.unshift(_data);
						this.setState({
							data
						});
					}	
				}							
			})
		});
	}

	componentWillUnmount() {
		this.state.subscriber();
	}

	loadData = async () => {
		const params = {
			withoutLoading: true,
			all: true
		}
		const res: any = await Promise.all([
			CurrencyService.get(params),
			ProcedureService.get(params)
		])
		this.setState({
			currencies: res[0].currencies,
			procedures: res[1].procedures
		});
	}

	updateEvaluation = (item: any) => {
		const data: any = [ ...this.state.data ];
		data[data.findIndex((i: any) => i.id == item.id)] = item;
		this.setState({
			data: data
		});
	}

	load = async () => {
		const params: any = {
			...this.state.form,
			page: this.state.page
		}
		const res: any = await EvaluationService.get(params);
		if (res.evaluations.data.length == 0 && this.state.page > 1) {
			this.setState({
				page: 1
			});
			this.load();
		}
		else {
			this.setState({
				data: res.evaluations.data,
				last_page: res.evaluations.last_page,
				loading: false
			});
		}
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	render() {
		return (
		<>
			<Header />
			<Menu history={ this.props.history }>
				<div id="evaluations">
					
					<Modal
						className="modal-lg"
			            title="Evaluación"
			            visible={ this.state.view }
			            onClose={ () => {
			              this.setState({
			                view: false,
			                item: null,
			                listen: true
			              });
			            } } 
			          >
			          <ViewEvaluation
			            setListen={ (status: boolean) => {
			            	this.setState({
			            		listen: status
			            	});
			            } }
			          	updateEvaluation={ this.updateEvaluation }
			          	procedures={ this.state.procedures }
			          	currencies={ this.state.currencies }
			          	evaluation={ this.state.item != null && this.state.data.find((i: any) => i.id == this.state.item.id) } />
			        </Modal>

					<form className="row form-filter" onSubmit={ async (e: any) => {
						e.preventDefault();
						await this.setState({
							page: 1
						});
						this.load();
					} }>
						<div className="col-md-5">
							<Input
								name="search" 
								label="Búsqueda"
								onChange={ this.change } 
								value={ this.state.form.search }
								placeholder="Buscar por Paciente o Procedimiento" />
						</div>
						<div className="col-md-5">
							<Select
								name="status"
								label="Estatus"
								onChange={ this.change }
								value={ this.state.form.status }
								options={ this.state.status } />
						</div>
						<div className="col-md-2">
							<Button className="btn-align-bottom text-black font-bold">
								Filtrar
							</Button>
						</div>
					</form>
					<Table loading={ this.state.loading } data={ this.state.data.length } title="Asesoría online" header={ this.state.header } center={ [2,3] }>
						{ this.state.data.map((i: any,index: number) => {
							return (
								<tr key={ index }>
									<td className="ellipsis">{ i.user.person.name + ' ' + i.user.person.lastname }</td>
									<td>
										<ul>
											{
												i.procedures.map((i: any) => (
													<li className="item-procedure">
														<p className="ellipsis">{ i.name }</p>
													</li>
												))
											}
										</ul>										
									</td>
									<td className="text-center">{ i.photos.length }</td>
									<td className="text-center">{ i.budgets.length > 0 ? (
										<Icon name="check" color="success" />
									) : (
										<Icon name="close" color="danger" />
									) }</td>
									<td className="text-center">{ moment(i.created_at).format('DD/MM/YYYY HH:mm') }</td>
									<td>{ i.status == 1 ? 'Activo' : 'Inactivo' }</td>
									<td>
										<Button badge={ i.badge || i.messages.filter((i: any) => i.view == 0 && i.user_id != this.props.user.id).length } color="info" title="Ver" small="true" onClick={ () => {
											this.setState({
												item: i,
												view: true
											});
										} }>
											<Icon name="eye" />											
										</Button>
									</td>
								</tr>
							)
						}) }
					</Table>

					<Pagination 
						pages={ this.state.last_page } 
						active={ this.state.page }
						onChange={ async (page: number) => {
							await this.setState({
								page: page
							});
							this.load();
					} } />
				</div>
			</Menu>
			</>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user
	}
})(Evaluations);