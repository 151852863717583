import React from 'react';

interface BadgeProps {
	value: number | undefined | null
}

const Badge = (props: BadgeProps) => {
	if (props.value != null && props.value > 0) {
		return <span className="badge badge-light">{ props.value }</span>
	}
	else
		return null;
}

export default Badge;