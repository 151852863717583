import React from 'react';
import Title from './title';

const Table = (props: any) => {
	const { emptyText = 'No hay registros' } = props

	let content = (
		<div className="table-responsive ">
			<table className="table table-component w-100 d-block d-md-table ">
				<thead>
					<tr>
						{props.header && props.header.map((i: any, index: number) => {
							return (
								<th className={props.center && props.center.indexOf(index) != -1 ? 'text-center' : ''} key={index}>{i}</th>
							)
						})}
					</tr>
				</thead>
				<tbody>
					{props.children}
				</tbody>
			</table>
		</div>
	)

	if (props.loading) {
		content = (
			<h2 className="no-table-data">Cargando...</h2>
		)
	}
	else if (!props.data || props.data == 0) {
		content = (
			<h2 className="no-table-data">{emptyText}</h2>
		)
	}

	return (
		<div className="table-container">

			<Title right={props.right} name={props.title} />

			{content}
		</div>
	)
}

export default Table;