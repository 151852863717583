import React from 'react';
import { ENV } from '../utils';

class Image extends React.Component<any> {
	
	render() {
		const source = ENV.BasePublic + this.props.source
		return (
			<div className={`image-component ${this.props.classcontainer} ${this.props.rounded ? 'image-component--rounded' : ''}`} onClick={this.props.onClick}>
				<img 
					alt={this.props.label ? this.props.label : ''} 
					src={ source }
					width={this.props.width}
					height={this.props.height}
				/>
				<label className="form-check-label" htmlFor={ `checkbox-${ this.props.label?.replace(' ','') }` }> 
					{ this.props.label }
				</label>
			</div>			
		)
	}
}

export default Image;