import React from 'react';
import Menu from 'screens/menu';
import { connect } from 'react-redux';
import { Input, Button, Modal } from 'components';
import { Socket, Constants, ENV, Globals } from 'utils';
import moment from 'moment';
import { on, emit } from 'jetemit';
import { ChatService, CountryService } from 'services';
import Header from 'components/header';
import ViewPatient from './view-patient';

class Chat extends React.Component<any> {

	private messages: any;

	state: any = {
		chat: null,
		chats: [],
		messages: [],
		message: '',
		unsuscriber: () => null,
		subscriberChat: () => null,
		subscriberDelete: () => null,
		countries: [],
		page: 1,
		last_page: 1,
		loading: false
	}

	componentWillUnmount() {
		this.state.unsuscriber();
		this.state.subscriberChat();
		this.state.subscriberDelete();
	}

	componentDidMount() {
		if (this.props.user.level == Constants.LEVELS.USER) {
			this.loadMessages();
		}
		else {
			this.loadChats();
		}
		this.loadCountries();

		this.setState({
			unsuscriber: on(Constants.CHAT.MESSAGE,async (message: any) => {
				if (this.state.chat && message.chat.id == this.state.chat.id) {
					message.viewed = 1;
	
					if (this.state.chats.length > 0) {
						let chats: any = [...this.state.chats];
						const index: number = chats.findIndex((i: any) => i.id == message.chat_id);
						let chat: any = chats[index];
						chat.updated_at = message.updated_at;
						chats.splice(index,1);
						chats.unshift(chat);
						this.setState({
							chats
						});
					}

					await this.setState({
						messages: [
							...this.state.messages,
							message
						]				
					},this.scrollToBottom);

					if (message.user_id != this.props.user.id) {
						await ChatService.viewed({ 
							id: message.id,
							withoutLoading: true
						});
					}
				}
				else if (this.state.chats.length > 0) {
					let chats: any = [...this.state.chats];
					const index: number = chats.findIndex((i: any) => i.id == message.chat_id);
					chats[index].messages_count++;
					this.setState({
						chats
					});
				}
			}),
			subscriberChat: on(Constants.CHAT.RELOAD,async () => {
				await this.setState({
					page: 1
				});
				this.loadChats(true);
			}),
			subscriberDelete: on(Constants.PATIENTS.USER_DELETE,async (data: any) => {
				await this.setState({
					page: 1
				});
				this.loadChats(true);

				if (this.props.user.level != Constants.LEVELS.USER) {
					const user: any = this.state.chat.users.find((i: any) => i.level == Constants.LEVELS.USER);

					if (data == user.id) {
						this.setState({
							chat: null,
							messages: []
						});
						Globals.showError("Lo sentimos, el usuario fue eliminado");
					}
				}
			})
		});
	}

	loadChats = async (withoutLoading: boolean = false) => {
		const res: any = await ChatService.get({ 
			id: this.props.user.id,
			withoutLoading,
			paginate: true,
			page: this.state.page
		});
		this.setState({
			chats: [
				...this.state.chats,
				...res.chats.data
			],
			last_page: res.chats.last_page,
			loading: false
		});
	}

	loadMessages = async () => {
		const res: any = await ChatService.messages({
			id: this.state.chat?.id, 
			user_id: this.props.user.id
		});
		const chats: any = [...this.state.chats];
		const index: number = chats.findIndex((i: any) => i.id == res.chat.id);
		chats[index] = res.chat;
		this.setState({
			messages: res.chat.messages,
			chat: res.chat,
			chats,
		},this.scrollToBottom);

		emit(Constants.CHAT.RELOAD_BADGE);

		if (res.create) {
			Socket.emit(Constants.CHAT.RELOAD);
		}
	}

	loadCountries = async () => {
		const res: any = await CountryService.get({
			withoutLoading: true,
		});
		this.setState({
			countries: [
				{
					id: 0,
					name: "Todos"
				},
				...res.countries
			]
		});
	}

	setActive = async (chat: any) => {
		await this.setState({
			chat: chat,
		});
		this.loadMessages();
	}

	getPosition = (message: any) => {
		let position = 'left';
		const { LEVELS } = Constants;
		const { user } = this.props;
		if ((message.user.level === user.level) || ([LEVELS.ADMIN, LEVELS.SUPERADMIN].includes(message.user.level))) {
			position = 'right';
		}
		return position;
	}

	scrollToBottom = () => {
		this.messages.scrollTop = this.messages.scrollHeight;
	}

	send = async (validate = true) => {
		if (this.state.message == '') {
			return;
		}
	    Socket.emit(Constants.CHAT.MESSAGE,{
	    	chat_id: this.state.chat.id,
			text: this.state.message,
			user_id: this.props.user.id
		});
		this.setState({
			message: ''
		},this.scrollToBottom);
	}

	change = async (e: any) => {
		const name = e.target.name
		await this.setState({
			[e.target.name]: e.target.value
		});
	}

	onKeyUp = (e: any) => {
		e.preventDefault();
		if (e.key == 'Enter') {
			this.send();
		}
	}

	getUserName = (users: any) => {
		if (this.props.user.level == Constants.LEVELS.USER) {
			return ENV.NAME;
		}
		else {
			const user: any = users.find((i: any) => i.level == Constants.LEVELS.USER);
			return user.person.name + ' ' + user.person.lastname;
		}
	}

	getAvatar = (users: any) => {
		if(!users) return ''
		const user: any = users.find((i: any) => i.id != this.props.user.id);
		return user.person.profile_picture_url
	}

	viewPatient = (users: any) => {
		const user: any = users.find((i: any) => i.level === Constants.LEVELS.USER);
		this.setState({
			item: user,
			view: true
		})
	}

	render() {
		const is_admin: boolean = this.props.user.level != Constants.LEVELS.USER;
        const photo = this.getAvatar(this.state.chat?.users);
        const styleBackground = { 
            backgroundImage: `url('${photo}')`
		}
		const { loading } = this.state;
		
		return (
			<>
			<Header />
			<Menu history={ this.props.history }>
				<div id="chat-admin">
					<Modal
							className="modal"
							title="Ver paciente"
							visible={ this.state.view }
							onClose={ () => {
							this.setState({
								view: false,
								item: null
							});
							} }
						>
						<ViewPatient
							view={ true }
							countries={ this.state.countries }
							item={ this.state.item }
							onClose={ () => {
							this.setState({
								view: false,
								item: null
							});
							} }
						/>
					</Modal>
					<div className="container-chat">
						<div className="row">
							{
								  is_admin && (
									<div className="col-md-4 no-padding-right">
										<div className="list-chats">
											{ this.state.chats.map((i: any,index: number) => {
												return (
													<div key={ index } className={ `item-chat ${ this.state.chat && this.state.chat.id == i.id ? 'active' : '' }` } onClick={ () => this.setActive(i) }>
														{ i.messages_count > 0 && <div className="badge">{ i.messages_count }</div> }
														<h2>{ this.getUserName(i.users) }</h2>
														<p className="date">{ moment(i.updated_at || i.created_at).format('DD/MM/YYYY') }</p> 
													</div>
												)
											}) }

											{
												!loading ? (
													<div className="text-center" style={ {
														marginTop: '20px',
														marginBottom: '20px'
													} }>
														{
															this.state.last_page > this.state.page && (
																<Button onClick={ () => {
																	this.setState({
																		loading: true,
																		page: this.state.page + 1
																	},() => this.loadChats(true));
																} }>
																	Ver Más
																</Button>
															)
														}														
													</div>
												) : (
													<div className="text-center" style={ {
														marginTop: '20px',
														marginBottom: '20px'
													} }>
														<i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i>
													</div>
												)
											}
										</div>										
									</div>
								)
							}							
							<div className={ is_admin ? 'col-md-8 no-padding-left' : 'col-md-12' }>
								<div className="container-messages">
									{ this.state.chat && <div className="top-messages">
										<div className="header-user">
											<div className={"header-user-avatar"} style={photo ? styleBackground : {}}></div>
											<span className="header-user-name" onClick={() => this.viewPatient(this.state.chat.users)}>
												{this.getUserName(this.state.chat.users)}
											</span>
										</div>
										{/* <h2><span>Chat con:</span> { this.getUserName(this.state.chat.users) }</h2> */}
									</div> }

									<div className="container-scroll" ref={ ref => this.messages = ref }>
										{ !this.state.chat && is_admin && <h2 className="no-chat">Por favor, seleccione un chat</h2> }

										{ this.state.messages.map((i: any,index: number) => {
											return (
												<div className={ `message ${ this.getPosition(i) }` } key={ index }>
													<h2 dangerouslySetInnerHTML={{__html: Globals.urlify(i.text)}}></h2>
													<p>{ moment(i.created_at).format('DD/MM/YYYY') }</p>
													<p>{ i.user.person.name + ' ' + i.user.person.lastname }</p>
													{/* <div className={ `triangle-${ this.getPosition(i) }` }></div> */}
												</div>
											)									
										}) }
									</div>

									<div className="container-input">
										<Input 
											disabled={ !this.state.chat }
											value={ this.state.message }
											onChange={ this.change }
											name="message"
											onKeyUp={ this.onKeyUp } />
										<Button 
											onClick={ this.send }
											disabled={ !this.state.chat || !this.state.message }>
												<img className="chat-send-message-icon" src={require(`assets/icons/chat-send-message.svg`)} alt="ico"/>
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Menu>
			</>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user,
	}
})(Chat);