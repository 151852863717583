import React from 'react';
import Menu from 'screens/menu';
import { PriceService } from 'services';
import { Input, Table, Button } from 'components';
import { Globals, Socket, Constants } from 'utils';
import { connect } from 'react-redux';
import Header from 'components/header';

class Prices extends React.Component<any> {

	state: any = {
		prices: [],
		header: [],
		loading: true
	}
	
	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await PriceService.get();
		this.setState({
			prices: res.prices,
			loading: false
		});
	}

	change = (e: any,index: number) => {
		const prices: any = [...this.state.prices];
		prices[index].amount = e.target.value;
		this.setState({
			prices
        });
	}

	submit = async () => {
		const prices_zero = this.state.prices.filter((i: any) => i.amount <= 0);
		if (prices_zero.length > 0) {
			Globals.showError("Lo sentimos, los precios deben ser mayores a 0");
			return;
		}
		await PriceService.save({
			prices: this.state.prices,
			user_id: this.props.user.id
		});
		Globals.showSuccess('Se ha guardado correctamente los precios');
		Socket.emit(Constants.PRICES.UPDATE,{
			prices: this.state.prices
		});
	}

	render() {
		return (
			<>
			<Header />
			<Menu history={ this.props.history }>
				<div className="prices">
					<Table loading={ this.state.loading } data={ this.state.prices.length } title="Precios" header={ this.state.header }>
						{
							this.state.prices
								.map((i: any, index: number) => {
									return (
										<tr>
											<td>{ i.type_description }</td>
											<td>
												<Input
									            	type="decimal"
									            	maxlength="10"
									                color="gray"
									                value={ i.amount }
									                name="amount" 
									                placeholder="Precio"
									                onChange={ (event: any) => this.change(event,index) } />
											</td>
										</tr>
									)									
								})
						}
					</Table>

					<div className="text-center">
						<Button type="button" onClick={ this.submit } >
		                    Guardar
		                </Button>
					</div>
				</div>
			</Menu>
					</>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user
	}
})(Prices);