import React from 'react';
import Menu from 'screens/menu';
import { Input, Button, Textarea, Select, AddAvatar } from 'components';
import { CountryService, ProfileService } from 'services';
import { Globals } from 'utils';
import { connect } from 'react-redux';
import Header from 'components/header';

class Profile extends React.Component<any> {

	state = {
		form: {
			name: '',
			lastname: '',
			phone: '',
			email: '',
			address: '',
			country_id: 0,
			profile_picture: {
				file: null,
				preview: null
			}
		},
		form_password: {
			password: '',
			password_confirmation: ''
		},
		countries: []
	}

	componentDidMount() {
		this.getCountries();
		this.load();
	}

	getCountries = async () => {
		const res: any = await CountryService.get({
			withoutLoading: true
		});
		this.setState({
			countries: [
				...res.countries
			]
		});
	}

	load = async () => {
		const res: any = await ProfileService.get({
			id: this.props.user.id
		});
		this.setState({
			form: {
				name: res.user.person.name,
				lastname: res.user.person.lastname,
				email: res.user.email,
				country_id: res.user.person.country_id,
				phone: res.user.person.phone,
				address: res.user.person.address,
				profile_picture: {
					file: null,
					preview: res.user.person.profile_picture_url
				}
			}
		});
	}

	save = async (e: any) => {
		e.preventDefault();
		const params = {
			id: this.props.user.id,
			...this.state.form,
			hasFile: !!this.state.form.profile_picture.file,
			file: this.state.form.profile_picture.file
		}
		const res: any = await ProfileService.edit(params);
		this.props.dispatch({
			type: 'SET_USER',
			payload: res.user
		});
		Globals.showSuccess('Se han guardado los datos correctamente');
	}

	savePassword = async (e: any) => {
		e.preventDefault();
		const params = {
			id: this.props.user.id,
			...this.state.form_password
		}
		await ProfileService.password(params);
		Globals.showSuccess('Se ha cambiado la contraseña correctamente');
		this.setState({
			form_password: {
				password: '',
				password_confirmation: ''
			}
		});
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	changePassword = (e: any) => {
		this.setState({
			form_password: {
				...this.state.form_password,
				[e.target.name]: e.target.value
			}
        });
	}

	render() {
		return (
			<>
			<Header />
			<Menu history={ this.props.history }>
				<div id="profile">
					<div className="row">
						<div className={ Globals.isSocial(this.props.user) ? 'col-md-12' : 'col-md-6' }>
							<div className="card text-center">
			                    <div className="card-body">
			                    	<h2>Datos Personales</h2>
			                        <form onSubmit={ this.save }>
										<div className="row">
											<div className="col-md-12">
											<div className="register-form-header">
												<AddAvatar 
													name="profile_picture" 
													onChange={ this.change }
													value={ this.state.form.profile_picture.preview} 
													view={true} 
												/>
													<div className="register-form-title">Foto de perfil</div>
												</div>
											</div>
										</div>
			                            <div className="row">
			                                <div className="col-md-6">
			                                    <Input
			                                        name="name"
			                                        color="gray"
			                                        label="Nombre"
			                                        onChange={ this.change } 
			                                        value={ this.state.form.name }
			                                        placeholder="Nombre" />
			                                </div>
			                                <div className="col-md-6">
			                                    <Input
			                                        name="lastname" 
			                                        color="gray"
			                                        label="Apellido"
			                                        onChange={ this.change } 
			                                        value={ this.state.form.lastname }
			                                        placeholder="Apellido" />
			                                </div>
			                                { !Globals.isSocial(this.props.user) && (
			                                	<div className="col-md-6">
				                                    <Input
				                                        name="email"
				                                        color="gray"
				                                        label="Correo electrónico"
				                                        onChange={ this.change } 
				                                        value={ this.state.form.email }
				                                        placeholder="Correo electrónico" />
				                                </div>
				                            ) }
			                                <div className={ !Globals.isSocial(this.props.user) ? 'col-md-6' : 'col-md-12' }>
			                                    <Input
			                                        name="phone"
			                                        color="gray"
			                                        label="Teléfono"
			                                        onChange={ this.change } 
			                                        value={ this.state.form.phone }
			                                        placeholder="Teléfono" />
			                                </div>
			                                <div className="col-md-12">
			                                	<Select
													color="gray"
													name="country_id"
													label="País"
													onChange={ this.change }
													disabled={ this.props.view }
													value={ this.state.form.country_id }
													options={ this.state.countries.map((i: any) => {
														return {
															value: i.id,
															label: i.name
														}
													}) } />
											</div>
							                <div className="col-md-12">
							                	<Textarea
							                	    label="Dirección"
							                	    name="address"
							                	    disabled={ this.props.view }
							                	    value={ this.state.form.address }
							                	    onChange={ this.change }
							                	/>
							                </div>
							            </div>
			                            <Button className="btn-align-bottom" type="submit">
											Guardar
										</Button>
			                        </form>
			                    </div>
			                </div>
						</div>
						{ !Globals.isSocial(this.props.user) && <div className="col-md-6">
							<div className="card text-center">
			                    <div className="card-body">
			                    	<h2>Cambiar contraseña</h2>
			                    	<form onSubmit={ this.savePassword }>
		                    			<Input
		                    				type="password"
	                                        name="password"
	                                        color="gray"
	                                        label="Nueva contraseña"
	                                        onChange={ this.changePassword } 
	                                        value={ this.state.form_password.password }
	                                        placeholder="Nueva contraseña" />
	                                    <Input
		                    				type="password"
	                                        name="password_confirmation"
	                                        color="gray"
	                                        label="Confirmar contraseña"
	                                        onChange={ this.changePassword } 
	                                        value={ this.state.form_password.password_confirmation }
	                                        placeholder="Nueva contraseña" />
										<Button className="btn-align-bottom" type="submit">
											Guardar
										</Button>
			                        </form>
			                    </div>
			                </div>
						</div> }
					</div>
				</div>
			</Menu>
			</>
		)
	}
}

export default connect((state: any) => {
	return {
		user: state.user
	}
})(Profile);