import React from 'react';
import Menu from 'screens/menu';
import { Input, Button, Table, Pagination, Icon, Modal, ButtonAdd, Action } from 'components';
import { PaymentCodesService } from 'services';
import { Globals } from 'utils';
import CreateEditPaymentCode from './create-edit-paymentcode';
import Header from 'components/header';

class PaymentCodes extends React.Component<any> {

	state = {
		form: {
			search: '',
		},		
		data: [],
		users: [],
		page: 1,
		last_page: 1,
		loading: true,
		header: [
			'Código',
			'Usuario',
			'Status',
			'Acciones'
		],
		edit: false,
		item: null,
		view: false
	}
	
	componentDidMount() {
		this.load();
	}

	load = async () => {
		const params = {
			...this.state.form, 
			page: this.state.page
		}
		const res: any = await PaymentCodesService.get(params);
		if (res.codes.data.length == 0 && this.state.page > 1) {
			this.setState({
				page: 1
			});
			this.load();
		}
		else {
			this.setState({
				data: res.codes.data,
				last_page: res.codes.last_page,
				users: res?.users,
				loading: false
			});
		}		
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}

	delete = (item: any) => {
		Globals.confirm('¿Desea eliminar el código?',async () => {
			await PaymentCodesService.delete({
				id: item.id
			});
			this.load();
		});
	}

	onCloseEdit = async (item: any) => {
	  let data: any = [ ...this.state.data ];
  	  if (item) {
  		this.load();
  		await this.setState({
			page: 1
  		});
  	  }
      await this.setState({
        edit: false,
        item: null
      });
	}

	render() {
		return (
			<>
			<Header />
			<Menu history={ this.props.history }>
				<div id="codes">
					
					<Modal
			            title={ this.state.item != null ? 'Editar codigo de pago' : 'Nuevo codigo de pago' }
			            visible={ this.state.edit }
			            onClose={ () => {
			              this.setState({
			                edit: false,
			                item: null
			              });
			            } } 
			          >
			          <CreateEditPaymentCode
			          	item={ this.state.item }
						users={this.state.users}
			          	onClose={ this.onCloseEdit } />
			        </Modal>

			        <Modal
			            title="Ver código"
			            visible={ this.state.view }
			            onClose={ () => {
			              this.setState({
			                view: false,
			                item: null
			              });
			            } }
			          >
			          <CreateEditPaymentCode
			          	view={ true }
			          	item={ this.state.item }
			          	onClose={ () => {
			              this.setState({
			                view: false,
			                item: null
			              });
			            } } />
			        </Modal>

					{/* <form className="row form-filter" onSubmit={ async (e: any) => {
						e.preventDefault();
						await this.setState({
							page: 1
						});
						this.load();
					} }>
						<div className="col-md-5">
							<Input
								name="search" 
								label="Búsqueda"
								onChange={ this.change } 
								value={ this.state.form.search }
								placeholder="Buscar por Nombre o Descripción" />
						</div>
						<div className="col-md-2">
							<Button className="btn-align-bottom text-black font-bold">
								Filtrar
							</Button>
						</div>
					</form> */}
					<Table loading={ this.state.loading } data={ this.state.data.length } title="Códigos de pago" header={ this.state.header } right={
						<ButtonAdd 
							onClick={ () => {
								this.setState({
								edit: true
								});
							} }
						/>
		                }>
						{ this.state.data.map((i: any,index: number) => {
							return (
								<tr key={ index }>
									<td className="ellipsis">{ i.code }</td>
									<td className="ellipsis">{ i.user.person ? i.user.person.name != '' ? 'ID: '+i.user.id+' '+ i.user.person.name + ' '+ i.user.person.lastname : 'ID: '+i.user.id+' '+ i.user.email : 'ID: '+i.user.id+' '+ i.user.email }</td>
									<td className="ellipsis">{ i.status_text }</td>
									<td>

										<Action icon="show" title="Ver" small="true" onClick={ () => {
											this.setState({
												item: i,
												view: true
											});
										} }/>

										{/* <Action icon="edit" title="Editar" onClick={ () => {
											this.setState({
												item: i,
												edit: true
											});
										} } />		 */}

										<Action icon="delete" title="Eliminar" onClick={ () => this.delete(i) } />	
									</td>
								</tr>
							)
						}) }
					</Table>

					<Pagination 
						pages={ this.state.last_page } 
						active={ this.state.page }
						onChange={ async (page: number) => {
							await this.setState({
								page: page
							});
							this.load();
					} } />
				</div>
			</Menu>
				</>
		)
	}
}

export default PaymentCodes;